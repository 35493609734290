export default function Avatar({src, size = "sm"}){
    if(src && src.length){
        return (
            <img className={`
                bg-gray-100 border shadow-sm rounded-full object-cover
                ${ size == 'sm' && 'w-6 h-6'}
                ${ size == 'md' && 'w-8 h-8'}
                ${ size == 'lg' && 'w-10 h-10'}
                ${ size == '3xl' && 'w-24 h-24'}
            `}
                src={src} alt="" 
            />
        );
    }

    return (
        <svg className={`text-gray-400
                ${ size == 'sm' && 'w-6 h-6'}
                ${ size == 'md' && 'w-8 h-8'}
                ${ size == 'lg' && 'w-10 h-10'}
                ${ size == '3xl' && 'w-24 h-24'}
            `}
            viewBox="0 0 20 20" fill="currentColor"
        >
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
        </svg>
    );
}