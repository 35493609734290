import { useState, Fragment, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import classnames from "classnames";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import FormSection from "../FormSection";
import { emailMatcherPattern } from "../../../../../constants";
import Label from "../../../../components/formControl/Label";
import Input from "../../../../components/formControl/Input";
import Select from "../../../../components/formControl/Select";
import Checkbox from "../../../../components/formControl/Checkbox";
import Radio from "../../../../components/formControl/Radio";
import RadioButtonGroup from "../../../../components/formControl/RadioButtonGroup";
import { yesNoChoices } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import { signatoryRoleOptions } from "../dataLoader";

const CompanyManagementAndSignatoryRights = ({
  details,
  formName,
  errors,
  trigger,
  register,
  signatoriesOptions,
  handleChange,
  handleValueChange,
  handleSelectChange,
}) => {
  const { t } = useTranslation("declaration-form");
  const [hideUnchecked, setHideUnchecked] = useState(false);
  const translatedSignatoryOptionLabel = (label) => {
    if (!label?.length) return label;

    return t(
      label.toString().toLowerCase().replaceAll(" ", "-").replaceAll("_", "-")
    );
  };

  const peopleAllowedToSign = details?.personRoles
    ?.map((person, index) => ({ ...person, index }))
    .filter((person) => {
      if (details.signatoryRoles === "only_ceo") return person.title == "ceo";

      if (details.signatoryRoles === "only_chairman")
        return person.title == "chairman";

      return true;
    })
    .map(({ index }) => index);

  useEffect(() => {
    if (details?.personRoles?.length) {
      details.personRoles.forEach((person, i) => {
        const checked = details.personRoles[i].isSignatory || false;
        if (checked && details.personRoles[i]?.email?.length) {
          trigger(`personRoles_email_${i}`);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (peopleAllowedToSign?.length) trigger("atleastOneSignatory");
  }, [details.personRoles]);

  useEffect(() => {
    if (peopleAllowedToSign?.length) {
      trigger("atleastOneSignatory");
    }
  }, [details.signatoryRoles]);

  return (
    <FormSection
      label={t("step-5")}
      title={t("company-signatory-details.title")}
      subtitle={t("company-signatory-details.description", {
        formName,
      })}
    >
      <div
        className="mb-3 text-black opacity-60 font-light text-sm leading-relaxed"
        dangerouslySetInnerHTML={{
          __html: details?.signingRule?.length
            ? t("company-signatory-details.message", {
                formName,
                signingRule: t(details.signingRule),
              })
            : t("company-signatory-details.alt-message", {
                companyName: details?.companyName,
                orgNumber: details?.organizationNumber,
              }),
        }}
      ></div>

      <Select
        wrapperClassName="mb-3"
        label={t("who-should-sign", { formName })}
        noPlaceholder={true}
        isClearable={false}
        id="signatoryRoles"
        name="signatoryRoles"
        choices={signatoriesOptions.map((option) => {
          return {
            ...option,
            label: translatedSignatoryOptionLabel(option?.label),
          };
        })}
        value={
          details.signatoryRoles
            ? {
                value: details.signatoryRoles,
                label:
                  signatoriesOptions.find((i) => {
                    return i?.value === details.signatoryRoles;
                  })?.label || "only_ceo",
              }
            : signatoriesOptions[1]
        }
        error={details.signatoryRoles === null}
        onChange={(e) => {
          handleSelectChange("signatoryRoles", null, e);
        }}
      />

      {!peopleAllowedToSign?.length && (
        <p className="mb-1">{t("no-signatories-message")}</p>
      )}

      <div className={errors.atleastOneSignatory ? "mb-2" : ""}>
        <Input
          id={
            !peopleAllowedToSign?.length
              ? t("specify-who-should-sign", { formName })
              : t("at-least-one-signatory")
          }
          className="absolute opacity-0 pointer-events-none"
          validator={register("atleastOneSignatory", {
            validate: () => {
              const signatories = details.personRoles.filter(
                ({ isSignatory }) => isSignatory
              );
              return !signatories.length ? "required" : true;
            },
          })}
          error={
            errors.atleastOneSignatory || details.atleastOneSignatory === null
          }
        />
      </div>

      {details.personRoles.map((person, i) => {
        const checked = details.personRoles[i].isSignatory || false;
        const dateSet = details.personRoles[i].birthDate !== null;

        return (
          <Fragment key={i}>
            {peopleAllowedToSign.includes(i) && (
              <div className="mb-3 grid grid-cols-12 gap-x-3.5" key={i}>
                <div className="col-span-1 flex items-center pt-5 px-2">
                  <Checkbox
                    name="isSignatory"
                    validator={
                      !checked
                        ? null
                        : register(`isSignatory-${i}`, { required: true })
                    }
                    error={errors.isSignatory}
                    className="mx-1"
                    checked={checked}
                    onChange={(e) => {
                      handleValueChange(
                        "personRoles",
                        "isSignatory",
                        i,
                        !details.personRoles[i].isSignatory
                      );
                    }}
                    id={`isSignatory-${i}`}
                  />
                </div>

                <Input
                  wrapperClassName="col-span-4"
                  label={translatedSignatoryOptionLabel(
                    `Name of ${details.personRoles[i].title}`
                  )}
                  type="text"
                  name={`personRoles_name_${i}`}
                  key={`personRoles_name_${i}`}
                  readOnly
                  value={
                    details.personRoles[i] ? details.personRoles[i].name : ""
                  }
                  onChange={(e) => handleChange("personRoles", "name", i, e)}
                  placeholder="John Smith"
                  id={`personRoles_name_${i}`}
                  validator={
                    !checked
                      ? null
                      : register(`personRoles_name_${i}`, {
                          required:
                            details.personRoles[i].title === "ceo" ||
                            details.personRoles[i].title === "chairman" ||
                            details.personRoles[i].isSignatory,
                        })
                  }
                  error={!details.personRoles[i].name}
                />

                <Input
                  wrapperClassName="col-span-4"
                  label={translatedSignatoryOptionLabel(
                    `Email of ${details.personRoles[i].title}`
                  )}
                  type="email"
                  name={`personRoles_email_${i}`}
                  key={`personRoles_email_${i}`}
                  value={
                    details.personRoles[i] ? details.personRoles[i].email : ""
                  }
                  onChange={(e) => handleChange("personRoles", "email", i, e)}
                  placeholder="example@email.com"
                  id={`personRoles_email_${i}`}
                  validator={
                    !checked
                      ? null
                      : register(`personRoles_email_${i}`, {
                          required: true,
                          pattern: emailMatcherPattern,
                        })
                  }
                  error={errors[`personRoles_email_${i}`]}
                />
                <div className="col-span-3">
                  <Label htmlFor={`personRoles_dob_${i}`}>
                    {t("date-of-birth")}
                  </Label>
                  <Flatpickr
                    disabled
                    id={`personRoles_dob_${i}`}
                    className={classnames(
                      "form-control",
                      {
                        "is-invalid": checked && !dateSet,
                      },
                      {
                        "is-valid": !checked || dateSet,
                      }
                    )}
                    name={`personRoles_birthDate_${i}`}
                    key={`personRoles_birthDate_${i}`}
                    value={
                      details.personRoles[i]
                        ? details.personRoles[i].birthDate
                        : ""
                    }
                    onChange={(date) =>
                      handleValueChange("personRoles", "birthDate", i, date)
                    }
                  />
                </div>
              </div>
            )}
          </Fragment>
        );
      })}

      {/* { details.signatoryRoles !== "only_ceo" &&
        <div className="bg-gray-100 -mx-6 mt-4 -mb-6 py-3 px-6">
          <button
            type="button"
            className="flex items-center border-none bg-transparent hover:opacity-75 text-sm leading-none focus:outline-none"
            onClick={() => setHideUnchecked(!hideUnchecked)}
          >
            {hideUnchecked && <EyeIcon width={16} className="mb-0.5" />}
            {!hideUnchecked && <EyeOffIcon width={16} className="mb-0.5" />}

            <span className="ml-4">{hideUnchecked ? 'Show all' : 'Hide unchecked'} members</span>
          </button>
        </div>
      } */}
    </FormSection>
  );
};

export default CompanyManagementAndSignatoryRights;
