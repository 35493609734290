import { useState } from "react"

function useCopyToClipboard() {
  const [status, setStatus] = useState("IDLE")

  function updateClipboard(text, timeout) {
    navigator.clipboard.writeText(text).then(
      () => {
        setStatus("SUCCEEDED")
        setTimeout(() => setStatus("IDLE"), timeout)
      },
      () => {
        setStatus("FAILED")
      }
    )
  }

  function copy(text, timeout = 500) {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported")
      return false
    }

    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        updateClipboard(text, timeout)
      }
    })
  }

  return { copy, status }
}

export { useCopyToClipboard }
