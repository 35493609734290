import { useHistory } from "react-router-dom";

import Button from "../../components/Button";
import ContractMessage from "./ContractMessage";
import { openLinkProgrammatically } from "../../../utils";
import { useTranslation } from "react-i18next";
import useSearchQuery from "../../../hooks/useSearchQuery";

const ContractNotFound = () => {
  const { t } = useTranslation("public-pages");
  const history = useHistory();
  const query = useSearchQuery();
  const formName = query.get("form_name");

  return (
    <ContractMessage
      title={t("contract-not-found.title", {
        formName: formName || "Form",
      })}
      message={t("contract-not-found.message", {
        formName: formName || "Form",
      })}
    >
      <div className="flex items-center space-x-3">
        <Button
          color="primary"
          className="mt-4 mb-2"
          onClick={() => history.replace("/")}
        >
          {t("go-to-home-page")}
        </Button>
      </div>
    </ContractMessage>
  );
};

export default ContractNotFound;
