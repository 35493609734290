import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusIcon, UsersIcon } from "@heroicons/react/solid";
import { useMutation, useQuery } from "react-query";

import Loader from "../../components/Loader";
import Button from "../../components/Button";

import { fetchResellers, resendResellerEmail } from "../../../providers/api";
import EmptyState from "../../components/EmptyState";
import { useTranslation } from "react-i18next";
import { Badge } from "../cases/list/columns";
import { toast } from "react-toastify";

function ResendEmailButton({ reseller }) {
	const { t } = useTranslation("resellers");
	const [email, setEmail] = useState(reseller?.email);
  const { isLoading, mutate } = useMutation(resendResellerEmail, {
    onSuccess: (res) => {
      toast.success(t("invite-sent"));
    },
    onError: (err) => {
      toast.error(err?.message ? err.message : t('Failed to resend invite. Try again'));
    },
  });

	const handleSendEmail = async (e) => {
		e.preventDefault();
		mutate({id: reseller.id, email});
	};

	return (
		<form className="flex items-center" onSubmit={handleSendEmail}>
			{/* <input
				type="text"
				placeholder="john@example.com"
				style={{ maxWidth: "130px", borderRadius: 0 }}
				className="w-full py-1 px-0 rounded-none border-0 border-b text-sm placeholder-gray-400/70"
				value={email}
				onChange={e => setEmail(e.target.value)}
			/> */}

			<Button
				type="submit"
				size="sm"
				outline
				className="ml-2 mb-0.5"
				loading={isLoading}
			>
				<span className="py-px">{t("resend-invite")}</span>
			</Button>
		</form>
	);
}

const Resellers = () => {
  const { t } = useTranslation("resellers");
  const { data, error, isLoading, isRefetching, refetch } = useQuery(
    "resellers",
    fetchResellers,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [resellers, setResellers] = useState([]);

  useEffect(() => {
    if (data?.data?.list) setResellers(data.data.list);
  }, [data]);

  return (
    <div className="md:py-5 max-w-4xl mx-auto">
      <div className="flex items-center justify-between border-b py-3 px-4 md:px-0">
        <div className="flex items-center min-w-0">
          <h2 className="pt-1.5 text-xl font-semibold leading-none text-gray-900 truncate">
            {t("resellers")}
          </h2>
        </div>

        <Link to="/resellers/add" type="button">
          <Button outline color="primary" size="sm">
            <span className="pb-0.5">
              <PlusIcon width={14} strokeWidth={3} className="mr-2" />
            </span>
            {t("add-reseller")}
          </Button>
        </Link>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex flex-col items-center justify-center py-7">
          <Loader fillParent={false} size={50} thickness={4} color="#ddd" />
          <span className="mt-1 opacity-80">{t("fetching-resellers")}</span>
        </div>
      ) : !resellers?.length ? (
        <EmptyState
          icon={UsersIcon}
          title={t("no-resellers.title")}
          message={t("no-resellers.message")}
          actionText={t("add-reseller")}
          actionLink="/resellers/add"
        />
      ) : (
        <div className="py-6 px-2">
          <div className="auth-inner">
            <div className="bg-white rounded shadow border overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "250px" }}>{t("reseller")}</th>
                    <th>{t("email")}</th>
                    <th>{t("status")}</th>
                    <th className="text-center">{t("actions")}</th>
                  </tr>
                </thead>
                <tbody className="p-5">
                  {resellers.map((reseller, index) => (
                    <tr key={reseller.id}>
                      <td>{reseller.name}</td>
                      <td>{reseller.email}</td>
                      <td>
                        <Badge
                          color={
                            reseller.status?.toLowerCase() == "active"
                              ? "light-success"
                              : "light-dark"
                          }
                        >
                          {reseller.status}
                        </Badge>
                      </td>
                      <td className="text-center">
                        { reseller.status?.toLowerCase() != "active" &&
                          <span className="inline-block">
                            <ResendEmailButton reseller={reseller} />
                          </span>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resellers;
