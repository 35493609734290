import { randomId } from "../../../utils";
import Loader from "../Loader";
import Label from "./Label";
import ValidationMessage from "./ValidationMessage";

export default function Input({
  id = randomId(),
  label,
  name,
  type = "text",
  validator = {},
  error,
  isClearable = false,
  isLoading = false,
  className,
  wrapperClassName,
  ...inputProps
}) {
  return (
    <div className={`flex flex-col ${wrapperClassName}`}>
      {label && label.length && <Label htmlFor={id}>{label}</Label>}

      <div className="relative">
        <input
          id={id}
          name={name}
          type={type}
          style={{
            color: "#6e6b7b",
          }}
          className={`placeholder-gray-400/70 border
            ${className}
            ${
              error
                ? "border-red-500 focus:border-red-500/50 focus:ring-red-500"
                : "border-gray-400/70"
            }
          `}
          {...validator}
          {...inputProps}
        />
        {isLoading && (
          <div className="absolute inset-y-0 right-2 pr-1 flex items-center pointer-events-none">
            <Loader fillParent={false} size={26} thickness={5} color="#888" />
          </div>
        )}
      </div>

      <ValidationMessage error={error} />
    </div>
  );
}
