import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/auth';
import {
  LogoutIcon,
  SwitchHorizontalIcon,
  KeyIcon,
  UsersIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';
import defaultAvatar from './avatar_placeholder.png';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../components/Dropdown';
import Avatar from '../components/Avatar';
import { useTranslation } from 'react-i18next';

const UserDropdown = ({
  onChangeTenant = () => {}
}) => {
  const {t} = useTranslation('common');
  const { signOut, authUser } = useAuth();
  const userAvatar = authUser?.avatar || defaultAvatar;
  const userEmail = authUser?.username || 'john_doe@gmail.com';
  const userName = userEmail.split('@')[0].replace('_', ' ');

  function handleSwitchTenant(e) {
    e.preventDefault();
    onChangeTenant();
  }

  return (
    <Dropdown
      className="flex justify-end"
    >
      <DropdownToggle>
        <div className="min-w-full flex items-center justify-end">
          <div className="flex flex-col items-end mr-1">
            <span className="text-sm leading-none font-weight-bold capitalize">
              {userName}
            </span>
            <span className="text-sm leading-none mt-1 font-light opacity-70">
              {authUser?.role || 'Admin'}
            </span>
          </div>
          <Avatar size='lg' />
        </div>
      </DropdownToggle>
      <DropdownMenu right className="w-56 px-1 py-1">
        {/* <DropdownItem
          link="/pages/account-settings"
        >
          <Settings className='w-5 mr-4 ml-2' />
          Settings
        </DropdownItem>
        <DropdownItem
          link="/pages/pricing"
        >
          <CreditCard className='w-5 mr-4 ml-2' />
          Pricing
        </DropdownItem>
        <DropdownItem
          link="/pages/faq"
        >
          <HelpCircle className='w-5 mr-4 ml-2' />
          FAQ
        </DropdownItem> */}
        <DropdownItem
          onClick={handleSwitchTenant}
        >
          <SwitchHorizontalIcon className='w-5 mr-4 ml-2' />
          {t('switch-tenant')}
        </DropdownItem>
        <DropdownItem
          link="/resellers"
        >
          <UserGroupIcon Icon className='w-4 mr-4 ml-2' />
          {t('resellers')}
        </DropdownItem>
        <DropdownItem
          link="/team-members"
        >
          <UsersIcon className='w-4 mr-4 ml-2' />
          {t('team-members')}
        </DropdownItem>
        <DropdownItem
          link="/api-keys"
        >
          <KeyIcon className='w-4 mr-4 ml-2' />
          {t('api-keys')}
        </DropdownItem>
        <DropdownItem
          onClick={signOut}
        >
          <LogoutIcon className='w-5 mr-4 ml-2' />
          {t('logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;
