import { getCurrentLanguage } from "../../i18n";
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "./Dropdown";
import { ChevronDownIcon } from "@heroicons/react/outline";

const supportedLanguages = {
  no: {
    flag: "norway-colored-48-cropped.svg",
    name: "Norsk",
  },
  en: {
    flag: "uk-colored-48-cropped.svg",
    name: "English",
  },
  // se: {
  //   flag: "se-colored-48-cropped.svg",
  //   name: "Swedish",
  // },
};

const Flag = ({ locale = "en" }) => {
  const localeFlag =
    supportedLanguages[locale]?.flag || "uk-colored-48-cropped.svg";

  return (
    <img
      className="w-5 h-5"
      src={`${process.env.PUBLIC_URL}/assets/flags/${localeFlag}`}
    />
  );
};

const LanguageSwitcher = ({ initial = false }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const langObject = supportedLanguages[currentLanguage];
  const localeName =
    langObject?.name || "English";

  // return;

  return (
    <Dropdown className="ml-2.5" isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle className="w-[130px] py-1 pl-1.5 ml-1 bg-white hover:bg-primary/5 transition-colors flex items-center border border-gray-400/70 rounded">
        <span>
          <Flag locale={currentLanguage} />
        </span>

        <span className="text-sm ml-2 mr-4">{localeName}</span>

        <ChevronDownIcon
          strokeWidth={3}
          className="opacity-70 pl-1 ml-auto mr-1.5"
          width={14}
        />
      </DropdownToggle>
      <DropdownMenu right fullWidth>
        {Object.keys(supportedLanguages)
          .filter((lang) => lang != currentLanguage)
          .map((code, index) => {
            return (
              <DropdownItem
                className="w-full flex items-center"
                key={index}
                onClick={() => i18n.changeLanguage(code)}
              >
                <Flag locale={code} />

                <span className="text-sm ml-2 mr-4">{supportedLanguages[code]?.name}</span>
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
