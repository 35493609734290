import UserDropdown from './UserDropdown'
import { Link, useLocation } from 'react-router-dom'
import SwitchTenant from './SwitchTenant'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchCases } from '../../providers/api'

import logo from './logo.png'
import LanguageSwitcher from '../components/LanguageSwitcher'
import { useTranslation } from 'react-i18next'

function NavLink({ to = '/', children }) {
  const location = useLocation()
  const active = to == location.pathname

  return (
    <div
      className={`flex rounded-sm
      ${
        active
          ? 'bg-blue-100/70'
          : 'hover:bg-gray-500/10'
      }
    `}
    >
      <Link
        to={to}
        className={`
          px-4 py-2.5 rounded uppercase tracking-wide text-xs font-semibold
          ${
            active
              ? 'text-gray-700'
              : 'text-gray-500 hover:text-gray-600'
          }
        `}
      >
        {children}
      </Link>
    </div>
  )
}
const AuthLayout = props => {
  const {t} = useTranslation('common');
  const [changeTenant, setChangeTenant] = useState(false);
  const _ = useQuery("test-session", () => fetchCases({}), {
    refetchOnWindowFocus: true
  });

  return (
    <div className='min-h-screen bg-gray-100'>
      <header className="sticky top-0 z-20 h-14 bg-white shadow-sm">
        <div className="px-2.5 lg:px-6 h-full flex items-center justify-between">
          <div className="h-full flex-1 flex items-center">
            <Link exact="true" to="/" className="flex items-center">
              <img
                src={logo}
                alt="logo"
                style={{ height: "32px" }}
              />
            </Link>

            <div className="d-sm-hidden flex justify-start flex-1">
              <div className="ml-6 flex items-baseline space-x-2">
                <NavLink to="/cases">{t('all-cases')}</NavLink>
              </div>
            </div>
          </div>

          <div className='flex items-center'>
            {/* <div className="mr-4">
              <LanguageSwitcher />
            </div> */}
            <UserDropdown onChangeTenant={() => setChangeTenant(true)} />
          </div>
        </div>
      </header>

      {props.children}

      <SwitchTenant open={changeTenant} onClose={() => setChangeTenant(false)} />
    </div>
  );
}

export default AuthLayout
