import { Menu, Transition } from "@headlessui/react";
import { Fragment, forwardRef } from "react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

export const DropdownToggle = forwardRef(({ children, className }, ref) => {
    return (
        <Menu.Button ref={ref} className={className}>
            {children}
        </Menu.Button>
    );
})

export const DropdownMenu = ({ children, className = "", width, fullWidth = false, right = false }) => {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={`z-50 absolute mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                ${right ? 'right-0' : 'left-0'}
                ${fullWidth && 'right-0 left-0'}
                ${className}
            `}
                style={{ width }}
            >
                { children }
            </Menu.Items>
        </Transition>
    );
}

export const DropdownItem = ({ className, children, onClick, link, replace }) => {
    const history = useHistory();

    if(!onClick) {
        onClick = () => {
            if(link) {
                if(replace)
                    history.replace(link);
                else
                    history.push(link);
            }
        }
    }

    return (
        <Menu.Item>
            {({ active }) => (
                <button type="button" className={`group flex rounded-md items-center w-full px-2 py-2 text-sm
                        ${active && "bg-black/5"}
                        ${className}
                    `}
                    onClick={onClick}
                >
                    {children}
                </button>
            )}
        </Menu.Item>
    );
};

export const Dropdown = ({children, className, width}) => {
  return (
    <div
        className={className}
      style={{ width }}
    >
      <div className="text-gray-500">
        <Menu as="div" className="relative inline-block text-left">
          {children}
        </Menu>
      </div>
    </div>
  );
}
