export const getContractUrl = (contract) => {
  const contractUrl = contract?.contractUrl;
  if (!contract || !contractUrl) {
    return undefined;
  }

  if (
    !contractUrl.startsWith(location.origin) &&
    process.env.REACT_APP_ENVIRONMENT !== "prod"
  ) {
    // Hack to open the declaration on localhost or in the preview form
    const url = new URL(contractUrl);
    url.protocol = location.protocol;
    url.host = location.host;
    url.port = location.port;
    return url.origin + process.env.PUBLIC_URL + url.pathname + url.search;
  }
  return contractUrl;
};
