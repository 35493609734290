import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const fallbackLng = {
  nb: ["no"],
  nn: ["no"],
  "nb-NO": ["no"],
  "nb-no": ["no"],
  "nn-NO": ["no"],
  "nn-no": ["no"],
  "en-GB": ["en"],
  "en-US": ["en"],
  "se-SE": ["se"],
  "sv-SE": ["se"],
  "sv-FI": ["se"],
  default: ["en"],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "en",
    fallbackLng,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export const getCurrentLanguage = () => {
  // userLanguage for older IE
  const language =
    i18n?.language || navigator.language || navigator.userLanguage || "en";
  const fallbackArray = fallbackLng[language];
  const languages = Object.values(fallbackLng).reduce((acc, elem) => {
    if (acc.includes(elem[0])) return acc;

    return [...acc, elem[0]];
  }, []);

  if (fallbackArray && fallbackArray[0]) return fallbackArray[0];
  return languages.includes(language) ? language : fallbackLng.default[0];
};

export default i18n;
