import { InformationCircleIcon as FilledInformationCircleIcon } from "@heroicons/react/solid";
import ActionButton from "../../../../../components/Button/ActionButton";
import ReviewStep from "../../../../../components/ReviewStep";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon, XIcon } from "@heroicons/react/outline";
import {
	formatDate,
	formatTime,
	getCurrentCurrency,
} from "../../../../../../utils";
import { Popover } from "../../../../../components/Popover";

const RatingTooltip = () => {
	const { t } = useTranslation("cases");

	return (
		<Popover
			render={({ close, labelId, descriptionId }) => (
			<>
				<div
						className="bg-white text-left p-2 relative"
						style={{ width: "350px" }}
					>
						<div className="absolute -right-1 top-1 pointer-events-auto">
							<ActionButton
								noBg
								icon={<XIcon width={16} />}
								onClick={close}
							/>
						</div>

						<span className="block mb-2 text-xl text-black font-bold">
							{t("legend")}
						</span>

						<span className="block mb-0.5 text-black text-base font-semibold">
							{t("credit-ratings")}
						</span>
						<table className="w-full text-sm font-medium text-black/60">
							<thead className="font-bold text-sm text-black/70">
								<tr>
									<th style={{ width: "40%" }}>{t("rating")}</th>
									<th>{t("description")}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="py-0.5">
										<span className="flex items-center">
											<span className="mr-2 w-3.5 h-3.5 rounded-full bg-status-success"></span>
											71 - 100
										</span>
									</td>
									<td>{t("very-low-risk")}</td>
								</tr>
								<tr>
									<td className="py-0.5">
										<span className="flex items-center">
											<span className="mr-2 w-3.5 h-3.5 rounded-full bg-status-success"></span>
											51 - 70
										</span>
									</td>
									<td>{t("low-risk")}</td>
								</tr>
								<tr>
									<td className="py-0.5">
										<span className="flex items-center">
											<span className="mr-2 w-3.5 h-3.5 rounded-full bg-status-success"></span>
											30 - 50
										</span>
									</td>
									<td>{t("moderate-risk")}</td>
								</tr>
								<tr>
									<td className="py-0.5">
										<span className="flex items-center">
											<span className="mr-2 w-3.5 h-3.5 rounded-full bg-status-error"></span>
											21 - 29
										</span>
									</td>
									<td>{t("high-risk")}</td>
								</tr>
								<tr>
									<td className="py-0.5">
										<span className="flex items-center">
											<span className="mr-2 w-3.5 h-3.5 rounded-full bg-status-error"></span>
											1 - 20
										</span>
									</td>
									<td>{t("very-high-risk")}</td>
								</tr>
							</tbody>
						</table>

						<hr className="mt-4 mb-3 border-gray-300" />

						<span className="block mb-0.5 text-black text-base font-semibold">
							{t("international-ratings")}
						</span>
						<table className="w-full text-sm font-bold text-black/60">
							<thead className="font-bold text-sm text-black/70">
								<tr>
									<th style={{ width: "40%" }}>{t("rating")}</th>
									<th>{t("description")}</th>
								</tr>
							</thead>
							<tbody className="text-sm font-bold">
								<tr>
									<td className="py-0.5 font-bold text-status-success">
										A
									</td>
									<td className="text-status-success">
										{t("very-low-risk")}
									</td>
								</tr>
								<tr>
									<td className="py-0.5 font-bold text-status-success">
										B
									</td>
									<td className="text-status-success">
										{t("low-risk")}
									</td>
								</tr>
								<tr>
									<td className="py-0.5 font-bold text-status-success">
										C
									</td>
									<td className="text-status-success">
										{t("moderate-risk")}
									</td>
								</tr>
								<tr>
									<td className="py-0.5 font-bold text-status-error">
										D
									</td>
									<td className="text-status-error">
										{t("high-risk")}
									</td>
								</tr>
								<tr>
									<td className="py-0.5 font-bold text-status-neutral">
										E
									</td>
									<td className="text-status-neutral">
										{t("not-rated")}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
			</>
			)}
		>
			<button className="ml-1.5 text-primary hover:bg-gray-200 rounded-full w-6 h-6 inline-flex items-center justify-center">
				<FilledInformationCircleIcon width={16} />
			</button>
		</Popover>
	);
};

const ResultBadge = ({ status, label = "--" }) => {
	status = status?.toLowerCase();

	return (
		<div
			className={`
        h-9 px-5 text-white text-base rounded-full inline-flex items-center
        ${["success", "active"].includes(status) && "bg-status-success"}
        ${["error", "inactive"].includes(status) && "bg-status-error"}
        ${status == "warning" && "bg-status-warning"}
        ${
			!["success", "active", "inactive", "error", "warning"].includes(
				status
			) && "bg-status-neutral"
		}
      `}
		>
			{label}
		</div>
	);
};

class CreditScoreProcessor {
	constructor(credit) {
		const creditScore = credit?.creditScore;
		this.paymentRemarksValue = credit?.paymentRemarks;
		this.creditScore = creditScore;
		this.currentRating = creditScore.score.current;
		this.prevRating = creditScore.score.previous;
	}

	computeRatingStatus(rating) {
		let status =
			rating?.toString().toLowerCase() == "not rated"
				? "neutral"
				: "success";
		if (status != "neutral" && isFinite(rating) && rating < 30)
			status = "error";

		return status;
	}

	get ratingDate() {
		const value = this.creditScore.score.current.lastUpdatedAt;
		const date = formatDate(new Date(value).getTime());
		const time = formatTime(new Date(value).getTime());

		if (!date?.length || !time?.length || date == "NaN" || time == "NaN")
			return "--";

		return `${date} | ${time}`;
	}

	get previousRatingDate() {
		const value = this.creditScore.score.previous.lastUpdatedAt;
		const date = formatDate(new Date(value).getTime());
		const time = formatTime(new Date(value).getTime());

		if (!date?.length || !time?.length || date == "NaN" || time == "NaN")
			return "--";

		return `${date} | ${time}`;
	}

	get rating() {
		const value = this.currentRating?.value;

		return {
			value,
			status: this.computeRatingStatus(value),
		};
	}

	get previousRating() {
		const value = this.prevRating?.value;

		return {
			value,
			status: this.computeRatingStatus(value),
		};
	}

	get ratingClass() {
		const value = this.currentRating?.common;
		let status = "neutral";
		if (["A", "B", "C"].includes(value)) status = "success";
		else if (value == "D") status = "error";

		return {
			value: value || "--",
			status,
		};
	}

	get creditLimit() {
		const creditLimitValue = this.creditScore?.limit;
		const noLimit =
			creditLimitValue == null ||
			creditLimitValue == undefined ||
			["not rated", "no limit"].includes(
				creditLimitValue?.toString().toLowerCase()
			);

		return {
			value: noLimit ? "--" : creditLimitValue,
			status: noLimit ? "neutral" : "success",
		};
	}

	get paymentRemarks() {
		const value = this.paymentRemarksValue;
		return {
			value: value ? "yes" : "no",
			status: value ? "error" : "success",
		};
	}
}

const CreditScoreResults = ({
	selectedLog,
	data,
	handleAddNotesToSection = () => {},
}) => {
	const { t } = useTranslation("cases");

	if (!selectedLog?.CREDIT?.creditScore) return null;

	const sourceMetadata = data?.sourceMetadata;
	const status = data?.details?.companyStatus || "--";
	const reportUrl = `https://cs.creditsafe.com/NO/Report/Report/${sourceMetadata?.company_id?.replace("NO-0-", "")}`;

	const creditData = new CreditScoreProcessor(
		selectedLog?.CREDIT
	);

	return (
		<ReviewStep
			heading={t("checks.CREDIT.name")}
			subHeading={t("checks.CREDIT.description")}
			onAddNotes={handleAddNotesToSection}
		>
			<table className="table">
				<thead>
					<tr>
						<th width="35%">{t("credit-details")}</th>
						<th className="py-1 ">{t("results")}</th>
						<th>{t("date")}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<p className="card-text text-nowrap inline-flex items-center">
								{t("credit-rating")}

								<RatingTooltip />
							</p>
						</td>
						<td>
							<ResultBadge
								status={creditData.rating.status}
								label={creditData.rating.value}
							/>
							{/* <span className="font-weight-bold">--</span> */}
						</td>
						<td>
							<span className="font-weight-bold">
								{creditData.ratingDate || "--"}
							</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap inline-flex items-center">
								{t("previous-credit-rating")}

								<RatingTooltip />
							</p>
						</td>
						<td>
							<ResultBadge
								status={creditData.previousRating.status}
								label={creditData.previousRating.value}
							/>
						</td>
						<td>
							<span className="font-weight-bold">
								{creditData.previousRatingDate || "--"}
							</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap inline-flex items-center">
								{t("international-rating-class")}

								<RatingTooltip />
							</p>
						</td>
						<td>
							<ResultBadge
								status={creditData.ratingClass.status}
								label={creditData.ratingClass.value}
							/>
						</td>
						<td>
							<span className="font-weight-bold">--</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap">
								{t("status")}
							</p>
						</td>
						<td>
							<ResultBadge status={status} label={status} />
							{/* <span className="font-weight-bold">--</span> */}
						</td>
						<td>
							<span className="font-weight-bold">--</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap">
								{t("payment-remarks")}
							</p>
						</td>
						<td>
							<ResultBadge status={creditData.paymentRemarks.status} label={t(creditData.paymentRemarks.value)} />
						</td>
						<td>
							<span className="font-weight-bold">--</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap">
								{t("credit-limit")}
							</p>
						</td>
						<td>
							<ResultBadge
								status={creditData.creditLimit.status}
								label={
									isNaN(creditData.creditLimit.value)
										? creditData.creditLimit.value
										: `${creditData.creditLimit.value.toLocaleString()} ${getCurrentCurrency(
												data?.countryCode
										  )}`
								}
							/>
						</td>
						<td>
							<span className="font-weight-bold">--</span>
						</td>
					</tr>
					<tr>
						<td>
							<p className="card-text text-nowrap">
								{t("full-report")}
							</p>
						</td>
						<td>
							<a
								href={reportUrl}
								target="_blank"
								className="flex items-start text-primary underline external-link"
							>
								<span className="w-full max-w-[260px] truncates text-base break-words">
									{reportUrl}

									<ExternalLinkIcon
										className="inline pb-0.5 ml-0.5"
										width={18}
									/>
								</span>
							</a>
							{/* <span className="font-weight-bold">--</span> */}
						</td>
						<td>
							<span className="font-weight-bold">--</span>
						</td>
					</tr>
				</tbody>
			</table>
		</ReviewStep>
	);
};

export default CreditScoreResults;
