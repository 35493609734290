import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { isObjEmpty } from '../../../utils';
import { useHistory } from 'react-router-dom';

import { inviteTenantUser } from '../../../providers/api';
import useLocalStorageState from '../../../hooks/useLocalStorageState';

import ModalMessageCentered from '../../components/ModalMessageCentered';
import Button from '../../components/Button';
import Input from '../../components/formControl/Input';
import { useTranslation } from 'react-i18next';

const AddTeamMember = () => {
  const {t} = useTranslation('team-members')
  const [tenant] = useLocalStorageState('tenant', null);
  const history = useHistory();
  const [userInvited, setUserInvited] = useState(false);
  const [email, setEmail] = useState('');
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { isLoading, data, error, mutate } = useMutation(inviteTenantUser, {
    onSuccess: res => {
      setUserInvited(true);
    },
    onError: err => {
      toast.error(err?.message ? err.message : err);
    },
  });

  const onSubmit = async => {
    if (isObjEmpty(errors)) {
      mutate({ email });
    }
  };

  return (
    <div className="pt-8 max-w-xl mx-auto">
      <div className="bg-white shadow-sm rounded-md overflow-hidden border">
        <div className="py-4 px-6 border-b">
          <h4 className="text-xl font-semibold text-black m-0 leading-none">
            {t('invite-team-member')}
          </h4>
        </div>
        <form
          className="px-7 pt-5 pb-7 space-y-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            label={t('email')}
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            id="email"
            placeholder=""
            validator={register("email", {
              required: true,
              validate: value => value !== '',
            })}
            error={errors["email"]}
          />

          <Button
            color="primary"
            type="submit"
            block
            loading={isLoading}
            className="py-3"
          >
            {isLoading ? t('please-wait') : t('save-user')}
          </Button>
        </form>
      </div>

      <ModalMessageCentered
        open={userInvited}
        success
        title={t('invite-success.title')}
        message={t('invite-success.message', {tenant: tenant.name, email})}
        onClose={() => history.push('/team-members')}
      />
    </div>
  );
};
export default AddTeamMember;
