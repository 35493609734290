import { useState } from "react";
import FileUploaderMulti from "./FileUploaderMulti";
import Attachment from "./Attachment";
import Loader from "./Loader";

const AttachmentUploader = ({
  onAddAttachment = async () => {},
  onRemoveAttachment = async () => {},
}) => {
  const [attachments, setAttachments] = useState([]);
  const [fetchingUploadDetails, setFetchingUploadDetails] = useState(null);

  const handleFileSelected = () => {
		setFetchingUploadDetails(true);
	};

  const handleAddAttachment = ({attachment, uploadDetails}) => {
    setFetchingUploadDetails(false);
    const tempId = Math.random().toString(16).slice(2); // random string
    setAttachments([
      ...attachments, 
      { attachment, uploadDetails, pending: true, tempId }
    ]);
  }

  const handleRemoveAttachment = async ({pending, tempId, attachment}, index) => {
    if(!pending) {
      const updatedAttachments = attachments.map(a => {
        if(a.tempId == tempId) {
          a.removing = true;
          a.status = "idle";
        }
  
        return a;
      });
      setAttachments(updatedAttachments);

      try {
        await onRemoveAttachment(index, attachment);
        const updatedAttachments = attachments.filter(a => {
          return a.tempId !== tempId;
        });
        setAttachments(updatedAttachments);
      } catch (error) {
        console.log("Error removing attachment: ", error);
        const updatedAttachments = attachments.map(a => {
          if(a.tempId == tempId)
            a.removing = false;
    
          return a;
        });
        setAttachments(updatedAttachments);
      }
    }
  }

  const handleAttachmentUploaded = async ({attachment, tempId}) => {
    try {
      await onAddAttachment(attachment);
      const updatedAttachments = attachments.map(a => {
        if(a.tempId == tempId) {
          a.pending = false;
          a.status = "success";
        }
  
        return a;
      });
      setAttachments(updatedAttachments);
    } catch (error) {
      const updatedAttachments = attachments.map(a => {
        if(a.tempId == tempId) {
          a.pending = false;
          a.status = "error";
        }
  
        return a;
      });
      setAttachments(updatedAttachments);
      console.log("Saving attachment error: ", error);
    }
  }

  return (
    <div>
      <div className="relative">
				<FileUploaderMulti
          height="200px"
					autoUpload={false}
					onFileSelected={handleFileSelected}
					onAttachmentSelected={handleAddAttachment}
				/>

				{ fetchingUploadDetails &&
					<div className="rounded-t opacity-80 backdrop-blur-md absolute inset-0 bg-gray-300 z-10 text-gray-900">
						<Loader color="currentColor" />
					</div>
				}
			</div>

      { attachments?.length > 0 && (
        <div>
          { attachments.map((attachmentProps, i) => (
            <Attachment key={i}
              {...attachmentProps}
              onUploaded={() => handleAttachmentUploaded(attachmentProps)}
              onRemove={() => handleRemoveAttachment(attachmentProps, i)}
            />
          )) }
        </div>
      )}
    </div>
  );
};

export default AttachmentUploader;
