import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ContractMessage from "./ContractMessage";

const ContractSignError = () => {
	const { t } = useTranslation("public-pages");
	const { search } = useLocation();
	const searchQuery = new URLSearchParams(search);

	const errorCode = searchQuery.get('error_code');
	const signLink = searchQuery.get('link');

	if (errorCode === "contract_rejected") {
		return (
			<ContractMessage
				title={t("declaration-rejected.title", {
					formName: "declaration",
				})}
				message={t("declaration-rejected.message", {
					formName: "declaration",
					tenantEmail: "onboarding@dintero.com",
				})}
			/>
		);
	}

	if (errorCode === "contract_signed") {
		return (
			<ContractMessage
				title={t("declaration-already-signed.title", {
					formName: "declaration",
				})}
				nextSteps={[
					t("declaration-already-signed.next-steps.1", {
						formName: "declaration",
					}),
					t("declaration-already-signed.next-steps.2", {
						formName: "declaration",
					}),
				]}
				message={t("declaration-already-signed.message", {
					formName: "declaration",
					tenantEmail: "onboarding@dintero.com",
				})}
			/>
		);
	}

	return (
		<ContractMessage
			title={t("contract-sign-error.title", { formName: "declaration" })}
			subTitle={t("contract-sign-error.subtitle", {
				formName: "declaration",
				companyName: "Dintero AS",
			})}
			message={!signLink?.length ? null : t("contract-sign-error.message", {
				formName: "declaration",
				tenantEmail: "onboarding@dintero.com",
				signingMethod: "BankID",
				link: signLink
			})}
			message2={t("contract-sign-error.message2", {
				tenantEmail: "onboarding@dintero.com",
			})}
		/>
	);
};
export default ContractSignError;
