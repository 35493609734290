import PropTypes from "prop-types";
import Radio from "./Radio";
import ValidationMessage from "./ValidationMessage";

const RadioButtonGroup = ({
  withWrapper = false,
  className,
  label,
  name,
  choices,
  validator,
  error,
  required,
  onChange,
  value,
}) => {
  return (
    <>
      <div
        className={`flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 lg:items-center ${
            withWrapper && "border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-2.5 px-3.5"
          }
          ${className}
        `}
      >
        {label && <span className="text-sm text-gray-500">{label}</span>}

        <div className="flex items-center space-x-3">
          {choices.map((choice, index) => {
            let choiceLabel = choice,
              choiceValue = choice;

            if (typeof choice === "object") {
              choiceLabel = choice.label;
              choiceValue = choice.value;
            }

            return (
              <Radio
                key={index}
                id={`${name}-${index + 1}`}
                label={choiceLabel}
                required={required}
                name={name}
                value={choiceValue}
                checked={value == choiceValue}
                validator={validator}
                onChange={e => onChange({ target: { value: choiceValue } })}
              />
            );
          })}
        </div>
      </div>

      <ValidationMessage error={error} />
    </>
  );
};

RadioButtonGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  choices: PropTypes.array,
  onChange: PropTypes.func,
};

RadioButtonGroup.defaultValues = {
  onChange: () => {},
};

export default RadioButtonGroup;
