import React, { useState } from "react";
import Uppy from "@uppy/core";
import English from "@uppy/locales/lib/en_US";
import Swedish from "@uppy/locales/lib/sv_SE";
import Norwegian from "@uppy/locales/lib/nb_NO";
import { DragDrop, ProgressBar } from "@uppy/react";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { generateUploadUrl } from "../../providers/api";
import { toast } from "react-toastify";
import i18n, { getCurrentLanguage } from "../../i18n";

const FileUploaderMulti = ({
  autoUpload = true,
  flat = false,
  height = "auto",
  imagesOnly = false,
  tenantId,
  minFileSize = 100, // 0.1kb
  minFileSizeText = "0.1kb",
  maxFileSize = 50000000, // 50Mb
  maxFileSizeText = "50Mb",
  onFileSelected = () => {},
  onAttachmentSelected = () => {},
  addAttachment = () => {},
  setBlock = () => {},
}) => {
  const [previewArr, setPreviewArr] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);
  const locale = {
    en: English,
    se: Swedish,
    no: Norwegian,
  }[getCurrentLanguage()];

  const uppy = new Uppy({
    //meta: { type: 'avatar' },
    autoProceed: true,
    locale: Swedish,
  });

  uppy.on("file-added", async (f) => {
    try {
      const file = f.data;
      const filename = `${Date.now()}-${file.name
        .toString()
        .replaceAll(" ", "_")}`;

      if(imagesOnly && file.type.indexOf("image") == -1){
        toast.error(i18n.t('only-images-allowed', {ns: "common"}));

        return;
      }

      if (file.size < minFileSize || file.size > maxFileSize) {
        const sizeMessage = file.size < minFileSize 
            ? i18n.t('file-is-too-small', {ns: "common", minFileSize: minFileSizeText}) 
            : i18n.t('file-is-too-large', {ns: "common", maxFileSize: maxFileSizeText});

        toast.error(sizeMessage);
        return;
      }

      setBlock(true);
      setIsPreparing(true);

      onFileSelected({
        attachment: {
          name: file.name,
          createdAt: new Date().getTime(),
        }
      });

      const res = await generateUploadUrl(filename, file.type, tenantId);

      if (!autoUpload) {
        setIsPreparing(false);
        setBlock(false);

        onAttachmentSelected({
          attachment: {
            name: file.name,
            createdAt: new Date().getTime(),
            url: `${res.url}/${res.fileName}`,
          },
          uploadDetails: {
            url: res.url,
            fields: {
              ...res.fields,
              file,
            },
          },
        });

        return;
      }

      const formData = new FormData();
      Object.entries(res.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      setIsUploading(true);
      const uploadRes = await fetch(res.url, {
        method: "POST",
        body: formData,
      });

      if (!uploadRes.ok) {
        const response = await uploadRes.text();
        throw new Error(response);
      }

      addAttachment({
        name: file.name,
        url: `${res.url}/${res.fileName}`,
        createdAt: new Date().getTime(),
        file,
      });
    } catch (error) {
      console.error("Error uploading file: ", error);
    } finally {
      setBlock(false);
      setIsUploading(false);
      setIsPreparing(false);
    }
  });

  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <img key={index} className="rounded mt-2 mr-1" src={src} alt="avatar" />
      ));
    }

    return null;
  };

  return (
    <div
      className={`bg-gray-50 rounded w-full h-full overflow-hidden
      ${flat ? "border p-3" : "shadow p-5"}
    `}
    >
      <DragDrop
        uppy={uppy}
        {...(flat ? { height: "112px" } : { height })}
        // locale={{
        //   strings: {
        //     dropHereOr: isUploading
        //       ? "Uploading files..."
        //       : isPreparing
        //         ? "Preparing files..."
        //         : "Drop here or %{browse}",
        //     browse: "browse",
        //   },
        // }}
        locale={locale}
      />
      <ProgressBar uppy={uppy} />
      {renderPreview()}
    </div>
  );
};

export default FileUploaderMulti;
