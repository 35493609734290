import { getValueFromLocalStorage } from "../../hooks/useLocalStorageState";
import { get, patch, post, put, remove, request } from "./request";

export async function generateUploadUrl(fileName, fileType, tenantId) {
  if (!tenantId) {
    const tenant = getValueFromLocalStorage("tenant", null);
    tenantId = tenant.id;
  }
  return post("/generate-upload-url", {
    fileName,
    fileType,
    tenantId,
  });
}

export async function registerTenant(payload) {
  return post("/tenant/register", payload);
}

export async function updateDefaultPassword(payload) {
  return post("/user/confirm", payload);
}

export async function confirmTenant(payload) {
  return post("/tenant/confirm", payload);
}

export async function signIn(payload) {
  return post("/user/login", payload);
}

export async function requestTemporaryPassword(payload) {
  return post("/user/forgot-password", payload);
}

export async function resetPassword(payload) {
  return post("/user/change-password", payload);
}

export async function createNewCase(payload) {
  return post("/cases", payload);
}

export async function fetchDeclarationFormDetails(contractId, urlSignature) {
  return get(`/public/cases/contracts/${contractId}?sig=${urlSignature}`);
}

export async function submitDeclarationForm({
  contractId,
  urlSignature,
  tenant,
  details,
  meta
}) {
  return put(
    `/public/cases/contracts/${contractId}?sig=${urlSignature}&tenant=${tenant}`,
    {details, meta}
  );
}

export async function fetchCases(payload = { status: "", q: "", limit: 50 }) {
  const { page, limit, cursor, forward, ...nonParams } = payload || {};

  return post("/cases/list", nonParams);

  let params = { page, limit };
  if (cursor) params.cursor = cursor;
  if (forward != undefined) params.forward = forward;
  params = new URLSearchParams(params).toString();

  return post("/cases/list?" + params, nonParams);
}

export async function fetchCaseDetails(caseId) {
  return get(`/cases/${caseId}`);
}

export async function sendEmailToSignatory(contractId, signatoryId, email) {
  return post(
    `/contract/${contractId}/signatory-email/send?signatoryId=${signatoryId}&email=${email}`,
    {}
  );
}

export async function reevaluateCase(caseId) {
  return get(`/back-office/case/${caseId}/reevaluate`);
}

export async function updateCaseLog(logId, payload) {
  return put(`/cases/logs/${logId}`, payload);
}

export async function fetchCaseContract(contractId) {
  return get(`/cases/contracts/${contractId}`);
}

export async function manuallySign(contractId, signatoryId) {
  return post(`/contract/${contractId}/manual-sign/${signatoryId}/sign`, null);
}

export async function manuallyUnSign(contractId, signatoryId) {
  return post(`/contract/${contractId}/manual-sign/${signatoryId}/unsign`, null);
}

export async function fetchApiKeys() {
  return get("/tenant/api-keys");
}

export async function createApiKey(payload) {
  return post("/tenant/api-keys", payload);
}

export async function deleteApiKey(keyId) {
  return remove(`/tenant/api-keys/${keyId.replace("api:", "")}`);
}

export async function refreshApiKey(apiKey) {
  await deleteApiKey(apiKey.id);
  return createApiKey({ name: apiKey.displayName });
}

export async function fetchTenantUsers() {
  return get("/tenant/users");
}

export async function inviteTenantUser(payload) {
  return post("/tenant/invite", payload);
}

export async function fetchResellers() {
  return get("/tenant/resellers");
}

export async function resendResellerEmail({id, email}) {
  return post(`/resellers/${id}/invite-email`, {email});
}

export async function inviteReseller(payload) {
  return post("/tenant/invite-reseller", payload);
}

export async function removeTenantUser(userId) {
  return remove(`/tenant/users/${userId.replace("api:", "")}`);
}

export async function backOfficeReevaluateCase(caseId) {
  return get(`/back-office/case/${caseId}/reevaluate`);
}
