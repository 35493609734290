import { useHistory } from "react-router-dom";

import Button from "../../components/Button";
import { openLinkProgrammatically } from "../../../utils";
import ContractMessage from "../Contract/ContractMessage";

const CaseNotFound = () => {
  const history = useHistory();

  return (
    <ContractMessage
      title="Case Not Found!"
      subTitle="Here are some possible scenarios:"
      message=""
    >
      <div>
        <ol className="list-disc space-y-2 mb-3 pl-6">
          <li>You have the wrong case link.</li>
          <li>
            The case belongs to a tenant you've not been added to.
          </li>
          <li>
            The case has been deleted.
          </li>
        </ol>

        <div className="flex items-center space-x-3">
          <Button
            color="primary"
            className="mt-4 mb-2"
            onClick={() => history.replace("/cases")}
          >
            Go back to cases
          </Button>
          <Button
            outline
            color="primary"
            className="mt-4 mb-2"
            onClick={() =>
              openLinkProgrammatically(
                "mailto:support@quickr.no?subject=Support Ticket - Case not found"
              )
            }
          >
            Contact support
          </Button>
        </div>
      </div>
    </ContractMessage>
  );
};

export default CaseNotFound;
