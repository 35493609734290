import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { hotjar } from "react-hotjar";

import dataLoader from "./dataLoader";
import IntroSection from "./FormSections/IntroSection";
import CompanyDetails from "./FormSections/CompanyDetails";
import BankingAndCurrencyDetails from "./FormSections/BankingAndCurrencyDetails";
import PurposeForPaymentService from "./FormSections/PurposeForPaymentService";
import CompanyOwnershipDetails from "./FormSections/CompanyOwnershipDetails";
import CompanyManagementAndSignatoryRights from "./FormSections/CompanyManagementAndSignatoryRights";
import Pricing from "./FormSections/Pricing";
import Terms from "./FormSections/Terms";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import useSearchQuery from "../../../../hooks/useSearchQuery";
import { toast } from "react-toastify";
import { submitDeclarationForm } from "../../../../providers/api";
import { useMutation } from "react-query";
import ContractPDFPreview from "./ContractPDFPreview";
import PepDetails from "./FormSections/PepDetails";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import { randomId } from "../../../../utils";

const DeclarationForm = () => {
  const { t } = useTranslation("declaration-form");
  const history = useHistory();
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [contractPreview, setContractPreview] = useState(null);
  const [actualFormName, setFormName] = useState("Declaration");
  const formName = t("Declaration");
  const [data, setData] = useState(null);
  const [dataErrors, setDataErros] = useState([]);
  const [details, setDetails] = useState("");

  const [signatoriesOptions, setSignatoriesOptions] = useState([
    { value: null, label: "Select" },
  ]);
  const [tenant, setTenant] = useState(null);
  const { isLoading, error, mutate } = useMutation(submitDeclarationForm, {
    onSuccess: (res) => {
      history.push(`/contract/submitted`, {
        formName,
      });
    },
    onError: (err) => {
      console.log("Declaration form error: ", err);
      setDataErros(err);
      toast.error(err?.message ? err.message : err);
    },
  });

  const { contractId } = useParams();
  const query = useSearchQuery();
  const urlSignature = query.get("sig");

  const trackingAllowed = () => {
    try {
      return window.localStorage.getItem("QUICKR_ENABLE_TRACKING_NEW");
    } catch (error) {
      return false;
    }
  };

  const {
    register,
    trigger,
    formState: { errors },
    handleSubmit,
    control,
    setError,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    const detailsClone = JSON.parse(JSON.stringify(details));
    try {
      if (detailsClone.personRoles.length > 0) {
        const personRoles = detailsClone.personRoles.map((p) => {
          const birthDate = new Date(p.birthDate);
          let day = birthDate.getDate();
          day = day < 10 ? `0${day}` : day;
          let month = birthDate.getMonth() + 1;
          month = month < 10 ? `0${month}` : month;
          const year = birthDate.getFullYear();

          return {
            title: p.title,
            name: p.name,
            email: p.email,
            isSignatory: p.isSignatory,
            birthDate: `${day}/${month}/${year}`,
          };
        });

        const shareholders = detailsClone.shareholders.map((p) => {
          const birthDate = new Date(p.birthDate);
          let day = birthDate.getDate();
          day = day < 10 ? `0${day}` : day;
          let month = birthDate.getMonth() + 1;
          month = month < 10 ? `0${month}` : month;
          const year = birthDate.getFullYear();

          return {
            name: p.name,
            stakes: p.stakes,
            birthDate: `${day}/${month}/${year}`,
          };
        });

        detailsClone.personRoles = personRoles;
        detailsClone.shareholders = shareholders;
        if (detailsClone.bankAccount?.bankAccountNumber) {
          detailsClone.bankAccount.bankAccountNumber =
            detailsClone.bankAccount.bankAccountNumber.replace(/[^0-9]/g, "");
        }

        setContractPreview(detailsClone);
      } else {
        console.log("Insufficient details: ", details.personRoles.length);
      }
    } catch (error) {
      console.log("Error submitting declaration form: ", error);
    }
  };

  function updateSignatories(v, contractDetails = details) {
    const d = { ...contractDetails };
    if (d) {
      d.personRoles = d.personRoles.map((p) => {
        const s = p;
        s.isSignatory = false;
        return s;
      });

      if (v === "only_ceo") {
        const index = d.personRoles.findIndex((x) => x.title === "ceo");
        if (index >= 0) {
          d.personRoles[index].isSignatory = true;
        }
      } else if (v === "only_chairman") {
        const index = d.personRoles.findIndex((x) => x.title === "chairman");
        if (index >= 0) {
          d.personRoles[index].isSignatory = true;
        }
      } else {
        d.personRoles = d.personRoles.map((p) => {
          const s = p;
          s.isSignatory = true;
          return s;
        });
      }

      setDetails(d);
    }
  }

  function handleSelectChange(key1, key2 = null, e) {
    const value = e.target.value;
    if (key1 === "signatoryRoles") updateSignatories(value);

    const d = { ...details };
    if (key2) d[key1][key2] = value;
    else d[key1] = value;

    setDetails(d);
  }

  const handleValueChange = (key1, key2 = null, index = null, value) => {
    const d = { ...details };
    if (index !== null) {
      if (key2) d[key1][index][key2] = value;
      else d[key1][index] = value;
    } else {
      if (key2) d[key1][key2] = value;
      else d[key1] = value;
    }

    setDetails(d);
  };

  const handleChange = (key1, key2 = null, index = null, e) => {
    const value = e.target.value;

    const d = { ...details };
    if (index !== null) {
      if (key2) d[key1][index][key2] = value;
      else d[key1][index] = value;
    } else {
      if (key2) d[key1][key2] = value;
      else d[key1] = value;
    }

    setDetails(d);
  };

  const addNewItem = (field) => {
    const d = { ...details };
    const c = d[field];
    c.push({
      id: randomId(),
      name: null,
      stakes: null,
      manuallyAdded: true,
    });
    d[field] = c;
    setDetails(d);
  };

  const removeItem = (index, field) => {
    const d = { ...details };
    const c = [...d[field]];
    c.splice(index, 1);
    d[field] = c;
    setDetails(d);
  };

  const addAttachment = async (attachment) => {
    const d = { ...details };
    const c = d.attachments;
    c.push(attachment.url);
    d.attachments = c;
    setDetails(d);
  };

  const loadData = useCallback(async () => {
    const contractInfo = await dataLoader(contractId, urlSignature, history);

    if (!contractInfo) return;

    setTenant(contractInfo.data.tenantId);

    if (contractInfo.data.type) setFormName(contractInfo.data.type);

    let {
      data: fetchedData,
      details: fetchedDetails,
      signatories,
    } = contractInfo;

    setSignatoriesOptions(signatories);
    let localDetails;

    try {
      localDetails = window.localStorage.getItem(
        `DECLARATION_FORM_${contractId}`
      );

      if (localDetails) {
        fetchedDetails = {
          ...fetchedDetails,
          ...JSON.parse(localDetails),
          signatoryRoles: fetchedDetails.signatoryRoles,
          signingRule: fetchedDetails.signingRule,
        };
      }
    } catch (error) {}

    fetchedDetails = {
      ...fetchedDetails,
      shareholders: fetchedDetails.shareholders.map((s) => ({
        ...s,
        id: randomId(),
      })),
    };

    setDetails(fetchedDetails);
    setData(fetchedData);

    if (fetchedDetails?.signatoryRoles) {
      updateSignatories(fetchedDetails?.signatories[0], fetchedDetails);
    }

    setTimeout(() => {
      setupUserTracking();
    }, 500);
  }, [history, contractId, updateSignatories, urlSignature]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (details) {
      try {
        const clonedDetails = JSON.parse(JSON.stringify(details));
        window.localStorage.setItem(
          "DECLARATION_FORM_" + contractId,
          JSON.stringify(clonedDetails)
        );
      } catch (error) {}
    }
  }, [details]);

  const setupUserTracking = (fromCookieBanner = false) => {
    if (
      process.env.NODE_ENV != "production" ||
      process.env.REACT_APP_ENVIRONMENT != "prod"
    )
      return;

    try {
      if (trackingAllowed()) {
        setupHotjar();
        return;
      }

      if (!fromCookieBanner) setShowCookieBanner(true);
    } catch (error) {
      console.log("Localstorage setup failed!");
    }
  };

  const setupHotjar = () => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
  };

  if (data == null) {
    return (
      <div className="auth-wrapper auth-v1 px-2">
        <Loader fillParent={true} size={90} color="#ddd" />
      </div>
    );
  }

  const themeColorSet =
    details?.companyProfile?.color?.length &&
    details.companyProfile.color != "373737";

  return (
    <div
      id="declarationForm"
      className={`min-h-screen bg-gray-100 ${
        showCookieBanner && "pb-48 sm:pb-36 md:pb-32 lg:pb-32 xl:pb-24"
      }`}
    >
      <style>
        {
          /*css*/ `
          ${
            !themeColorSet
              ? ""
              : /*css*/ `
            :root {
              --theme-color: #${details.companyProfile.color};
            }

            hr{
              border-color: rgba(0, 0, 0, 0.08);
            }
            
            [dir] #declarationForm .theme-color {
              color: var(--theme-color);
            }
          `
          }

          [dir] #declarationForm .uppy-Root {
            color: #777 !important;
            border-color: #777 !important;
          }
          
          [dir] #declarationForm .uppy-DragDrop-browse{
            color: #777 !important;
          }
          [dir] #declarationForm .uppy-Root .uppy-DragDrop-arrow {
            fill: #777 !important;
          }

          #submitButton:not(:hover):not(.disabled):not(:disabled){
            box-shadow: none !important;
          }
        `
        }
      </style>
      <div
        className={`
        h-screen fixed inset-0
        ${themeColorSet ? "theme-color bg-current opacity-2.5" : "opacity-0"}
      `}
      ></div>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-6xl mx-auto lg:py-8 lg:px-10">
          <div className="flex justify-end mb-4">
            <LanguageSwitcher />
          </div>

          <IntroSection
            details={details}
            errors={errors}
            formName={formName}
            register={register}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />

          <hr className="my-5" />

          <CompanyDetails
            details={details}
            errors={errors}
            register={register}
            handleValueChange={handleValueChange}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />

          <hr className="my-5" />

          <BankingAndCurrencyDetails
            details={details}
            errors={errors}
            register={register}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />

          <hr className="my-5" />

          <PurposeForPaymentService
            details={details}
            errors={errors}
            register={register}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
          />

          <hr className="my-5" />

          <CompanyOwnershipDetails
            details={details}
            errors={errors}
            register={register}
            handleValueChange={handleValueChange}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
            addNewItem={addNewItem}
            removeItem={removeItem}
          />

          <hr className="my-5" />

          <CompanyManagementAndSignatoryRights
            details={details}
            formName={formName}
            errors={errors}
            trigger={trigger}
            register={register}
            handleChange={handleChange}
            handleValueChange={handleValueChange}
            handleSelectChange={handleSelectChange}
            signatoriesOptions={signatoriesOptions}
          />

          <hr className="my-5" />

          <PepDetails
            details={details}
            errors={errors}
            register={register}
            handleChange={handleChange}
            handleValueChange={handleValueChange}
            handleSelectChange={handleSelectChange}
            signatoriesOptions={signatoriesOptions}
          />

          {details?.pricing?.length > 0 && (
            <>
              <hr className="my-5" />

              <Pricing
                details={details}
                errors={errors}
                register={register}
                handleValueChange={handleValueChange}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                removeItem={removeItem}
                addAttachment={addAttachment}
              />
            </>
          )}

          {details?.terms?.length > 0 && (
            <>
              <hr className="my-5" />

              <Terms
                details={details}
                errors={errors}
                register={register}
                handleValueChange={handleValueChange}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                removeItem={removeItem}
                addAttachment={addAttachment}
              />
            </>
          )}

          {/* <hr className="my-5" />

          <RequiredDocumentationAndAttachments
            tenantId={tenant}
            details={details}
            formName={formName}
            errors={errors}
            register={register}
            handleValueChange={handleValueChange}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
            removeItem={removeItem}
            addAttachment={addAttachment}
          /> */}

          <div className="mt-12 mb-5 max-w-[200px] ml-auto">
            <Button
              id="submitButton"
              color="primary"
              type="submit"
              block
              loading={isLoading}
              className="transition duration-300"
              style={
                !themeColorSet
                  ? {}
                  : {
                      backgroundColor: "var(--theme-color)",
                      borderColor: "var(--theme-color)",
                      boxShadow: "0 8px 25px -8px var(--theme-color)",
                    }
              }
            >
              {t("continue")}
            </Button>
          </div>
        </div>
      </form>
      <ContractPDFPreview
        formName={formName}
        contractId={contractId}
        urlSignature={urlSignature}
        tenant={tenant}
        contract={contractPreview}
        onClose={() => setContractPreview(null)}
      />
      <Transition
        as={Fragment}
        show={showCookieBanner}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        <div className="z-50 fixed inset-x-0 bottom-0 negative-shadow bg-white">
          <div className="container py-4 lg:py-7 lg:flex items-center lg:px-4">
            <div
              className="flex-1 text-center px-6"
              dangerouslySetInnerHTML={{ __html: t("cookie-banner.prompt") }}
            ></div>

            <div className="mt-3 lg:mt-0 ml-auto flex items-center justify-center space-x-2">
              <Button
                color="primary"
                onClick={() => {
                  window.localStorage.setItem(
                    "QUICKR_ENABLE_TRACKING_NEW",
                    true
                  );
                  setupUserTracking(true);
                  setShowCookieBanner(false);
                }}
              >
                {t("cookie-banner.accept")}
              </Button>

              <Button
                outline
                color="primary"
                onClick={() => setShowCookieBanner(false)}
              >
                {t("cookie-banner.decline")}
              </Button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default DeclarationForm;
