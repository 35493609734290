import { useTranslation } from "react-i18next";
import useSearchQuery from "../../../hooks/useSearchQuery";
import ContractMessage from "./ContractMessage";

const ContractSigned = () => {
  const { t } = useTranslation("public-pages");
  const query = useSearchQuery();
  const formName = query.get("form_name");

  return (
    <ContractMessage
      title={t("contract-signed.title", {
        formName: formName || "Declaration",
      })}
      subTitle={t("contract-signed.subtitle", {
        formName: formName || "Declaration",
      })}
      nextSteps={[
        t("contract-signed.next-steps.1", {
          formName: formName || "Declaration",
        }),
        t("contract-signed.next-steps.2", {
          formName: formName || "Declaration",
          companyName: "Dintero AS",
          signingMethod: "BankID",
        }),
      ]}
      message={t("contract-signed.message", {
        tenantEmail: "onboarding@dintero.com",
      })}
    />
  );
};
export default ContractSigned;
