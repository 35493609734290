import {
  getValueFromLocalStorage,
  saveValueToLocalStorage,
} from "../../hooks/useLocalStorageState";

export const BASE_URL = process.env.REACT_APP_AWS_APIGATEWAY_URL;

class HTTPError extends Error {
  constructor(body, statusCode) {
    super(body);
    this.statusCode = statusCode;
    this.body = body;
  }
}

export class HTTP404Error extends HTTPError {
  constructor(body) {
    super(body, 404);
  }
}

export const request = async (
  type,
  endpoint,
  data,
  headers,
  extrRequestParams = {}
) => {
  if (!navigator.onLine) throw new Error("Offline!");

  const tenant = getValueFromLocalStorage("tenant", null);
  const token = getValueFromLocalStorage("authToken", null);
  let userHeaders;

  if (headers) {
    userHeaders = {
      Tenant: tenant?.id,
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      ...headers,
    };
  }

  const requestProps = {
    method: type,
    headers: userHeaders || {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_QUICKR_API_KEY,
      Tenant: tenant?.id,
      Authorization: `Bearer ${token}`,
    },
    ...extrRequestParams,
  };

  if (data) requestProps.body = JSON.stringify(data);

  const response = await fetch(BASE_URL + endpoint, requestProps);

  if (response.status == 401 && window.location.href.indexOf("/login") == -1) {
    const event = new Event("session-expired");
    window.dispatchEvent(event);

    return;
  }

  if (response.status === 404) {
    throw new HTTP404Error(await response.json());
  }

  if (userHeaders && userHeaders["Accept"] != "application/json") {
    if (!response.ok) throw new Error("An error occured!");
    return response;
  } else {
    const responseData = await response.json();

    if (!response.ok) throw responseData;

    return responseData;
  }
};

export const get = async (endpoint) => {
  return await request("get", endpoint);
};

export const patch = async (endpoint, data) => {
  return await request("patch", endpoint, data);
};

export const put = async (endpoint, data) => {
  return await request("put", endpoint, data);
};

export const post = async (endpoint, data) => {
  return await request("post", endpoint, data);
};

export const remove = async (endpoint) => {
  return await request("delete", endpoint);
};
