import { Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import {
  ChevronRightIcon,
  DocumentTextIcon,
  DownloadIcon,
  ExternalLinkIcon,
} from "@heroicons/react/solid";
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { formatDate, formatDateTime, formatTime } from "../../../../../utils";
import Button from "../../../../components/Button";
import ActionButton from "../../../../components/Button/ActionButton";
import Select from "../../../../components/formControl/Select";
import ReviewStep from "../../../../components/ReviewStep";
import CaseSignatories from "../CaseSignatories";
import AddReviewNotes from "./AddReviewNotes";
import InlineCaseDetails from "./InlineCaseDetails";
import CreditScoreResults from "./CreditScoreResults";
import LogDetailsSidebar from "./LogDetailsSidebar";

const CheckCircle = ({ width = "20" }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  );
};

const CaseDetails = ({
  data,
  loadData,
  formName,
  previousSelectedLogId,
  updateSelectedLogid,
  saveLog,
  block,
  setBlock,
}) => {
  const history = useHistory();
  const { t } = useTranslation("cases");
  const [selectedLog, setSelectedLog] = useState(null);
  const [selectedLogId, setSelectedLogId] = useState(null);
  const [selectedLogNoContract, setSelectedLogNoContract] = useState(null);
  const [notes, setNotes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [note, setNote] = useState([]);
  const [logsOptions, setLogsOptions] = useState([]);
  const [caseData, setCaseData] = useState([]);

  const [contractSidebarOpen, setContractSidebarOpen] = useState(false);
  const toggleContractSidebar = () =>
    setContractSidebarOpen(!contractSidebarOpen);

  const [caseSidebarOpen, setCaseSidebarOpen] = useState(false);
  const toggleCaseSidebar = () => setCaseSidebarOpen(!caseSidebarOpen);

  const [caseLogSidebarOpen, setCaseLogSidebarOpen] = useState(false);
  const [caseLogSidebarData, setCaseLogSidebarData] = useState({});
  const toggleCaseLogSidebar = () => setCaseLogSidebarOpen(!caseLogSidebarOpen);

  const [caseNotesSidebarOpen, setCaseNotesSidebarOpen] = useState(false);
  const [caseNotesSidebarData, setCaseNotesSidebarData] = useState({});
  const [expandedChecks, setExpandedChecks] = useState([]);
  const toggleCaseNotesSidebar = () =>
    setCaseNotesSidebarOpen(!caseNotesSidebarOpen);

  useEffect(() => {
    try {
      const orderedLogs = data.checksResults
        ? data.checksResults.sort((a, b) => a.timestamp - b.timestamp)
        : [];
      const logs = orderedLogs.map((log) => {
        return { value: log.id, label: formatDateTime(log.createdAt) };
      });

      if (logs.length) {
        const currentLog =
          orderedLogs.find(({ id }) => id == previousSelectedLogId) ||
          orderedLogs[orderedLogs.length - 1];

        setLogsOptions(logs);
        setSelectedLog(currentLog);
        setNotes(currentLog.notes ? currentLog.notes : []);
        setAttachments(currentLog.attachments ? currentLog.attachments : []);
        updateSelectedLogid(currentLog.id);
        setSelectedLogId(currentLog.id);
        const c = { ...data };
        delete c.checksResults;
        delete c.recentLog;
        setCaseData(c);
      } else {
        setLogsOptions([{ value: null, label: "No logs yet" }]);
        setSelectedLog(data);
        setCaseData(data.details);
      }
    } catch (e) {
      console.log("Error setting up logs", e);
    }
  }, [data]);

  const handleSelectChange = async (e) => {
    const value = e.target.value;
    const log = data.checksResults.find((l) => {
      return l.id === value;
    });
    setSelectedLog(log);
    setNotes(log.notes ? log.notes : []);
    setAttachments(log.attachments ? log.attachments : []);
    updateSelectedLogid(log.id);
    const logNoContract = { ...log };
    delete logNoContract.contract;
    setSelectedLogNoContract(logNoContract);
  };

  function handleAddNotesToSection() {
    toggleCaseNotesSidebar();
  }

  function toggleExpandedCheck(check) {
    if (expandedChecks.includes(check))
      setExpandedChecks(expandedChecks.filter((c) => c != check));
    else setExpandedChecks([...expandedChecks, check]);
  }

  if (
    data == null ||
    ((!logsOptions.length || selectedLog == null) &&
      data.caseStatus != "WAITING_FOR_SIGNATURE")
  )
    return;

  const declarationFormFilled =
    data.caseStatus != "WAITING_FOR_DECLARATION" &&
    data.contracts?.length &&
    data.contracts[0]?.isSubmitted;


  return (
    <div className="">
      <div
        className={
          selectedLogId && declarationFormFilled ? "mb-9" : "mt-0.5 mb-5"
        }
      >
        <div className="flex items-center justify-between">
          <h2 className="m-0 p-0 text-black opacity-70 text-lg leading-none font-semibold">
            {t("declaration-form-details.title", { formName })}
          </h2>

          {declarationFormFilled && (
            <div className="flex items-center space-x-3">
              {data.contracts[0].pdfSummaryUrl?.length && (
                <a href={data.contracts[0].pdfSummaryUrl} target="_blank">
                  <Button size="md" className="border-gray-400">
                    <DownloadIcon
                      className="mr-2 text-gray-600"
                      stroke="currentColor"
                      strokeWidth={0.1}
                      width={16}
                    />

                    {t("declaration-form-details.download")}
                  </Button>
                </a>
              )}

              <Link
                to={`/contract/preview/${data.id}?form_name=${formName}`}
                target="_blank"
              >
                <Button size="md" className="border-gray-400">
                  <ExternalLinkIcon
                    className="mr-2 text-gray-600"
                    stroke="currentColor"
                    strokeWidth={0.1}
                    width={16}
                  />

                  {t("declaration-form-details.view")}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {data.caseStatus == "DECLARATION_SUBMITTED" && (
        <div className="mt-4 mb-7">
          <div className="py-5 px-6 bg-white shadow-sm rounded overflow-hidden border z-10 relative flex justify-between">
            {t("declaration-submitted.message", { formName })}
            <button
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center underline text-sm text-gray-600"
              onClick={() => window.location.reload()}
            >
              {t("declaration-submitted.button", { formName })}
            </button>
          </div>
        </div>
      )}

      {selectedLog && selectedLogId && (
        <div className="bg-white rounded shadow-sm border py-4 px-5 mb-11">
          <Select
            label={t("current-case-log")}
            onChange={handleSelectChange}
            noPlaceholder
            value={selectedLog.id}
            choices={logsOptions}
          />
        </div>
      )}

      <div>
        {selectedLog?.KYC && (
          <ReviewStep
            heading={t("checks.KYC.name")}
            subHeading={t("checks.KYC.description")}
            onAddNotes={handleAddNotesToSection}
          >
            <table className="table">
              <thead>
                <tr>
                  <th width="55%">{t("checks-performed")}</th>
                  <th>{t("timestamp")}</th>
                  <th className="text-center">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.KYC.checks.customer-legal-name")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(
                        selectedLog.KYC.legalNameAddressDeclaration?.checkedAt
                      )}{" "}
                      |{" "}
                      {formatTime(
                        selectedLog.KYC.legalNameAddressDeclaration?.checkedAt
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.KYC.legalNameAddressDeclaration
                            ?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.KYC.legalNameAddressDeclaration
                            ?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.KYC.legalNameAddressDeclaration && (
                  <InlineCaseDetails
                    data={selectedLog.KYC.legalNameAddressDeclaration}
                  />
                )}

                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.KYC.checks.purpose-of-relationship")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(
                        selectedLog.KYC.relationshipPurposeDeclaration
                          ?.checkedAt
                      )}{" "}
                      |{" "}
                      {formatTime(
                        selectedLog.KYC.relationshipPurposeDeclaration
                          ?.checkedAt
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.KYC.relationshipPurposeDeclaration
                            ?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.KYC.relationshipPurposeDeclaration
                            ?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.KYC.relationshipPurposeDeclaration && (
                  <InlineCaseDetails
                    data={selectedLog.KYC.relationshipPurposeDeclaration}
                  />
                )}

                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.KYC.checks.beneficial-ownership")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(selectedLog.KYC.uboDeclaration?.checkedAt)} |{" "}
                      {formatTime(selectedLog.KYC.uboDeclaration?.checkedAt)}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.KYC.uboDeclaration?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.KYC.uboDeclaration?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.KYC.uboDeclaration && (
                  <InlineCaseDetails data={selectedLog.KYC.uboDeclaration} />
                )}
              </tbody>
            </table>
          </ReviewStep>
        )}

        {selectedLog?.AML && (
          <ReviewStep
            heading={t("checks.AML.name")}
            subHeading={t("checks.AML.description")}
            onAddNotes={handleAddNotesToSection}
          >
            <table className="table">
              <thead>
                <tr>
                  <th width="55%">{t("checks-performed")}</th>
                  <th className="py-1 ">{t("timestamp")}</th>
                  <th className="text-center">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.AML.checks.pep-details")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(selectedLog.AML.personsCheck?.checkedAt)} |{" "}
                      {formatTime(selectedLog.AML.personsCheck?.checkedAt)}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.AML.personsCheck?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.AML.personsCheck?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.AML.personsCheck && (
                  <InlineCaseDetails data={selectedLog.AML.personsCheck} />
                )}

                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.AML.checks.sanctions-and-criminal-checks")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(selectedLog.AML.entityCheck?.checkedAt)} |{" "}
                      {formatTime(selectedLog.AML.entityCheck?.checkedAt)}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.AML.entityCheck?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.AML.entityCheck?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.AML.entityCheck && (
                  <InlineCaseDetails data={selectedLog.AML.entityCheck} />
                )}
              </tbody>
            </table>
          </ReviewStep>
        )}

        <CaseSignatories
          data={data}
          loadData={loadData}
          formName={formName}
          onAddNotes={selectedLogId?.length ? handleAddNotesToSection : null}
        />

        {selectedLog?.BANK_OWNERSHIP && (
          <ReviewStep
            heading={t("checks.BANK_OWNERSHIP.name")}
            subHeading={t("checks.BANK_OWNERSHIP.description")}
            onAddNotes={handleAddNotesToSection}
          >
            <table className="table">
              <thead>
                <tr>
                  <th width="55%">{t("checks-performed")}</th>
                  <th className="py-1 ">{t("timestamp")}</th>
                  <th className="text-center">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.BANK_OWNERSHIP.checks.account-number")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(
                        selectedLog.BANK_OWNERSHIP.bankAccountDeclaration
                          ?.checkedAt
                      )}{" "}
                      |{" "}
                      {formatTime(
                        selectedLog.BANK_OWNERSHIP.bankAccountDeclaration
                          ?.checkedAt
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.BANK_OWNERSHIP.bankAccountDeclaration
                            ?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.BANK_OWNERSHIP.bankAccountDeclaration
                            ?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.BANK_OWNERSHIP.bankAccountDeclaration && (
                  <InlineCaseDetails
                    data={selectedLog.BANK_OWNERSHIP.bankAccountDeclaration}
                  />
                )}

                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.BANK_OWNERSHIP.checks.account-ownership")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(
                        selectedLog.BANK_OWNERSHIP.bankAccountCheck?.checkedAt
                      )}{" "}
                      |{" "}
                      {formatTime(
                        selectedLog.BANK_OWNERSHIP.bankAccountCheck?.checkedAt
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.BANK_OWNERSHIP.bankAccountCheck
                            ?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.BANK_OWNERSHIP.bankAccountCheck
                            ?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>

                {selectedLog.BANK_OWNERSHIP.bankAccountCheck && (
                  <InlineCaseDetails
                    data={selectedLog.BANK_OWNERSHIP.bankAccountCheck}
                  />
                )}
              </tbody>
            </table>
          </ReviewStep>
        )}

        {selectedLog?.CREDIT?.creditScore && (
          <CreditScoreResults
            data={data}
            selectedLog={selectedLog} 
            handleAddNotesToSection={handleAddNotesToSection}
          />
        )}

        {selectedLog?.INDUSTRY && (
          <ReviewStep
            heading={t("checks.FINANCIAL.name")}
            subHeading={t("checks.FINANCIAL.description")}
            onAddNotes={handleAddNotesToSection}
          >
            <table className="table">
              <thead>
                <tr>
                  <th width="55%">{t("checks-performed")}</th>
                  <th className="py-1 ">{t("timestamp")}</th>
                  <th className="text-center">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.FINANCIAL.checks.custom-legal-entity")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(selectedLog?.RISK?.financial?.checkedAt)} |{" "}
                      {formatTime(selectedLog?.RISK?.financial?.checkedAt)}
                    </span>
                  </td>
                  {/* <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog?.RISK?.financial?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog?.RISK?.financial?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td> */}
                </tr>

                {selectedLog?.RISK?.financial && (
                  <InlineCaseDetails data={selectedLog?.RISK?.financial} />
                )}

                {/* <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.INDUSTRY.checks.custom-commercial-risk")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(
                        selectedLog.INDUSTRY.companyTypeDeclared?.checkedAt
                      )}{" "}
                      |{" "}
                      {formatTime(
                        selectedLog.INDUSTRY.companyTypeDeclared?.checkedAt
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.INDUSTRY.companyTypeDeclared?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.INDUSTRY.companyTypeDeclared
                            ?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr> */}

                {/* {selectedLog.INDUSTRY.companyTypeDeclared && (
                  <InlineCaseDetails
                    data={selectedLog.INDUSTRY.companyTypeDeclared}
                  />
                )} */}

                {/* <tr>
                  <td>
                    <p className="card-text text-nowrap">
                      {t("checks.INDUSTRY.checks.mcc")}
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDate(selectedLog.INDUSTRY.mccDeclared?.checkedAt)}{" "}
                      |{" "}
                      {formatTime(selectedLog.INDUSTRY.mccDeclared?.checkedAt)}
                    </span>
                  </td>
                  <td>
                    <span className="flex justify-center pointer-events-none">
                      <ActionButton
                        color={
                          selectedLog.INDUSTRY.mccDeclared?.finalResult
                            ? "light-success"
                            : "light-warning"
                        }
                        icon={
                          selectedLog.INDUSTRY.mccDeclared?.finalResult ? (
                            <CheckCircle width={14} />
                          ) : (
                            <InformationCircleIcon width={18} />
                          )
                        }
                      />
                    </span>
                  </td>
                </tr> */}

                {/* {selectedLog.INDUSTRY.mccDeclared && (
                  <InlineCaseDetails data={selectedLog.INDUSTRY.mccDeclared} />
                )} */}

                {/* <tr>
                  <td className='py-1'>
                    <p className='card-text text-nowrap'>
                      {t('checks-performed')}
                    </p>
                  </td>
                  <td className='py-1'>
                    <span className='font-weight-bold'>
                      {formatDate(selectedLog.INDUSTRY.mccChecked?.checkedAt)} |{' '}
                      {formatTime(selectedLog.INDUSTRY.mccChecked?.checkedAt)}
                    </span>
                  </td>
                  <td className='py-1'>
                    {!selectedLog.INDUSTRY.mccChecked?.finalResult && (
                      <>
                        <ActionButton
                          color='light-warning'
                          icon={<InformationCircleIcon width={14} />}
                          onClick={() => {
                            setCaseLogSidebarOpen(true)
                            setCaseLogSidebarData(
                              selectedLog.INDUSTRY.mccChecked
                            )
                          }}
                        />
                      </>
                    )}
                    {selectedLog.INDUSTRY.mccChecked?.finalResult && (
                      <ActionButton
                        color='light-success'
                        icon={<CheckCircle width={14} />}
                        onClick={() => {
                          setCaseLogSidebarOpen(true)
                          setCaseLogSidebarData(selectedLog?.INDUSTRY?.mccChecked)
                        }}
                      />
                    )}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </ReviewStep>
        )}

        {/* {notes.length > 0 && ( */}
        <ReviewStep
          heading={t("notes-and-attachments.title")}
          subHeading={t("notes-and-attachments.description")}
          // onAddNotes={handleAddNotesToSection}
        >
          <table className="table">
            <thead>
              <tr>
                <th width="40%">{t("notes")}</th>
                <th width="60%">{t("attachments")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul className="space-y-1">
                    {notes.length > 0 ? (
                      notes.map((note, index) => (
                        <li key={index}>
                          <p className="card-text text-nowrap">
                            {note.note}
                            <br />
                            <small className="text-muted">
                              {formatDate(note.createdAt)} |{" "}
                              {formatTime(note.createdAt)}
                            </small>
                          </p>
                        </li>
                      ))
                    ) : (
                      <p className="text-muted">{t("no-notes")}</p>
                    )}
                  </ul>
                </td>
                <td>
                  <ul className="flex flex-wrap">
                    {attachments.length > 0 ? (
                      attachments.map((attachment, index) => {
                        let fileName = !attachment.name
                          ? ""
                          : [...attachment.name.split("-")].at(-1);
                        let attachmentName = fileName.substr(-16);
                        if (fileName.length > 16)
                          attachmentName = "..." + attachmentName;

                        return (
                          <li key={index}>
                            <a
                              href={attachment.url}
                              rel="noreferrer"
                              target="_blank"
                              className="inline-flex items-center group border border-gray-400/60 hover:border-gray-400/70 hover:bg-gray-200/50 text-gray-600 hover:text-black rounded-full pt-1 pb-1.5 pl-2 pr-3 mr-2 mb-2 transition-colors duration-150"
                            >
                              <DocumentTextIcon className="opacity-60 w-3.5 h-3.5 mt-0.5 mr-1 flex-shrink-0" />
                              <span className="text-xs leading-none w-full truncated pt-px">
                                {attachmentName}
                              </span>
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <p className="text-muted">{t("no-attachments")}</p>
                    )}
                  </ul>
                </td>
                {/* <p className="text-muted">No attachments added</p>
              {/* {notes.map((item, i) => (
                <tr key={i}>
                  <td>
                    <p className="card-text font-weight-bold mb-25">
                      {item.text} some notes today
                    </p>
                  </td>
                  <td>
                    <span className="font-weight-bold">
                      {formatDateTime(item.createdAt)}
                      24
                    </span>
                  </td>
                </tr>
              ))} */}
              </tr>
            </tbody>
          </table>
        </ReviewStep>
      </div>

      <LogDetailsSidebar
        title="Case details"
        toggleSidebar={toggleCaseSidebar}
        open={caseSidebarOpen}
        data={caseData}
      />

      <LogDetailsSidebar
        title="CDD check details"
        toggleSidebar={toggleCaseLogSidebar}
        open={caseLogSidebarOpen}
        data={caseLogSidebarData}
      />

      <AddReviewNotes
        toggleSidebar={toggleCaseNotesSidebar}
        open={caseNotesSidebarOpen}
        data={data}
        saveLog={saveLog}
        block={block}
        setBlock={setBlock}
      />
    </div>
  );
};

export default CaseDetails;
