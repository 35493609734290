import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  LinkIcon,
} from "@heroicons/react/solid";

import { customDescription } from "../../../../constants";
import Input from "../../../components/formControl/Input";
import Textarea from "../../../components/formControl/Textarea";
import Label from "../../../components/formControl/Label";
import Button from "../../../components/Button";
import Switch from "../../../components/formControl/Switch";
import LogoUploader from "../../../components/LogoUploader";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";
import ModalMessageCentered from "../../../components/ModalMessageCentered";
import Loader from "../../../components/Loader";

import { createNewCase } from "../../../../providers/api";
import { defaultThemeColors } from "../../../../constants";

import CaseProducts from "./CaseProducts";
import CaseTerms from "./CaseTerms";
import { useTranslation } from "react-i18next";
import {
  isValidOrgNumber,
  randomId,
  trimSpecialCharaters,
} from "../../../../utils";
import { HTTP404Error } from "../../../../providers/api/request";
import { getContractUrl } from "../../../../utils/contractUrl";

function CopyLinkButton({ link }) {
  const { t } = useTranslation("cases");
  const { copy: copyLink, status: copyStatus } = useCopyToClipboard();

  return (
    <Button
      type="button"
      color="dark"
      outline
      className="w-full flex items-center justify-center"
      onClick={(e) => {
        e.preventDefault();
        copyLink(link, 700);
      }}
    >
      {copyStatus !== "SUCCEEDED" ? (
        <span className="inline-flex items-center">
          <LinkIcon className="mr-2.5" width={14} />
          {t("copy-link")}
        </span>
      ) : (
        <span className="inline-flex items-center text-green-500">
          <CheckCircleIcon
            className="mr-2.5"
            fill="currentColor"
            stroke="white"
            strokeWidth={2.5}
            width={14}
          />
          {t("link copied")}
        </span>
      )}
    </Button>
  );
}

const CreateCase = () => {
  const { t, i18n } = useTranslation("cases");
  const history = useHistory();
  const [contractUrl, setContractUrl] = useState("");
  const [optionalChecks, setOptionalChecks] = useState([
    {
      selected: true,
      code: "BANK_OWNERSHIP",
    },
    {
      selected: true,
      code: "RISK",
    },
    {
      selected: true,
      code: "CREDIT",
    },
    {
      selected: true,
      code: "INDUSTRY",
    },
  ]); //['KYC', 'AML']);
  const [contractSaveStatus, setContractSaveStatus] = useState();
  const [error, setError] = useState(t("error-message.body"));
  const [colorChoices, setColorChoices] = useState(defaultThemeColors);

  const [data, setData] = useState({
    formName: "Declaration",
    countryCode: "no",
    organizationNumber: "",
    companyProfile: {
      description: customDescription(i18n.language),
      color: "373737",
    },
  });

  const handleChange = (key1, key2 = null, index = null, value) => {
    const d = { ...data };
    if (index !== null) {
      if (key2) {
        d[key1][index][key2] = value.target.value;
      } else {
        d[key1][index] = value.target.value;
      }
    } else {
      if (key2) {
        d[key1][key2] = value.target.value;
      } else {
        d[key1] = value.target.value;
      }
    }

    setData(d);
  };

  const handleAddTerm = () => {
    const newTerm = {
      name: "",
      url: "",
      isChecked: false,
    };

    setData({
      ...data,
      terms: data.terms ? [...data.terms, newTerm] : [newTerm],
    });
  };

  const handleRemoveTerm = (removedTermIndex) => {
    setData({
      ...data,
      terms: data.terms.filter((_, index) => index != removedTermIndex),
    });
  };

  const handleAddProduct = async () => {
    const newProduct = {
      tempId: randomId(),
      product: "",
      description: "--",
      price: "",
    };

    setData({
      ...data,
      pricing: data.pricing ? [...data.pricing, newProduct] : [newProduct],
    });
  };

  const handleRemoveProduct = (removedProductIndex) => {
    setData({
      ...data,
      pricing: data.pricing.filter((_, index) => index != removedProductIndex),
    });
  };

  const handleChangeCompanyProfileField = (key, value) => {
    const companyProfile = { ...data.companyProfile };
    companyProfile[key] = value;
    setData({ ...data, companyProfile });
  };

  const handleAddCustomColor = (e) => {
    let color = e.target.value;
    color = color.replace("#", "");
    setData({ ...data, color });
    handleChangeCompanyProfileField("color", color);

    if (colorChoices.length == 13) setColorChoices([...colorChoices, color]);
    else setColorChoices(Object.assign(colorChoices, { 13: color }));
  };

  const addAttachment = async (attachment) => {
    if (!attachment || !attachment.url?.length) return;

    handleChangeCompanyProfileField("logo", attachment.url);
  };

  const saveContract = async () => {
    const payload = {
      ...data,
      countryCode: data.countryCode,
      organizationNumber: trimSpecialCharaters(data.organizationNumber),
      requireContractSigning: true,
      checks: [
        "KYC",
        "AML",
        ...optionalChecks
          .filter(({ selected }) => selected)
          .map(({ code }) => code),
      ],
      // checks: ['KYC', 'AML', 'RISK', 'INDUSTRY', 'BANK_OWNERSHIP'],
    };

    if (data.terms?.length) {
      const validTerms = data.terms.filter(({ name, url }) => {
        return name?.trim().length && url?.trim().length;
      });
      if (validTerms.length) payload.terms = validTerms;
    }
    if (data.pricing?.length) {
      const validPricing = data.pricing.filter(({ product, price }) => {
        return product?.trim().length && price?.toString().trim().length;
      });
      if (validPricing.length)
        payload.pricing = validPricing.map(({ tempId, ...product }) => product);
    }

    if (payload.pricing || payload.terms || payload.companyProfile) {
      payload.details = {};
      if (payload.pricing?.length) payload.details.pricing = payload.pricing;
      if (payload.terms?.length) payload.details.terms = payload.terms;
      if (payload.companyProfile)
        payload.details.companyProfile = payload.companyProfile;
    }

    return createNewCase(payload);
  };

  const {
    register,
    trigger,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const onSubmit = async (e) => {
    setContractSaveStatus("LOADING");
    setContractUrl(null);

    try {
      const response = await saveContract(null);
      setContractSaveStatus("SUCCESS");
      setContractUrl(getContractUrl(response.data.contracts[0]));
    } catch (error) {
      setContractSaveStatus("ERROR");
      setError(
        error instanceof HTTP404Error
          ? t("invalid-company-number")
          : t("error-message.body")
      );
    }
  };

  function renderActions() {
    return (
      <>
        <CopyLinkButton link={contractUrl} />

        <a
          href={contractUrl}
          className="block w-full"
          target="_blank"
          rel="noreferrer"
        >
          <Button block color="primary" outline>
            <ExternalLinkIcon className="mr-2.5" width={14} />
            {t("open-contract", { formName: data.formName ?? "Declaration" })}
          </Button>
        </a>
      </>
    );
  }

  const handleCloseModal = (redirectToCases) => {
    setContractSaveStatus(null);
    if (redirectToCases) goBackHome();
  };

  const goBackHome = () => {
    history.replace("/");
  };

  return (
    <div className="md:pt-8 pb-16 max-w-3xl mx-auto">
      <div className="bg-white shadow-sm rounded-md overflow-hidden border pb-4">
        <div className="px-6 py-4 border-b">
          <h4 className="text-xl font-semibold text-black m-0 leading-none">
            {t("create-new-case")}
          </h4>
        </div>
        <form className="px-7 pt-6" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-black mb-3 font-semibold leading-none">
            {t("company-details")}
          </h2>
          <div className="space-y-6 lg:space-y-0 lg:grid grid-cols-2 gap-4 mb-6">
            <Input
              label={t("country-code")}
              type="text"
              value={data.countryCode}
              disabled
              name="countryCode"
              id="countryCode"
            />

            <Input
              label={t("organisation-number")}
              value={data.organizationNumber}
              onChange={(e) =>
                handleChange("organizationNumber", null, null, e)
              }
              type="text"
              name="organizationNumber"
              id="organizationNumber"
              placeholder="E.g. 919656395"
              validator={register("organizationNumber", {
                validate: () => isValidOrgNumber(data.organizationNumber),
              })}
              error={errors.organizationNumber}
              onBlur={() => trigger(["organizationNumber"])}
            />

            <div>
              <div>
                <Label htmlFor="organizationLogo">
                  {t("organisation-logo")}
                </Label>
                <div>
                  <LogoUploader flat onAddAttachment={addAttachment} />
                </div>
              </div>
            </div>

            <div>
              <div>
                <Label htmlFor="customColor">{t("theme-color")}</Label>
                <div className="border px-4 py-5 rounded">
                  {colorChoices?.length > 0 && (
                    <div className="pt-0.5 grid grid-cols-7 gap-x-3 gap-y-4">
                      {colorChoices.map((color, index) => {
                        const selected =
                          color.replace("#", "") === data.companyProfile.color;

                        return (
                          <button
                            type="button"
                            key={index}
                            className="border rounded-full overflow-hidden w-8 h-8 p-1 flex items-center justify-center"
                            style={{
                              background: `#${color.replace("#", "")}`,
                              borderRadius: "50%",
                            }}
                            onClick={() =>
                              handleChangeCompanyProfileField(
                                "color",
                                color.replace("#", "")
                              )
                            }
                          >
                            {selected && (
                              <span
                                className="rounded-full w-full h-full"
                                style={{ border: "2.5px solid #fff" }}
                              ></span>
                            )}
                          </button>
                        );
                      })}
                      <label
                        htmlFor="customColor"
                        className="border rounded-full w-10 h-10 p-1 flex items-center justify-center"
                      >
                        <input
                          className="hidden"
                          type="color"
                          id="customColor"
                          // defaultValue={data.color}
                          value={data.color}
                          onChange={handleAddCustomColor}
                        />
                        <svg viewBox="0 0 24 24" fill="#888">
                          <path d="M12 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.04 10 9c0 3.31-2.69 6-6 6h-1.77c-.28 0-.5.22-.5.5 0 .12.05.23.13.33.41.47.64 1.06.64 1.67A2.5 2.5 0 0 1 12 22zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5a.54.54 0 0 0-.14-.35c-.41-.46-.63-1.05-.63-1.65a2.5 2.5 0 0 1 2.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7z" />
                          <circle cx="6.5" cy="11.5" r="1.5" />
                          <circle cx="9.5" cy="7.5" r="1.5" />
                          <circle cx="14.5" cy="7.5" r="1.5" />
                          <circle cx="17.5" cy="11.5" r="1.5" />
                        </svg>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="relative">
              <Input
                label={t("form-name")}
                value={data.formName}
                onChange={(e) => handleChange("formName", null, null, e)}
                onKeyUp={(e) =>
                  data.formName?.length >= 25 && e.nativeEvent.keyCode != 8
                    ? setData({
                        ...data,
                        formName: data.formName.substring(0, 25),
                      })
                    : null
                }
                type="text"
                name="formName"
                id="formName"
                placeholder="E.g. Declaration"
                validator={register("formName", {
                  required: true,
                  maxLength: 25,
                })}
                error={errors.formName}
              />

              <p className="text-xs mt-1 text-gray-500/90">
                {t("form-name-hint")}
              </p>

              <span className="absolute right-1 top-10 text-[0.65rem] py-0.5 px-1">
                <span
                  className={data.formName?.length > 25 ? "text-red-500" : ""}
                >
                  {data.formName?.length || 0}
                </span>{" "}
                / 25
              </span>
            </div>

            <Textarea
              wrapperClassName="col-span-2"
              label={t("description")}
              rows="3"
              id="customDescription"
              name="customDescription"
              value={data.companyProfile.description}
              onChange={(e) =>
                handleChangeCompanyProfileField("description", e.target.value)
              }
              placeholder="Enter your custom description here"
              validator={register("customDescription", {
                required: true,
              })}
              error={errors.customDescription}
            />
          </div>

          <hr className="-mx-7" />

          <CaseTerms
            data={data}
            setData={setData}
            onAddTerm={handleAddTerm}
            onRemoveTerm={handleRemoveTerm}
          />

          <hr className="-mx-7" />

          <CaseProducts
            data={data}
            setData={setData}
            onAddProduct={handleAddProduct}
            onRemoveProduct={handleRemoveProduct}
          />

          <hr className="-mx-7" />

          <div className="mt-6">
            <h2 className="text-black leading-none mb-2.5 font-semibold">
              {t("optional-checks")}
            </h2>
            {/* <p className="mb-">
                  These checks are not mandatory for Quickr to function, so feel
                  free to turn off the ones not required by your verification
                  process.
                  </p> */}
            <div className="space-y-3">
              {optionalChecks.map((check, index) => {
                const handleCheckClicked = (checked) => {
                  setOptionalChecks(
                    [...optionalChecks].map((check, idx) => {
                      if (index == idx) {
                        return {
                          ...optionalChecks[idx],
                          selected: checked,
                        };
                      }

                      return check;
                    })
                  );
                };

                return (
                  <button
                    aria-labelledby={`checkName${index}`}
                    type="button"
                    key={index}
                    className={`w-full text-left relative mt-2 border rounded-md pt-3.5 pb-3 pl-3.5 pr-2 border-gray-400 
                            ${check.selected && "bg-gray-100"}
                        `}
                    onClick={() => handleCheckClicked(!check.selected)}
                  >
                    <div className="flex items-center mb-2">
                      <h5
                        id={`checkName${index}`}
                        className="leading-none text-gray-800 font-semibold p-0 m-0"
                      >
                        {t(`checks.${check.code}.name`)}
                      </h5>
                    </div>
                    <p className="block text-sm p-0 m-0">
                      {t(`checks.${check.code}.description`)}
                    </p>

                    <div className="pointer-events-none absolute right-2 top-2.5 flex items-center">
                      <Switch
                        id={`optionalCheck${index}`}
                        checked={check.selected}
                        onChange={handleCheckClicked}
                      />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <Button
              outline
              disabled={contractSaveStatus == "LOADING"}
              className="py-3 px-10"
              onClick={goBackHome}
            >
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              loading={contractSaveStatus == "LOADING"}
              type="submit"
              className="py-3 px-10"
            >
              {t("create")}
            </Button>
          </div>
        </form>
      </div>

      <ModalMessageCentered
        success
        title={t("success-message.title", {
          formName: data.formName ?? "Declaration",
        })}
        message={t("success-message.body", {
          formName: data.formName ?? "Declaration",
        })}
        open={contractSaveStatus == "SUCCESS"}
        onClose={() => handleCloseModal(true)}
        actions={renderActions()}
      />

      <ModalMessageCentered
        error
        title={t("error-message.title", {
          formName: data.formName ?? "Declaration",
        })}
        open={contractSaveStatus == "ERROR"}
        onClose={() => handleCloseModal(false)}
      >
        <span dangerouslySetInnerHTML={{ __html: error }}></span>
      </ModalMessageCentered>
    </div>
  );
};
export default CreateCase;
