const FormSection = ({
  label = "Step 1",
  title = "Company details",
  subtitle = "Details about this company",
  children,
}) => {
  return (
    <div className="lg:grid grid-cols-12 gap-8">
      <div className="col-span-4 mb-2 pt-3.5 -mt-0.5 px-4 lg:px-0">
        <div className="relative mb-3 theme-color text-black/70 uppercase font-medium tracking-wide text-xs leading-none rounded-full py-1.5 px-2.5 inline-flex items-center justify-center">
          <span className="rounded-full opacity-30 absolute inset-0 border border-current"></span>
          <span className="rounded-full opacity-5 absolute inset-0 bg-current bg-gray-200"></span>
          {label}
        </div>
        <h2 className="mb-1 text-xl font-semibold">
          {title}
        </h2>
        <p className="leading-relaxed text-black opacity-60 font-light text-base">
          {subtitle}
        </p>
      </div>

      <div className="col-span-8 flex-shrink-0 bg-white shadow lg:rounded-md overflow-hidden">
        <div className="py-6 px-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormSection;
