import React from "react";
import PropTypes from "prop-types";
import Label from "./Label";
import { randomId } from "../../../utils";
import i18n from "../../../i18n";
import ValidationMessage from "./ValidationMessage";

const Select = ({
  id = randomId(),
  choices = [],
  label,
  placeholder = i18n.t("choose-one", { ns: "declaration-form" }),
  noPlaceholder = false,
  defaultValue,
  value,
  name = "select",
  wrapperClassName,
  className,
  orderChoicesAlphabetically = false,
  disabled = false,
  hidden = false,
  error,
  validator = {},
  isClearable = false,
  onChange = () => {},
  ...selectProps
}) => {
  value = typeof value == "object" ? value.value : value;
  defaultValue =
    typeof defaultValue == "object" ? defaultValue.value : defaultValue;

  if (orderChoicesAlphabetically) choices.sort();

  return (
    <div className={`flex flex-col ${wrapperClassName}`}>
      {label && label.length && <Label htmlFor={id}>{label}</Label>}

      <select
        id={id}
        name={name}
        className={`w-full rounded-md px-3 py-2 border border-gray-400/70 focus:outline-none focus:ring-1 focus:ring-blue-500/70
          ${
            disabled
              ? "pointer-events-none bg-gray-200 border-gray-400 text-gray-500"
              : "bg-gray-50 border-gray-400/70"
          }
          ${
            error
              ? "border-red-500 focus:border-red-500/50 focus:ring-red-500"
              : "border-gray-400/70"
          }
          ${className}
        `}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        {...selectProps}
        {...validator}
        onChange={onChange}
      >
        {!noPlaceholder && !!placeholder && (
          <option value="">{placeholder}</option>
        )}
        {choices.map((choice, index) => {
          const isObject = typeof choice == "object";
          const label = isObject ? choice.label : choice;
          const value = isObject ? choice.value : choice;

          return (
            <option key={index} value={value}>
              {label}
            </option>
          );
        })}
      </select>

      <ValidationMessage error={error} />
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  noPlaceholder: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  choices: PropTypes.array,
  orderChoicesAlphabetically: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default Select;
