import { customDescription } from "../../../../../constants";
import { getCurrentLanguage } from "../../../../../i18n";
import Logo from "../../../../components/Logo";

const IntroSection = ({ details, formName }) => {
  // const introDescription =
  //   details?.companyProfile?.description || customDescription(lang);
  const introDescription = customDescription(getCurrentLanguage());
  const logo = details?.companyProfile?.logo;

  return (
    <div className="lg:grid grid-cols-12 gap-8">
      <div className="grid place-items-center relative rounded overlflow-hidden col-span-4 bg-black/5 border px-5 py-5 mt-0.5">
        <span className="rounded opacity-2.5 absolute inset-0 theme-color bg-current"></span>

        {logo ? (
          <img src={logo} alt="" className="max-h-[60px] md:max-h-[120px] max-w-[70%]" />
        ) : (
          <div className="h-6 pointer-events-none">
            <Logo className="object-cover object-left max-w-full" dintero />
          </div>
        )}
      </div>

      <div className="col-span-8 flex-shrink-0 bg-white shadow-sm rounded-md">
        <div className="py-4 px-6">
          <h2 className="mb-2 text-2xl font-semibold text-black/70">
            {formName}
          </h2>

          {introDescription.split("\n\n").map((section, index) => (
            <div
              key={index}
              className="mb-2 text-sm text-black/60 font-light leading-loose"
              dangerouslySetInnerHTML={{__html: section}}
            >
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
