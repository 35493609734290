import { useState } from "react";
import { useTranslation } from "react-i18next";
import { openLinkProgrammatically } from "../../utils";
import Button from "./Button";
import Dots from "./Dots";
import Input from "./formControl/Input";
import LanguageSwitcher from "./LanguageSwitcher";
import Modal from "./Modal";

const SubmitErrorMessage = ({
  open = true,
  size = "sm",
  title = "Submit Bug Report",
  message = "Please tell us a little bit about the error you run into and what you were doing before it happened.",
  action = "Okay, thanks",
  children,
  onClose = () => {},
}) => {
  const { t } = useTranslation("common", { keyPrefix: "error-fallback" });
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const email = "support@quickr.no";
    const subject = "User Submitted Bug";
    const body = formData.get("message");
    openLinkProgrammatically(`mailto:${email}?subject=${subject}&body=${body}`);
  };

  return (
    <Modal size="md" open={open} onClose={onClose}>
      <div className="flex-grow-1 relative">
        <button
          button
          type="button"
          className="absolute right-4 top-4 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={onClose}
        >
          <span className="sr-only">{t("close")}</span>
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <form onSubmit={handleSubmit}>
          <div className="mt-5 px-2">
            <div className="md:mt-6">
              <h3
                id="modal-title"
                className="text-xl leading-6 font-bold text-gray-900"
              >
                {title}
              </h3>
              <div className="md:mt-2">
                <p className="text-base leading-relaxed text-gray-500">
                  {children ? children : message}
                </p>

                <Input
                  required
                  type="textarea"
                  name="message"
                  rows="3"
                  placeholder="Enter your error report here..."
                />
              </div>
            </div>
          </div>
          <div className="md:mt-4 mt-4 flex justify-end">
            <button
              type="submit"
              className="inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary md:text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 text-sm"
            >
              {t("submit-report")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

function ErrorFallback({ error, resetErrorBoundary }) {
  const { t } = useTranslation("common", { keyPrefix: "error-fallback" });
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);

  return (
    <div className="h-screen bg-gray-200 flex items-center justify-center">
      <div className="fixed top-3 right-3 z-50">
        <LanguageSwitcher />
      </div>

      <div className="w-full max-w-4xl flex justify-end mx-auto relative bg-gray-100 shadow border py-10 px-14 rounded">
        <div className="max-w-xl mr-auto">
          <h3 className="text-2xl font-semibold mb-4">{t("title")}</h3>
          <p className="text-bases leading-relaxed">{t("message")}</p>
          <p className="mt-4 mb-4">
            {t("contact-support")}&nbsp;
            <a
              href="mailto:support@quickr.no"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              support@quickr.no
            </a>
            .
          </p>
          <div className="pt-2 flex items-center space-x-3">
            <Button
              color="primary"
              type="button"
              outline
              onClick={() => setShowErrorMessageModal(true)}
            >
              {t("submit-bug-report")}
            </Button>

            <Button
              color="primary"
              type="button"
              onClick={() => (window.location.href = "/")}
            >
              {t("return-to-quickr")}
            </Button>
          </div>
        </div>
        <div className="absolute top-0 right-0 -mt-8 -mr-6">
          <Dots />
        </div>
        <div
          className="absolute -mt-4 -ml-6 top-0 left-0 sprite sprite-circle-bottom"
          style={{ color: "#D9E6F2" }}
        ></div>
        <div
          className="absolute bottom-0 -mb-8 sprite-mobile-small sprite sprite-circle"
          style={{ color: "#FFF9EE" }}
        ></div>
        <div
          className="absolute top-12 sprite-mobile-small sprite sprite-triangle-equi"
          style={{ color: "#EBE8F3" }}
        ></div>
      </div>

      <SubmitErrorMessage
        success
        open={showErrorMessageModal}
        onClose={() => setShowErrorMessageModal(false)}
        actions={() => {}}
      />
    </div>
  );
}

export default ErrorFallback;
