/* eslint-disable nonblock-statement-body-position */
import { Fragment, useState } from "react";
import { useMutation } from "react-query";

import { isObjEmpty } from "../../../utils";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { registerTenant } from "../../../providers/api";
import logo from "../Login/logo.png";
import registerIllustration from "./register-illustration.svg";
import Button from "../../components/Button";
import Input from "../../components/formControl/Input";
import PasswordInput from "../../components/formControl/PasswordInput";
import Checkbox from "../../components/formControl/Checkbox";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation("auth");
  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [email, setEmail] = useState("");
  const [valErrors, setValErrors] = useState({});
  const [tenant, setTenant] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);

  const { isLoading, data, error, mutate } = useMutation(registerTenant, {
    onSuccess: (res) => {
      try {
        if (res.data?.error) {
          const arr = {};
          for (const property in res.data.error) {
            if (res.data.error[property] !== null)
              arr[property] = res.data.error[property];
          }
          setValErrors(arr);
          if (res.data.error.email !== null)
            console.error(res.data.error.email);
          if (res.data.error.tenant !== null)
            console.error(res.data.error.tenant);

          console.log("Register error: ", res.data.error);
        } else {
          setValErrors({});
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("tenant", tenant);

          history.push("/confirm");
        }
      } catch (error) {
        console.log("Unknown register error: ", error);
      }
    },
    onError: (err) => {
      console.log("Register error: ", err);
      toast.error(err?.message ? err.message : err);
    },
  });

  const Terms = () => {
    return (
      <Fragment>
        {t("register.i-agree-to")}&nbsp;
        <a
          className="font-medium text-gray-700 hover:underline ml-25"
          href="/"
          onClick={(e) => e.preventDefault()}
        >
          {t("register.privacy-policy-and-terms")}
        </a>
      </Fragment>
    );
  };

  const onSubmit = async (payload) => {
    try {
      if (isObjEmpty(errors)) {
        setEmail(payload.email);
        setTenant(payload.tenant);

        await mutate(payload);
      }
    } catch (error) {
      console.log("Register error: ", error);
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="flex h-screen items-center">
        <Link
          className="brand-logo inline-flex items-center absolute top-6 left-6"
          to="/"
          onClick={(e) => e.preventDefault()}
        >
          <img className="object-cover h-10" src={logo} alt="logo" />
        </Link>
        <div className="hidden md:flex items-center p-5 flex-1">
          <div className="w-100 lg:flex items-center justify-content-center px-5">
            <img
              className="img-fluid"
              src={registerIllustration}
              alt="Login V2"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex items-center h-full bg-white px-2 lg:p-10">
          <div className="px-xl-2 mx-auto p-8">
            <div className="mb-5">
              <h2 className="text-2xl font-semibold mb-2">
                {t("register.title")}
              </h2>
              <p className="mb-2">{t("register.message")}</p>
            </div>
            <form
              action="/"
              className="mt-2 space-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Input
                  label={t("register.company-name")}
                  type="text"
                  placeholder="Healthy and Tasty Co"
                  id="tenant"
                  name="tenant"
                  error={errors["tenant"]}
                  validator={register("tenant", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                />
                {Object.keys(valErrors).length && valErrors.tenant ? (
                  <small className="text-danger">{valErrors.tenant}</small>
                ) : null}
              </div>
              <div>
                <Input
                  label={t("email")}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="john@example.com"
                  error={errors["email"]}
                  validator={register("email", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                />
                {Object.keys(valErrors).length && valErrors.email ? (
                  <small className="text-danger">{valErrors.email}</small>
                ) : null}
              </div>
              <PasswordInput
                label={t("password")}
                type="password"
                id="password"
                name="password"
                error={errors["password"]}
                validator={register("password", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />
              <Checkbox
                label={<Terms />}
                type="checkbox"
                id="terms"
                name="terms"
                value="terms"
                className="mb-1 mr-2"
                validator={register("terms", { required: true })}
                error={errors.terms && true}
              />

              <Button type="submit" block color="primary" loading={isLoading}>
                {isLoading ? t("please wait") : t("register.sign-up")}
              </Button>
            </form>
            <p className="text-center mt-4">
              <span className="mr-2">
                {t("register.already-have-an-account")}
              </span>
              <Link to="/login" className="text-primary">
                <span>{t("register.sign-in-instead")}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
