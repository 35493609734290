import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

import ErrorFallback from "./ui/components/ErrorFallback";
import AppRouter from "./AppRouter";

import ModalMessageCentered from "./ui/components/ModalMessageCentered";
import { saveValueToLocalStorage } from "./hooks/useLocalStorageState";
import Loader from "./ui/components/Loader";

import "./sentry";
import "./i18n";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { AlertProvider } from "./providers/alerts";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

function SessionExpired() {
	const { t } = useTranslation("common");
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		window.addEventListener("session-expired", sessionExpired, false);

		return () =>
			window.removeEventListener(
				"session-expired",
				sessionExpired,
				false
			);
	}, []);

	function sessionExpired() {
		setShowMessage(true);
	}

	function handleClearSession() {
		setShowMessage(false);

		setTimeout(() => {
			saveValueToLocalStorage("authUser", null);
			saveValueToLocalStorage("authToken", null);
			saveValueToLocalStorage("tenant", null);
			window.location.reload();
		}, 200);
	}

	return (
		<ModalMessageCentered
			title={t("session-expired.title")}
			message={t("session-expired.message")}
			open={showMessage}
			size="md"
			action={t("session-expired.action")}
			actionStyle="primary"
			onOkay={handleClearSession}
		/>
	);
}

function App() {
	return (
		<Suspense
			fallback={<Loader fillParent={true} size={90} color="#ddd" />}
		>
			<Sentry.ErrorBoundary
				showDialog={false}
				FallbackComponent={ErrorFallback}
				onReset={() => {
					
				}}
			>
				<QueryClientProvider client={queryClient}>
					<AlertProvider>
						<AppRouter />
						<ToastContainer newestOnTop />
						<SessionExpired />
					</AlertProvider>
				</QueryClientProvider>
			</Sentry.ErrorBoundary>
		</Suspense>
	);
}

export default App;
