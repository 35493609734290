import { randomId } from "../../../utils";
import ValidationMessage from "./ValidationMessage";

export default function Checkbox({
  id = randomId(),
  label,
  validator = {},
  error,
  valid = false,
  className,
  withWrapper = false,
  ...inputProps
}) {
  return (
    <>
      <div
        className={`
        ${withWrapper && "border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-1.5 px-3.5"}
        ${className}
      `}
      >
        <label
          id={id}
          className="inline-flex items-start lg:items-center"
        >
          <input
            id={id}
            type="checkbox"
            className={`placeholder-gray-400/70 border-gray-400/80 mr-2.5 mt-0.5 lg:mt-0
              ${
                error
                  ? "border-red-500 focus:border-red-500/50 focus:ring-red-500"
                  : ""
              }
            `}
            {...validator}
            {...inputProps}
          />

          {label && <span className="text-sm text-gray-500">{label}</span>}
        </label>
      </div>

      <ValidationMessage error={error} />
    </>
  );
}
