import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { isObjEmpty } from '../../../utils';

import { createApiKey } from '../../../providers/api';
import ModalMessageCentered from '../../components/ModalMessageCentered';
import Button from '../../components/Button';
import Input from '../../components/formControl/Input';

import downloadApiKey from './downloadApiKey';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateApiKey = () => {
  const {t} = useTranslation('api-keys');
  const history = useHistory();
  const [apikeyCreated, setApikeyCreated] = useState(false);
  const [name, setName] = useState('');
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { isLoading, data, error, mutate } = useMutation(createApiKey, {
    onSuccess: res => {
      downloadApiKey(res.data);
      setApikeyCreated(true);
    },
    onError: err => {
      toast.error(err?.message ? err.message : err);
    },
  });

  const onSubmit = async => {
    if (isObjEmpty(errors)) {
      mutate({ name });
    }
  };

  return (
    <div className="pt-8 max-w-xl mx-auto">
      <div className="bg-white shadow-sm rounded-md overflow-hidden border">
        <div className="py-4 px-6 border-b">
          <h4 className="text-xl font-semibold text-black m-0 leading-none">
            {t('create-api-key')}
          </h4>
        </div>
        <form
          className="px-7 pt-5 pb-7 space-y-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            label={t('api-key-name')}
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
            id="name"
            error={errors["name"]}
            validator={register("name", {
              required: true,
              validate: value => value !== '',
            })}
          />

          <Button
            color="primary"
            type="submit"
            block
            loading={isLoading}
            className="py-3"
          >
            {isLoading ? t('please-wait') : t('save-api-key')}
          </Button>
        </form>
      </div>

      <ModalMessageCentered
        open={apikeyCreated}
        success
        title={t('creation-success.title')}
        message={t('creation-success.message')}
        onClose={() => history.push('/api-keys')}
      />
    </div>
  );
};
export default CreateApiKey;
