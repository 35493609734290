import { useTranslation } from "react-i18next";
import Input from "../../../../components/formControl/Input";
import FormSection from "../FormSection";

const CompanyDetails = ({
  details,
  errors,
  register,
  handleChange,
}) => {
  const {t} = useTranslation('declaration-form');

  return (
    <FormSection
      label={t('step-1')}
      title={t('company-details.title')}
      subtitle={t('company-details.description')}
    >
      <div className="grid grid-cols-2 gap-6">
        <Input
          label={t('company-name')}
          type="text"
          name="companyName"
          readOnly
          value={details.companyName}
          onChange={e => handleChange("companyName", null, null, e)}
          placeholder={t('company-name')}
          id="companyName"
          validator={register("companyName", { required: true })}
          error={errors.companyName}
        />
        <Input
          label={t('organisation-number')}
          type="text"
          name="organizationNumber"
          value={details.organizationNumber}
          readOnly
          onChange={e => handleChange("organizationNumber", null, null, e)}
          placeholder={t('organisation-number')}
          id="organizationNumber"
          validator={register("organizationNumber", { required: true })}
          error={errors.organizationNumber}
        />

        <Input
          label={t('business-trading-name')}
          type="text"
          name="commercialInformation_tradingName"
          value={details.commercialInformation.tradingName}
          onChange={e =>
            handleChange("commercialInformation", "tradingName", null, e)
          }
          placeholder={t('business-trading-name')}
          id="commercialInformation_tradingName"
          validator={register("commercialInformation_tradingName", { required: true })}
          error={errors.commercialInformation_tradingName}
        />
        <Input
          label={t('business-trading-address')}
          readOnly
          type="text"
          name="postalAddress_streetAddress"
          value={details.postalAddress.streetAddress}
          onChange={e =>
            handleChange("postalAddress", "streetAddress", null, e)
          }
          placeholder={t('business-trading-address')}
          id="postalAddress_streetAddress"
        />

        <Input
          label={t('post-code')}
          readOnly
          type="text"
          name="postalAddress_postalCode"
          value={details.postalAddress.postalCode}
          onChange={e => handleChange("postalAddress", "postalCode", null, e)}
          placeholder={t('post-code')}
          id="postalAddress_postalCode"
        />

        <Input
          label={t('city')}
          readOnly
          type="text"
          name="postalAddress_city"
          value={details.postalAddress.city}
          onChange={e => handleChange("postalAddress", "city", null, e)}
          placeholder={t('city')}
          id="postalAddress_city"
        />
      </div>
    </FormSection>
  );
};

export default CompanyDetails;
