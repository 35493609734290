import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { Fragment, useState } from "react";
import JSONPretty from "react-json-pretty";
import ActionButton from "../../../../components/Button/ActionButton";

const InlineCaseDetails = ({ data }) => {
	const [expanded, setExpanded] = useState(false);
	const [placement, setPlacement] = useState();
	const ref = useRef(null);
	useLayoutEffect(() => {
		const row = ref.current.closest('tr');
		setPlacement(row.getBoundingClientRect().top - row.offsetTop);
	}, [])

	return (
		<>
			<tr
				className="relative"
				ref={ref}
				style={{
					backgroundColor: "#272822",
				}}
			>
				<Transition
					className="transition ease-in-out duration-75 origin-top border-t"
					style={{ borderTop: "1px solid #ebe9f1 !important" }}
					as={Fragment}
					show={expanded}
					enter="duration-100"
					enterFrom="scale-y-0 opacity-0"
					enterTo="opacity-100 scale-y-100"
					leave="duration-100"
					leaveFrom="opacity-100 scale-y-100"
					leaveTo="scale-y-0 opacity-0"
				>
					<td
						colSpan={3}
						style={{
							backgroundColor: "#272822",
						}}
					>
						<JSONPretty data={data} />
					</td>
				</Transition>

				<span
					className={`
                      absolute right-5 -top-10 mt-[1px] duration-150 
                      ${expanded ? " rotate-180" : ""}
                	`}
					// style={{ top: `${placement}px` }}
				>
					<ActionButton onClick={() => setExpanded(!expanded)}>
						<ChevronDownIcon width={12} strokeWidth={4} />
					</ActionButton>
				</span>
			</tr>
		</>
	);
};

export default InlineCaseDetails;
