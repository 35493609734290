import "flatpickr/dist/themes/material_green.css";
import FormSection from "../FormSection";
import RadioButtonGroup from "../../../../components/formControl/RadioButtonGroup";
import { yesNoChoices } from "../../../../../constants";
import { useTranslation } from "react-i18next";

const PepDetails = ({
  details,
  errors,
  register,
  handleChange,
}) => {
  const {t} = useTranslation('declaration-form');

  return (
    <FormSection
      label={t('step-6')}
      title={t('pep-details.title')}
      subtitle={t('pep-details.description')}
    >
      <div className="mb-3 text-black opacity-60 font-light text-sm leading-relaxed">
        {t('pep-details.message')}
      </div>

      <RadioButtonGroup
        className="mb-3"
        withWrapper={true}
        name="declaration_isPEP"
        label={t('are-you-a-pep')}
        choices={yesNoChoices()}
        validator={register("declaration_isPEP", { required: true })}
        error={errors.declaration_isPEP || details.declaration.isPEP !== null}
        value={details.declaration.isPEP}
        onChange={e =>
          handleChange("declaration", "isPEP", null, e)
        }
      />

      <RadioButtonGroup
        withWrapper={true}
        name="declaration_isAffiliatedPEP"
        label={t('are-you-affiliated-with-a-pep')}
        choices={yesNoChoices()}
        validator={register("declaration_isAffiliatedPEP", { required: true })}
        error={errors.declaration_isAffiliatedPEP}
        value={details.declaration.isAffiliatedPEP}
        onChange={(e) =>
          handleChange("declaration", "isAffiliatedPEP", null, e)
        }
      />
    </FormSection>
  );
};

export default PepDetails;
