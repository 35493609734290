import { useState } from "react";
import { isObjEmpty } from "../../../utils";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import { updateDefaultPassword } from "../../../providers/api";
import PasswordInput from "../../components/formControl/PasswordInput";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { saveValueToLocalStorage } from "../../../hooks/useLocalStorageState";
import SwitchTenant from "../../layouts/SwitchTenant";
import { useAuth } from "../../../providers/auth";

const ChangePassword = () => {
  const { setAuthUser } = useAuth();
  const { t } = useTranslation("auth");
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const [tenantChoices, setTenantChoices] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { isLoading, data, error, mutate } = useMutation(
    updateDefaultPassword,
    {
      onSuccess: (res) => {
        setAuthUser(res.data);
        if (res.data.tenantGroups.length == 1) {
          saveValueToLocalStorage("tenant", res.data.tenantGroups[0]);
          history.replace(from);
          toast.success(t("success-password-updated"));
        } else {
          setTenantChoices(res.data.tenantGroups);
        }
      },
      onError: (err) => {
        console.log("Change default password error: ", err);
        toast.error(err?.message ? err.message : err);
      },
    }
  );

  const onSubmit = async (data) => {
    try {
      if (isObjEmpty(errors)) {
        const email = sessionStorage.getItem("email");
        mutate({ ...data, email });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex flex-col">
      <div className="w-full max-w-xl m-auto px-6 md:px-0 pb-16">
        <div className="bg-white border shadow-sm p-6 rounded">
          <h2 className="text-2xl font-semibold mb-2">
            {t("change-password.title")}
          </h2>
          <p className="mb-2">{t("change-password.message")}</p>
          <form
            action="/"
            className="mt-4 space-y-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <PasswordInput
              label={t("current-password")}
              id="login-password"
              name="password"
              error={errors["password"]}
              validator={register("password", {
                required: true,
                validate: (value) => value !== "",
              })}
            />

            <PasswordInput
              label={t("new-password")}
              id="login-password"
              name="newPassword"
              error={errors["newPassword"]}
              validator={register("newPassword", {
                required: true,
                validate: (value) => value !== "",
              })}
            />

            <Button
              type="submit"
              color="primary"
              block
              loading={isLoading}
              className="relative"
            >
              {isLoading ? t("please wait") : t("submit")}
            </Button>
          </form>
        </div>

        <p className="text-center mt-4">
          <Link to="/login" className="flex items-center">
            <ChevronLeftIcon className="mr-2" width={20} />
            <span className="align-middle">{t("back-to-login")}</span>
          </Link>
        </p>
      </div>

      <SwitchTenant
        open={!!tenantChoices}
        tenants={tenantChoices}
        from={from}
        onClose={() => {}}
      />
    </div>
  );
};

export default ChangePassword;
