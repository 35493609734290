import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ContractMessage from "./ContractMessage";

const ContractSubmitted = () => {
  const {t} = useTranslation('public-pages');
  const { state } = useLocation();

  return (
    <ContractMessage
      title={t('contract-submitted.title', {formName: state?.formName || "Declaration"})}
      subTitle={t('contract-submitted.subtitle', {formName: state?.formName || "Declaration"})}
      nextSteps={[
        t('contract-submitted.next-steps.1'),
        t('contract-submitted.next-steps.2', {formName: state?.formName || "Declaration", companyName: 'Dintero AS', signingMethod: "BankID"})
      ]}
      message={t('contract-submitted.message', { tenantEmail: 'onboarding@dintero.com' })}
    />
  );
};
export default ContractSubmitted;
