import { useState, useEffect } from "react";
import {
	XIcon,
	CheckCircleIcon,
	InformationCircleIcon,
} from "@heroicons/react/solid";
import Button from "./Button";
import ActionButton from "./Button/ActionButton";
import Loader from "./Loader";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const Attachment = ({
	status = "idle",
	showPreview,
	attachment,
	uploadDetails,
	tempId,
	pending,
	removing,
	onRemove,
	onUploaded,
}) => {
	const { t } = useTranslation("common");
	const initialized = useRef();
	const [_status, setStatus] = useState(status);

	useEffect(() => {
		if (initialized.current) return;
		initialized.current = true;

		uploadAttachment();
	}, []);

	useEffect(() => {
		setStatus(status);
	}, [status]);

	const uploadAttachment = async () => {
		if (!attachment || !uploadDetails) {
			return console.log("No attachment or upload details");
		}

		const formData = new FormData();
		Object.entries(uploadDetails.fields).forEach(([key, value]) => {
			formData.append(key, value);
		});

		try {
			setStatus("uploading");
			const uploadRes = await fetch(uploadDetails.url, {
				method: "POST",
				body: formData,
			});

			const responseText = await uploadRes.text();

			if (!uploadRes.ok) throw new Error(responseText);

			handleSave();
		} catch (error) {
			setStatus("error");
			console.error("Error uploading file: ", error);
		}
	};

	const handleSave = async () => {
		if (typeof onUploaded == "function") onUploaded();
		else setStatus("success");
	};

	if (!attachment) return;

	return (
		<div
			className={`
            flex items-center border border-gray-400/40 bg-gray-50 py-2 pl-3
            ${showPreview ? "rounded-b -mt-0.5" : "rounded"}
        `}
		>
			<div className="flex-shrink-0 mr-2.5">
				{_status == "error" ? (
					<InformationCircleIcon
						className="text-red-500"
						width={18}
					/>
				) : _status == "uploading" || pending || removing ? (
					<Loader fillParent={false} size={22} color="#aaa" />
				) : (
					_status == "success" &&
					(showPreview ? (
						<img
							src={attachment.url}
							alt=""
							className="w-6 h-6 object-cover rounded-sm"
						/>
					) : (
						<CheckCircleIcon
							className="text-green-500 theme-color"
							width={19}
						/>
					))
				)}
			</div>

			<div className="flex-1 text-base leading-none">
				{_status == "error" ? (
					<div className="flex items-center">
						<span className="mr-2">
							Failed to upload attachment
						</span>
						<Button
							size="xs"
							outline
							rounded="full"
							className="font-medium theme-color"
							onClick={uploadAttachment}
						>
							Retry
						</Button>
					</div>
				) : pending || removing || _status == "uploading" ? (
					<span className="ml-1">
						{_status == "uploading"
							? t("uploading-attachment")
							: removing
							? t("removing-attachment")
							: t("saving-attachment")}
					</span>
				) : (
					_status == "success" && (
						<a
							className="ml-1 text-sm text-black font-medium hover:underline"
							href={attachment.url}
							target="_blank"
						>
							{attachment.name}
						</a>
					)
				)}
			</div>

			<div
				className={`mr-2 ${
					pending ? "opacity-0 pointer-events-none" : ""
				}`}
			>
				<ActionButton color="flat-danger" onClick={onRemove}>
					<XIcon width={16} />
				</ActionButton>
			</div>
		</div>
	);
};

export default Attachment;
