export default function Label({ children, className, ...labelProps }) {
  return (
    <label
      className={`
            block text-sm text-gray-500
            ${className}
        `}
      {...labelProps}
    >
      {children}
    </label>
  );
}
