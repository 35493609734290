import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getCurrentLanguage } from "../../../../../i18n";
import { submitDeclarationForm } from "../../../../../providers/api";
import Button from "../../../../components/Button";
import Sidebar from "../../../../components/Sidebar";
import generatePDF, { css as pdfCSS } from "quickr-pdf";
import { signatoryRoleFromKey } from "../dataLoader";

function ContractPDFPreview({
  contractId,
  urlSignature,
  tenant,
  contract,
  formName,
  setDataErros = () => {},
  onClose = () => {},
}) {
  const { t } = useTranslation("declaration-form");
  const history = useHistory();
  const { isLoading, error, mutate } = useMutation(submitDeclarationForm, {
    onSuccess: (res) => {
      try {
        window.localStorage.removeItem("DECLARATION_FORM_" + contractId);
      } catch (error) {}

      history.push(`/contract/submitted`, {
        formName,
      });
    },
    onError: (err) => {
      console.log("Declaration form error: ", err);
      setDataErros(err);
      toast.error(err?.message ? err.message : err);
    },
  });

  const themeColorSet =
    contract?.companyProfile?.color?.length &&
    contract.companyProfile.color != "373737";

  const saveContract = () => {
    const { mcc, ...payload } = contract;

    mutate({
      contractId,
      urlSignature,
      tenant,
      details: {...payload, signatoryRoles: signatoryRoleFromKey(payload?.signingRule)?.value},
      meta: {
        lang: getCurrentLanguage()
      }
    });
  };

  return (
    <Sidebar
      size="5xl"
      open={!!contract}
      title={t("contract-preview", { formName: formName || "Declaration" })}
      headerClassName="mb-1"
      contentClassName="p-0"
      bodyClassName="pb-sm-0 pb-3"
      onClose={onClose}
    >
      {contract && (
        <>
          <div
            className="-mb-16"
            dangerouslySetInnerHTML={{
              __html: `
							<style>
								${pdfCSS}
							</style>
							${generatePDF(contract, getCurrentLanguage())}
						`,
            }}
          ></div>

          <div className="sticky bottom-0 mt-12 py-5 -mb-8 bg-gray-100 border-t px-6">
            <div
              className="absolute inset-0 opacity-2.5"
              style={
                themeColorSet
                  ? {
                      background: `#${contract.companyProfile.color}`,
                    }
                  : { opacity: 0 }
              }
            ></div>

            <div className="sm:flex items-center justify-end">
              <p className="text-lg text-gray-900 mb-2 sm:mb-0 sm:mr-4">
                {t("are-all-details-correct.prompt", {
                  formName: formName || "Declaration",
                })}
              </p>

              <div className="sm:flex items-center">
                <Button
                  id="submitButton"
                  type="button"
                  outline
                  disabled={isLoading}
                  className="transition duration-300"
                  onClick={onClose}
                >
                  {t("are-all-details-correct.no")}
                </Button>

                <Button
                  id="submitButton"
                  color="primary"
                  type="submit"
                  className="transition duration-300 mt-2 sm:mt-0 sm:ml-2"
                  loading={isLoading}
                  style={
                    !themeColorSet
                      ? {
                          backgroundColor: "#121aff", // Dintero blue
                        }
                      : {
                          backgroundColor: "var(--theme-color)",
                          borderColor: "var(--theme-color)",
                          boxShadow: "0 8px 25px -8px var(--theme-color)",
                        }
                  }
                  onClick={saveContract}
                >
                  {isLoading
                    ? t("please-wait")
                    : t("are-all-details-correct.yes", {
                        formName: formName || "Declaration",
                      })}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Sidebar>
  );
}

export default ContractPDFPreview;
