import FormSection from '../FormSection';
import { numberWithCommas } from '../../../../../utils';
import Input from '../../../../components/formControl/Input';
import Checkbox from '../../../../components/formControl/Checkbox';
import { useTranslation } from 'react-i18next';

const PricingAndTerms = ({
  details,
  errors,
  register,
  addAttachment,
  removeItem,
  handleValueChange,
}) => {
  const {t} = useTranslation('declaration-form');

  return (
    <FormSection
      label={details?.pricing?.length > 0 ? t('step-8') : t('step-7')}
      title={t('terms-section.title')}
      subtitle={t('terms-section.description')}
    >
      {details?.terms?.length > 0 && (
        <div className="mb-5">
          <h5 className="text-gray-500 font-semibold leading-none m-0 p-0">
            {t('terms-section.title')}
          </h5>
          <div className="mt-1 mb-1 text-black opacity-60 text-sm font-light leading-relaxed">
            {t('terms-section.description')}
          </div>
          
          {details.terms.map((term, i) => (
            <div key={i} className="mt-3 pb-2 -mb-6">
              <div className="border rounded bg-gray-50 pt-2.5 pb-1.5 px-3.5">
                <Checkbox
                  label={
                    <div>
                      <span className='text-gray-500'>{t('i-have-read-and-accept')} </span>
                      <a className="underline hover:underline font-medium" target="_blank" rel="noreferrer" href={term.url}>
                        {term.name}.
                      </a>
                    </div>
                  }
                  checked={details.terms[i].isChecked || false}
                  onChange={e =>
                    handleValueChange(
                      'terms',
                      'isChecked',
                      i,
                      !details.terms[i].isChecked
                    )
                  }
                  id={`terms-${i}`}
                  validator={register(`terms-${i}`, { required: true })}
                  error={errors[`terms-${i}`]}
                  type="checkbox"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </FormSection>
  );
};

export default PricingAndTerms;
