import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../ui/components/Button";
import ModalMessageCentered from "../ui/components/ModalMessageCentered";
import { randomId } from "../utils";

const AlertContext = createContext(null);

export function AlertProvider({ children }) {
	const { t } = useTranslation("common");
	const [alerts, setAlerts] = useState([]);

	function handleClose(al, action) {
		const alertId = al.id;

		al.callback(action);

		setAlerts(
			alerts.map((alert) => {
				if (alert.id === alertId) return { ...alert, open: false };
				return alert;
			})
		);

		setTimeout(() => {
			setAlerts(alerts.filter(({ id }) => id !== alertId));
		}, 300);
	}

	function confirm(userProps = {}) {
		const alert = {
			id: randomId(),
			type: "confirm",
			size: "md",
			open: true,
			title: t("are-you-sure"),
			message: t("action-cant-be-undone"),
			cancelText: t("no-cancel"),
			okayText: t("yes-continue"),
			...(userProps || {}),
		};

		alert.actions = [alert.cancelText, alert.okayText];

		return new Promise((resolve) => {
			setAlerts([
				...alerts,
				{
					...alert,
					callback: (action) => resolve(action == alert.actions[1]),
				},
			]);
		});
	}

	function renderActions(alert) {
		return (
			<>
				<Button
					outline
					color="primary"
					onClick={() => handleClose(alert, alert.actions[0])}
				>
					{alert.actions[0]}
				</Button>

				<Button
					color="primary"
					onClick={() => handleClose(alert, alert.actions[1])}
				>
					{alert.actions[1]}
				</Button>
			</>
		);
	}

	const value = {
		alerts,
		confirm,
	};

	return (
		<AlertContext.Provider value={value}>
			{children}

			{alerts.map((alert) => (
				<ModalMessageCentered
					key={alert.id}
					open={alert.open}
					size={alert.size}
					title={alert.title}
					message={alert.message}
					hideCloseButton={true}
					actions={renderActions(alert)}
					onClose={() => handleClose(alert, alert.actions[0])}
				/>
			))}
		</AlertContext.Provider>
	);
}

export function useAlerts() {
	return useContext(AlertContext);
}
