import { useTranslation } from "react-i18next";
import { camelToTitleCase } from "../../../utils";

function getInputLabel(input) {
  const label = camelToTitleCase(input.id || input.name).replaceAll("_", " ");
  const labelNode = document.querySelector(`[for="${input.id || input.name}"]`);

  return labelNode ? labelNode.textContent : label;
}

const ValidationMessage = ({ error }) => {
  const { t } = useTranslation("declaration-form");

  if (!error?.ref) return null;

  const label = getInputLabel(error.ref);

  const checkboxOrRadio = ["radio", "checkbox"].includes(error.ref.type);
  const externalRadio =
    error.ref.type === "radio" &&
    error.ref.parentElement.parentElement.parentElement.classList.contains(
      "mb-3"
    );

  return (
    <div
      className={`flex items-center text-red-500 text-xs 
				${!checkboxOrRadio ? "mt-1" : ""}
				${error.ref.type === "radio" ? (externalRadio ? "-mt-2 mb-3" : "ml-3") : ""}
				${error.ref.type === "checkbox" ? "-mt-2 mb-3" : ""}
			`}
    >
      {error.type === "required" &&
        (checkboxOrRadio ? t('field') : label) + ` ${t("is-required")}`}
      {error.type === "minLength" && label + ` ${t("is-too short")}`}
      {error.type === "maxLength" && label + ` ${t("is-too long")}`}
      {error.type === "min" &&
        (error.message || label + ` ${t("is-too small")}`)}
      {error.type === "max" &&
        (error.message || label + ` ${t("is-too large")}`)}
      {error.type === "pattern" &&
        error.ref.type === "email" &&
        label + ` ${t("is")} ${t("invalid")}`}
      {error.type === "validate" && label + ` ${t("is")} ${error.message}`}
    </div>
  );
};

export default ValidationMessage;
