import i18n from "./i18n";

export const customDescriptionEn = `Please complete the declaration form accurately in order to meet regulatory requirements and to provide you with the best service possible. 

Dintero pre-fills some information from a variety of public sources, but it is your responsibility to verify the information is true and up to date, or update any pre-filled details where necessary.

For any questions please contact <span class="text-primary">onboarding@dintero.com</span>.
`;

export const customDescriptionNo = `Fyll ut egenerklæringsskjemaet nøyaktig for å oppfylle regulatoriske krav og for å gi deg best mulig service.

Dintero forhåndsutfyller noe informasjon fra en rekke offentlige kilder, men det er ditt ansvar å bekrefte at informasjonen er sann og oppdatert, eller oppdatere eventuelle forhåndsutfylte detaljer der det er nødvendig.

For spørsmål vennligst kontakt <span class="text-primary">onboarding@dintero.com</span>.
`;

export const customDescription = (locale = "en") => {
  const description = {
    en: customDescriptionEn,
    se: "Swed: " + customDescriptionEn,
    no: customDescriptionNo,
  };

  return description[locale];
};

export const emailMatcherPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const defaultThemeColors = [
  "#db4437", //red
  "#673ab7", //purple
  "#3f51b5", //dark blue
  "#4285f4", // blue
  // "#03a9f4", // light blue
  // "#00bcd4", // cyan
  // "#ff5722", // orange
  "#ff9800", // amber
  "#009688", // teal
  "#4caf50", // lightgreen
  "#607d8b", // bluegrey
  "#6D4C41", // brown
  "#4b4b4b", // dark
];

export const yesNoChoices = () => [
  { label: i18n.t("yes", { ns: "declaration-form" }), value: true },
  { label: i18n.t("no", { ns: "declaration-form" }), value: false },
];
