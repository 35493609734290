import { useMemo, useRef } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import {
  BriefcaseIcon,
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import DataTable from "react-data-table-component";
import Button from "../../../components/Button";
import Label from "../../../components/formControl/Label";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "../../../components/Dropdown";
import { fetchCases } from "../../../../providers/api";
import { caseStatusObj, columns } from "./columns";
import Loader from "../../../components/Loader";
import EmptyState from "../../../components/EmptyState";
import useLocalStorageState from "../../../../hooks/useLocalStorageState";
import sortAndFilterCases from "./sortAndFilterCases";
import { useTranslation } from "react-i18next";
import { useThrottle } from "@react-hook/throttle";
import { useEffect } from "react";

// import '@styles/react/libs/tables/react-dataTable-component.scss'
// import Loader from '../../../components/Loader'

const StatusPicker = ({ onChange }) => {
  const { t } = useTranslation("cases");
  const dropdownToggleRef = useRef(null);
  const [selected, setSelected] = useLocalStorageState("caseFilters", [
    ...Object.keys(caseStatusObj),
  ]);
  const [open, setOpen] = useState(false);
  const statusChoices = Object.keys(caseStatusObj).sort();

  const handleSelectAllStatuses = () => {
    let newSelected = [];
    if (selected?.length != statusChoices.length)
      newSelected = [...statusChoices];

    setSelected(newSelected);
    onChange(newSelected);

    setTimeout(() => {
      dropdownToggleRef.current?.click();
    }, 0);
  };

  const handleStatusClicked = (status) => {
    let newSelected = [...selected, status];

    if (selected.includes(status))
      newSelected = selected.filter((s) => status != s);

    setSelected(newSelected);
    onChange(newSelected);

    setTimeout(() => {
      dropdownToggleRef.current?.click();
    }, 0);
  };

  return (
    <Dropdown className="ml-2.5">
      <DropdownToggle
        className="py-2 pl-0 pr-2 ml-1 bg-white flex items-center border border-gray-400/90 rounded-md"
        ref={dropdownToggleRef}
      >
        <FilterIcon
          width={15}
          className="ml-3 text-gray-400 "
          color="currentColor"
        />
        <div className="hidden md:flex">
          <span
            className="ml-2 text-sm font-medium text-left truncate inline-block"
            style={{ width: "220px" }}
          >
            {!selected?.length
              ? t("all-statuses")
              : selected.map((filter) => t("caseStatus." + filter)).join(", ")}
          </span>

          <ChevronDownIcon
            width={20}
            className="ml-3 text-gray-400 "
            color="currentColor"
          />
        </div>
      </DropdownToggle>
      <DropdownMenu right width="220px">
        <DropdownItem
          toggle={false}
          className="group w-full flex items-center text-left bg-gray-100 border rounded-none"
          onClick={handleSelectAllStatuses}
        >
          <span className="w-5 mr-3 relative flex items-center justify-center">
            <svg
              width="20"
              viewBox="0 0 24 24"
              fill={
                selected.length == statusChoices.length
                  ? "currentColor"
                  : "none"
              }
              className={`
                ${
                  selected.length == statusChoices.length
                    ? "text-blue-700"
                    : "text-gray-400 group-hover:text-gray-500"
                }
              `}
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            </svg>
            {selected.length == statusChoices.length && (
              <CheckIcon
                className="absolute"
                stroke="white"
                width={12}
                strokeWidth={1.5}
              />
            )}
          </span>
          <span className="mr-0.5">{t("select-all")}</span>
        </DropdownItem>
        {statusChoices.map((status, index) => {
          const active = selected.includes(status);

          return (
            <DropdownItem
              toggle={false}
              className="group w-full flex items-center text-left"
              key={index}
              onClick={() => handleStatusClicked(status)}
            >
              <span className="w-5 mr-3 relative flex items-center justify-center">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill={active ? "currentColor" : "none"}
                  className={`
                    ${
                      active
                        ? "text-blue-700"
                        : "text-gray-400 group-hover:text-gray-500"
                    }
                  `}
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                </svg>
                {active && (
                  <CheckIcon
                    className="absolute"
                    stroke="white"
                    width={12}
                    strokeWidth={1.5}
                  />
                )}
              </span>
              <span className="mr-0.5">{t("caseStatus." + status)}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );

  return null;
};

const CustomHeader = ({ onSearch, onStatusChange }) => {
  const { t } = useTranslation("cases");
  const [searchQuery, setSearchQuery] = useThrottle("", 1);
  const [status, setStatus] = useThrottle("", 0.8);

  useEffect(() => {
    onSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    onStatusChange(status);
  }, [status])

  return (
    <div className="flex items-center justify-between py-2">
      <div
        className="relative rounded-md w-72"
        style={{ border: "1px solid #bbb" }}
      >
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="w-5 text-gray-400" />
        </div>
        <input
          type="search"
          className="py-2 pl-10 text-sm border-gray-100 rounded-md placeholder-gray-400"
          placeholder={t("search-placeholder")}
          onChange={(e) => setSearchQuery(e.target.value)}
          icon={BriefcaseIcon}
        />
      </div>

      <StatusPicker onChange={setStatus} />
    </div>
  );
};

const CasesList = () => {
  const { t, i18n } = useTranslation("cases");
  const tableColumns = columns();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusValue, setStatusValue] = useLocalStorageState("caseFilters", [
    "UNDER_MANUAL_REVIEW",
    "WAITING_FOR_SIGNATURE",
    "WAITING_FOR_DECLARATION",
  ]);
  const [rowsPerPage, setRowsPerPage] = useLocalStorageState(
    "cases-per-page",
    100
  );
  const [cursor, setCursor] = useState(null);
  const history = useHistory();
  const [caseListSorter, setCaseListSorter] = useLocalStorageState(
    "caseListSorter",
    {
      id: "statusUpdated",
      asc: false,
    }
  );

  const handleFetchCases = (payload) => {
    return fetchCases(payload);
  };
  const [cases, setCases] = useState([]);
  const { isLoading, isRefetching, refetch, error } = useQuery(
    ["cases", currentPage, rowsPerPage, statusValue, searchQuery],
    () =>
      handleFetchCases({
        page: currentPage,
        limit: rowsPerPage,
        status: statusValue,
        query: searchQuery,
        cursor,
      }),
    {
      onSuccess: (res) => {
        if (res?.data) {
          const data = res.data;
          setCases(data.cases);
          setCursor(data.response_metadata?.cursor);
        }
      },
    }
  );

  const CustomPagination = () => {
    const count = 0; //Number((store.total / rowsPerPage).toFixed(0))

    return (
      <ReactPaginate
        pageCount={count ?? 1}
        nextLabel=""
        breakLabel="..."
        previousLabel=""
        activeClassName="active"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        // forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => setCurrentPage(page.selected + 1)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={"pagination react-paginate justify-content-end p-1"}
      />
    );
  };

  const dataToRender = () => {
    let data = sortAndFilterCases([...cases], statusValue);
    const filters = {
      q: searchQuery,
    };

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0;
    });

    if (data.length > 0) return data;
    else if (data.length === 0 && isFiltered) return [];
    else return data.slice(0, rowsPerPage);
  };

  const highlightFocusedRow = (e) => {
    const currentlyHighlighted = document.querySelector(
      ".rdt_TableRow.highlighted"
    );
    if (currentlyHighlighted)
      currentlyHighlighted.classList.remove("highlighted");
    e.target.closest(".rdt_TableRow").classList.add("highlighted");
  };

  const removeFocusHighlightFromRow = (e) => {
    e.target.closest(".rdt_TableRow").classList.remove("highlighted");
  };

  return (
    <div className="py-4 container">
      <div className="px-4 md:px-0 mb-1 md:flex md:items-center md:justify-between">
        <div className="flex items-center flex-1 min-w-0">
          <h2 className="m-0 p-0 text-xl font-semibold leading-none text-gray-900 truncate">
            {t("cases")}
          </h2>

          <Link to="/cases/new" type="button" className="ml-4">
            <Button outline color="primary" size="sm">
              <span className="pb-0.5">
                <PlusIcon width={14} className="mr-2" />
              </span>
              {t("new-case")}
            </Button>
          </Link>
        </div>
        <div className="sflex items-center md:mt-0 md:ml-4">
          <CustomHeader
            onSearch={setSearchQuery}
            onStatusChange={setStatusValue}
          />
        </div>
      </div>

      <div className="bg-white shadow border rounded-md overflow-hidden">
        {isLoading || isRefetching ? (
          <div className="flex flex-col items-center justify-center py-3">
            <Loader fillParent={false} size={50} thickness={4} color="#ddd" />
            <span className="mt-1 opacity-80"> {t("fetching-cases")} </span>
          </div>
        ) : (
          !dataToRender()?.length && (
            <EmptyState
              icon={BriefcaseIcon}
              title={t("no-cases.title")}
              message={t("no-cases.message")}
              actionText={t("new case")}
              actionLink="/cases/new"
            />
          )
        )}

        {!isLoading && !isRefetching && dataToRender()?.length > 0 && (
          <DataTable
            noHeader
            columns={tableColumns}
            responsive={true}
            sortIcon={<ChevronDownIcon />}
            className="react-dataTable"
            defaultSortField="invoiceId"
            data={dataToRender()}
            onRowMouseEnter={(row, e) => highlightFocusedRow(e)}
            onRowMouseLeave={(row, e) => removeFocusHighlightFromRow(e)}
            onRowClicked={(row) => history.push(`/cases/${row.id}`)}
            defaultSortFieldId={caseListSorter.id}
            defaultSortAsc={caseListSorter.asc}
            onSort={({ id }, direction) =>
              setCaseListSorter({ id, asc: direction === "asc" })
            }
          />
        )}
      </div>

      {!isLoading && !isRefetching && dataToRender()?.length > 0 && (
        <div className="hidden py-6 flex items-center justify-between">
          <div className="flex items-center mr-2">
            <Label htmlFor="rows-per-page">{t("show")}</Label>
            <select
              className="w-auto ml-2 py-1 pl-2 pr-8 text-sm"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
            >
              {[10, 20, 50, 100, 200, 500].map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </select>

            <span className="ml-2.5">{t("results")}</span>
          </div>

          <div className="pt-2.5 space-x-3 flex items-center">
            <Button
              outline
              size="sm"
              disabled={currentPage == 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Prev
            </Button>

            <span className="rounded-full w-8 h-8 bg-white border border-gray-300 flex items-center justify-center text-xs">
              {currentPage}
            </span>

            <Button
              outline
              size="sm"
              disabled={!cursor}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CasesList;
