import { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import {
  getValueFromLocalStorage,
  saveValueToLocalStorage,
} from "../../hooks/useLocalStorageState";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";

const SwitchTenant = ({
  open = true,
  size = "sm",
  message = "",
  children,
  from = "/",
  onClose = () => {},
  ...props
}) => {
  const { t } = useTranslation("common");
  const [currentTenant, setCurrentTenant] = useState();
  const [tenants, setTenants] = useState();
  const history = useHistory();

  let title = t("switch-tenant"),
    action = t("save-changes");
  if (props.tenants) {
    title = t("select-tenant");
    action = t("continue");
  }

  async function getTenants() {
    const authUser = getValueFromLocalStorage("authUser");
    return authUser?.tenantGroups;
  }

  const setTenantChoices = async () => {
    if (props.tenants) {
      setTenants(props.tenants);
      return;
    }
    const tenants = await getTenants();
    setTenants(tenants);
    setCurrentTenant(getValueFromLocalStorage("tenant"));
  };

  useEffect(() => {
    if (open) setTenantChoices();
  }, [props.tenants, open]);

  function saveCurrentTenant(tenant) {
    setCurrentTenant(tenant);
    saveValueToLocalStorage("tenant", tenant);

    if (props.tenants) history.replace(from);
    else history.push(from);

    window.location.reload();
  }

  return (
    <Modal alignTop open={open} onClose={onClose} title={title}>
      <div className="flex-grow-1 relative">
        <div className="mt-5 space-y-2">
          {!tenants && (
            <div className="flex flex-col items-center justify-center py-3">
              <Loader
                fillParent={false}
                size={50}
                thickness={4}
                color="#ddd"
              />
              <span className="mt-1 opacity-80">Fetching tenants...</span>
            </div>
          )}

          {tenants &&
            tenants.map((tenant, index) => {
              const selected = currentTenant?.id == tenant?.id;

              return (
                <button
                  type="button"
                  key={index}
                  className={`
                      py-3 px-4 border-2 rounded w-full flex items-center justify-between hover:bg-gray-100
                      ${
                        selected &&
                        "pointer-events-none bg-gray-100 focus:outline-none"
                      }
                    `}
                  onClick={() => saveCurrentTenant(tenant)}
                >
                  <span className="text-lgs font-medium">{tenant.name}</span>

                  <span
                    className={`
                      rounded-full w-6 h-6 border-2 flex items-center justify-center
                      ${
                        selected
                          ? "bg-blue-700 border-blue-700 text-white"
                          : "border-gray-400"
                      }
                    `}
                  >
                    {selected && (
                      <CheckIcon className="w-4" strokeWidth={4} />
                    )}
                  </span>
                </button>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default SwitchTenant;
