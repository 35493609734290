import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import ActionButton from './Button/ActionButton';

const Modal = ({ size, alignTop = false, noPadding, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const onClose = props.onClose;

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center md:block p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 translate-y-0 scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white rounded-lg overflow-hidden text-left shadow-xl transform transition-all w-full
                ${!noPadding && 'px-4 pt-5 pb-4 p-6'}
                ${alignTop ? "my-12 align-top" : "my-8 align-middle"}
                ${size === 'md' && 'lg:max-w-md'}
                ${size === 'lg' && 'lg:max-w-lg'}
                ${size === 'xl' && 'lg:max-w-xl'}
                ${size === '2xl' && 'lg:max-w-2xl'}
              `}
            >
              <div className="">
                <div className="mt-3 text-center mt-0 text-left">
                  {props.title?.length && (
                    <div className="flex items-center justify-between">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-none font-semibold text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                      
                      <ActionButton onClick={onClose}>
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </ActionButton>
                    </div>
                  )}

                  <div className={`${!noPadding && "mt-2"}`}>{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['md', 'lg', 'xl', '2xl']),
};

Modal.defaultProps = {
  size: 'lg',
  onClose: () => {}
};

export default Modal;