const MailTo = ({
  email,
  subject,
  body,
  className = "text-primary",
  ...props
}) => {
  return (
    <a
      type="button"
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
      className={className}
    >
      {props.children}
    </a>
  );
};

export default MailTo;
