import { useRef, useState } from "react";
import { useMutation } from "react-query";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useHistory, useLocation } from "react-router-dom";

import { isObjEmpty } from "../../../utils";
import { useAuth } from "../../../providers/auth";
import { signIn } from "../../../providers/api";
import { saveValueToLocalStorage } from "../../../hooks/useLocalStorageState";
import logo from "./logo.png";
import loginIllustration from "./login-illustration.svg";

import Input from "../../components/formControl/Input";
import PasswordInput from "../../components/formControl/PasswordInput";
import Button from "../../components/Button";
import Label from "../../components/formControl/Label";
import SwitchTenant from "../../layouts/SwitchTenant";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation("auth");
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const email = useRef("");
  const { setAuthUser } = useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [tenantChoices, setTenantChoices] = useState(false);

  const { isLoading, data, error, mutate } = useMutation(signIn, {
    onSuccess: (res) => {
      setAuthUser(res.data);
      if (res.data.tenantGroups.length == 1) {
        saveValueToLocalStorage("tenant", res.data.tenantGroups[0]);
        history.replace(from);
        window.location.reload();
      } else {
        setTenantChoices(res.data.tenantGroups);
      }
    },
    onError: (err) => {
      console.log("Login error: ", err);
      if (err?.message == "New password required") {
        sessionStorage.setItem("email", email.current);
        history.push("/change-password");
        return;
      }
      toast.error(err?.message ? err.message : err);
    },
  });

  const onSubmit = async (payload) => {
    try {
      if (isObjEmpty(errors)) {
        email.current = payload.username;
        await mutate(payload);

        if (data?.tenantGroups) {
          setTenantChoices(data?.tenantGroups);
          history.replace(from);
        } else if (error) {
          if (error.toString().indexOf("User does not exist.") != -1) {
            throw new Error("Wrong username or password");
          }

          throw error;
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="flex h-screen items-center">
        <Link
          className="brand-logo inline-flex items-center absolute left-6 top-6"
          to="/"
          onClick={(e) => e.preventDefault()}
        >
          <img className="object-cover h-10" src={logo} alt="logo" />
        </Link>
        <div className="hidden md:flex items-center p-5 flex-1">
          <div className="w-100 lg:flex items-center justify-content-center px-5">
            <img className="img-fluid" src={loginIllustration} alt="Login V2" />
          </div>
        </div>
        <div className="bg-white h-full w-full md:w-auto flex items-center px-2 lg:p-10">
          <div className="px-xl-2 mx-auto p-8">
            <div className="mb-5">
              <h2 className="text-2xl leading-none font-semibold mb-1.5">
                {t("login.title")}
              </h2>
              <p>{t("login.message")}</p>
            </div>

            <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label={t("email")}
                type="email"
                id="email"
                name="username"
                placeholder="john@example.com"
                error={errors["username"]}
                validator={register("username", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />
              <div>
                <div className="flex items-center justify-between">
                  <Label className="form-label" htmlFor="password">
                    {t("password")}
                  </Label>
                  <Link to="/forgot-password">
                    <small className="text-gray-500">
                      {t("forgot-password-label")}
                    </small>
                  </Link>
                </div>
                <PasswordInput
                  type="password"
                  id="password"
                  name="password"
                  error={errors["password"]}
                  validator={register("password", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                />
              </div>
              <Button
                type="submit"
                color="primary"
                block
                loading={isLoading}
                className="relative"
              >
                {isLoading ? t("please-wait") : t("login.sign-in")}
              </Button>
            </form>
            <p className="text-center mt-4">
              <span className="mr-2">{t("login.new-on-our-platform")}</span>
              <Link to="/register" className="text-primary">
                <span>{t("login.create-an-account")}</span>
              </Link>
            </p>
          </div>
        </div>

        <SwitchTenant
          open={!!tenantChoices}
          tenants={tenantChoices}
          from={from}
          onClose={() => setTenantChoices(false)}
        />
      </div>
    </div>
  );
};

export default Login;
