import { fetchDeclarationFormDetails } from "../../../../providers/api";

export const signatoryRoleOptions = [
  { value: "only_ceo", label: "ceo-only", englishLabel: "CEO only" },
  {
    value: "only_chairman",
    label: "chairman-only",
    englishLabel: "Chairman only",
  },
  {
    value: "any_board_member",
    label: "any-board-member-to-sign-individually",
    englishLabel: "Any board member to sign individually",
  },
  {
    value: "all_board_members",
    label: "board-members",
    englishLabel: "Board members",
  },
  {
    value: "min_two_board_members",
    label: "any-two-board-members-to-sign-jointly",
    englishLabel: "Any two board members to sign jointly",
  },
  {
    value: "min_two_board_members_or_ceo",
    label: "any-two-board-members-to-sign-jointly-or-the-ceo-only",
    englishLabel: "Any two board members to sign jointly or the CEO only",
  },
  {
    value: "min_two_board_members_or_chairman",
    label: "any-two-board-members-to-sign-jointly-or-the-chairman-only",
    englishLabel: "Any two board members to sign jointly or the Chairman only",
  },
  {
    value: "chairman_or_ceo",
    label: "chairman-or-ceo-to-sign-separately",
    englishLabel: "The CEO or the chairman of the board alone",
  },
  {
    value: "ceo_and_chairman",
    label: "ceo-and-chairman-to-sign-jointly",
    englishLabel: "The CEO and Chairman jointly",
  },
  {
    value: "chairman_or_deputy_chairman",
    label: "chairman-or-deputy-chairman",
    englishLabel: "The chairman and deputy chairman of the board separately",
  },
  {
    value: "chairman_and_deputy_chairman",
    label: "chairman-and-deputy-chairman",
    englishLabel: "The chairman and deputy chairman of the board jointly",
  },
  {
    value: "ceo_and_board_member",
    label: "ceo-and-board-member",
    englishLabel: "The CEO and one board member jointly",
  },
  {
    value: "any_three_board_members",
    label: "any-three-board-members",
    englishLabel: "Three board members jointly",
  },
  {
    value: "chairman_and_any_two_board_members",
    label: "chairman-and-any-two-board-members",
    englishLabel: "The chairman of the board and two board members jointly",
  },
];

export const signatoryRoleFromKey = (key) => {
  return signatoryRoleOptions.find(
    ({ label, value }) => value == key || label == key
  );
};

export default async function dataLoader(contractId, urlSignature, history) {
  try {
    const contract = await fetchDeclarationFormDetails(
      contractId,
      urlSignature
    );

    if (contract.data.isSubmitted && !contract.data.isSigned)
      history.push("/contract/submitted", {
        formName: contract.data.type,
      });

    if (contract.data.isSubmitted && contract.data.isSigned)
      history.push(`/contract/signed/${contractId}`, {
        formName: contract.data.type,
      });

    const signatories = ["only_ceo", "only_chairman", "all_board_members"].map(
      (s) => {
        return signatoryRoleOptions[
          signatoryRoleOptions.findIndex((x) => x.value === s)
        ];
      }
    );

    const details = contract?.data?.details;
    const signatoryRoles = ["only_ceo", "only_chairman"].includes(
      details?.signatoryRoles
    )
      ? details?.signatoryRoles
      : "all_board_members";

    return {
      data: contract?.data,
      details: {
        ...details,
        signingRule: signatoryRoleFromKey(details?.signatoryRoles)?.label,
        signatoryRoles,
        signatories: [signatoryRoles],
      },
      signatories,
    };
  } catch (error) {
    history.push("/contract/not-found");
  }
}
