import FormSection from "../FormSection";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { XIcon } from "@heroicons/react/outline";
import Checkbox from "../../../../components/formControl/Checkbox";
import Input from "../../../../components/formControl/Input";
import Label from "../../../../components/formControl/Label";
import RadioButtonGroup from "../../../../components/formControl/RadioButtonGroup";
import { yesNoChoices } from "../../../../../constants";
import { useTranslation } from "react-i18next";

const CompanyOwnershipDetails = ({
  details,
  errors,
  register,
  addNewItem,
  removeItem,
  handleChange,
  handleSelectChange,
  handleValueChange,
}) => {
  const {t} = useTranslation('declaration-form')
  return (
    <FormSection
      label={t('step-4')}
      title={t('beneficial-ownership-details.title')}
      subtitle={t('beneficial-ownership-details.description')}
    >
      <div className="mb-4 text-black text-sm opacity-60 font-light leading-loose">
        {t('beneficial-ownership-details.message')}
      </div>

      {details.shareholders?.length > 0 && (
        <div className="mb-1 grid grid-cols-11 gap-x-3.5">
          <div className="col-span-3">
            <Label htmlFor="shareholders_name_0">{t('name-of-ultimate-owner')}</Label>
          </div>
          <div className="col-span-4">
            <Label htmlFor="shareholders_stakes_0">{t('ownership-stake')}</Label>
          </div>
          <div className="col-span-3">
            <Label htmlFor="shareholders_birthDate_0">{t('date-of-birth')}</Label>
          </div>
        </div>
      )}

      {details.shareholders.map((owner, i) => {
        let birthDate = details.shareholders[i].birthDate;
        try {
          birthDate = new Date(birthDate).toISOString().split("T").shift();
        } catch (error) {
          
        }

        return (
          <div key={i} className="grid grid-cols-11 gap-x-3.5 mb-2.5">
            <div className="col-span-3">
              <label htmlFor={`shareholders_name_${owner.id}`} className="hidden">{t('name-of-ultimate-owner')}</label>
              <Input
                type="text"
                name={`shareholders_name_${owner.id}`}
                key={`shareholders_name_${owner.id}`}
                value={
                  details.shareholders[i] ? details.shareholders[i].name : ""
                }
                onChange={(e) => handleChange("shareholders", "name", i, e)}
                placeholder="John Smith"
                id={`shareholders_name_${owner.id}`}
                validator={register(`shareholders_name_${owner.id}`, { required: true })}
                error={errors[`shareholders_name_${owner.id}`]}
              />
            </div>
            <div className="col-span-4">
              <label htmlFor={`shareholders_stakes_${owner.id}`} className="hidden">{t('ownership-stake')}</label>
              <Input
                type="number"
                name={`shareholders_stakes_${owner.id}`}
                key={`shareholders_stakes_${owner.id}`}
                value={
                  details.shareholders[i] ? details.shareholders[i].stakes : ""
                }
                onChange={(e) => handleChange("shareholders", "stakes", i, e)}
                placeholder="5"
                id={`shareholders_stakes_${owner.id}`}
                validator={register(`shareholders_stakes_${owner.id}`, {
                  required: true,
                })}
                error={errors[`shareholders_stakes_${owner.id}`]}
              />
            </div>

            <div className="col-span-3">
              <label htmlFor={`shareholders_birthDate_${owner.id}`} className="hidden">{t('date-of-birth')}</label>
              <Input
                type="date"
                name={`shareholders_birthDate_${owner.id}`}
                readOnly={!details.shareholders[i].manuallyAdded && details.shareholders[i].birthDate?.length}
                key={`shareholders_birthDate_${owner.id}`}
                value={
                  details.shareholders[i] ? birthDate : ""
                }
                onChange={(e) => handleChange("shareholders", "birthDate", i, e)}
                placeholder="5"
                id={`shareholders_birthDate_${owner.id}`}
                validator={register(`shareholders_birthDate_${owner.id}`, {
                  required: true,
                })}
                error={errors[`shareholders_birthDate_${owner.id}`]}
              />
            </div>
            
            <div className="col-span-1">
              <button
                type="button"
                className="mt-2 grid place-items-center h-7 w-7 border-none bg-transparent hover:bg-gray-200 text-sm leading-none focus:outline-none"
                onClick={() => removeItem(i, "shareholders")}
              >
                <XIcon width={16} className="mb-0.5" />
              </button>
            </div>
          </div>
        );
      })}
      
      {!details.shareholders?.length && (
        <div className="text-sm font-semibold mb-3">
          {t('no-owners')}
        </div>
      )}

      <div
        className={details?.shareholders?.length > 1 ? "mb-5 mt-3" : "mb-5"}
      >
        <button
          type="button"
          className="flex rounded border-none bg-transparent text-sm leading-none focus:outline-none"
          onClick={() => addNewItem("shareholders")}
        >
          <span className="flex items-center">
            <svg
              className="h-5 mr-1.5 theme-color text-primary opacity-80"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
            </svg>
            {t('add-new-owner')}
          </span>
        </button>
      </div>

      <RadioButtonGroup
        className="mb-3"
        withWrapper={true}
        name="declaration_influencingFactors"
        choices={yesNoChoices()}
        label={t('other-company-control-factors')}
        validator={register("declaration_influencingFactors", {
          required: true,
        })}
        error={errors.declaration_influencingFactors}
        value={details.declaration.influencingFactors}
        onChange={(e) =>
          handleChange("declaration", "influencingFactors", null, e)
        }
      />

      <Checkbox
        withWrapper={true}
        className="mb-3"
        label={t('ownership-confirmation')}
        id="declaration.ownershipChanged.yes"
        name="declaration_ownershipChanged"
        validator={register("declaration_ownershipChanged", {
          required: true,
        })}
        error={errors.declaration_ownershipChanged}
        checked={details.declaration.ownershipChanged === true}
        onChange={(e) =>
          handleSelectChange("declaration", "ownershipChanged", {
            target: {
              value: !details.declaration.ownershipChanged,
            },
          })
        }
      />
    </FormSection>
  );
};

export default CompanyOwnershipDetails;
