import React, { Fragment, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Dialog, Transition } from "@headlessui/react";
import ActionButton from "./Button/ActionButton";

const Sidebar = ({ size, alignTop = false, noPadding, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const onClose = props.onClose;

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className={`ml-auto bg-white flex flex-col text-left shadow-xl transform transition-all 
                h-screen w-full
                ${size == "md" && "max-w-md"}
                ${size == "lg" && "max-w-lg"}
                ${size == "xl" && "max-w-xl"}
                ${size == "2xl" && "max-w-2xl"}
                ${size == "3xl" && "max-w-3xl"}
                ${size == "4xl" && "max-w-4xl"}
                ${size == "5xl" && "max-w-5xl"}
              `}
            >
              <div className="h-screen overflow-y-auto">
                <div className="mt-3 text-center mt-0 text-left">
                  {props.title?.length && (
                    <div className="py-2 px-3 sticky z-10 top-0 border-b bg-white flex items-center justify-between">
                      <Dialog.Title
                        as="h3"
                        className="leading-none font-semibold text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>

                      <ActionButton onClick={onClose}>
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </ActionButton>
                    </div>
                  )}

                  <div>{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(["md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"]),
};

Sidebar.defaultProps = {
  size: "lg",
  onClose: () => {},
};

export default Sidebar;
