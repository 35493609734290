import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import MailTo from "../../components/Mailto";
import errorIllustration from "./error-illustration.svg";

const PageNotFound = () => {
  const {t} = useTranslation('public-pages');

  return (
    <div className="fixed inset-0 z-10 bg-gray-100">
      <Logo />
      <div className="container flex flex-col py-24 items-center">
        <div className="w-100 text-center">
          <h2 className="text-3xl font-semibold mb-3">404!</h2>
          <p className="mb-5 max-w-lg mx-auto text-base leading-relaxed">
            {t('404-message')}
          </p>

          <div className="flex items-center justify-center space-x-3">
            <Button
              outline
              tag={MailTo}
              email="support@quickr.no"
              subject="Support Ticket - Declaration Form Submission"
              color="primary"
              className="btn-sm-block mb-2"
            >
              {t('contact-support')}
            </Button>
            <Link to="/">
              <Button
                email="support@quickr.no"
                subject="Support Ticket - Declaration Form Submission"
                color="primary"
                className="btn-sm-block mb-2 px-8"
              >
                {t('go-to-home-page')}
              </Button>
            </Link>
          </div>
          <img className="img-fluid" src={errorIllustration} alt="Not authorized page" />
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
