import { useEffect, useRef, useState } from "react";
import { isObjEmpty } from "../../utils";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { useMutation } from "react-query";
import { resetPassword } from "../../providers/api";

import Input from "../components/formControl/Input";
import PasswordInput from "../components/formControl/PasswordInput";
import Button from "../components/Button";

import logo from "./Login/logo.png";
import forgotPasswordIllustration from "./ForgotPassword/forgot-password-illustration.svg";
import { ChevronLeftIcon } from "@heroicons/react/solid";

const ConfirmNewPassword = () => {
  const { t } = useTranslation("auth");
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const codeInputRef = useRef(null);

  const { isLoading, mutate } = useMutation(resetPassword, {
    onSuccess: (res) => {
      toast.success("Password reset successful");
      history.push("/login");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const onSubmit = async (data) => {
    try {
      if (isObjEmpty(errors)) {
        const { newPassword, code } = data;
        const username = sessionStorage.getItem("email");
        mutate({ username, code, newPassword });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitted(true);
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="flex h-screen items-center">
        <Link
          className="brand-logo inline-flex items-center absolute top-6 left-6"
          to="/"
          onClick={(e) => e.preventDefault()}
        >
          <img className="object-cover h-10" src={logo} alt="logo" />
        </Link>
        <div className="hidden md:flex items-center p-5 flex-1">
          <div className="w-100 lg:flex items-center justify-content-center px-5">
            <img
              className="img-fluid"
              src={forgotPasswordIllustration}
              alt="Login V2"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex items-center h-full bg-white px-2 lg:p-10">
          <div className="px-xl-2 max-w-lg mx-auto p-8">
            <div className="mb-5">
              <h2 className="text-2xl font-semibold mb-2">
                {t("confirm-password.title")}
              </h2>
              <p className="mb-2 max-w-xl">{t("confirm-password.message")}</p>
            </div>
            <form className="mt-4 space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label={t("confirm-password.code")}
                autoFocus
                type="text"
                id="forgot-password-code"
                name="code"
                placeholder={t("confirm-password.code-placeholder")}
                error={errors["code"]}
                validator={register("code", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />

              <PasswordInput
                label={t("new-password")}
                id="password"
                name="newPassword"
                error={errors["newPassword"]}
                validator={register("newPassword", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />

              <PasswordInput
                label={t("confirm-new-password")}
                id="newPassword"
                name="confirmNewPassword"
                error={errors["confirmNewPassword"]}
                validator={register("confirmNewPassword", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />

              <Button
                type="submit"
                block
                color="primary"
                loading={isLoading}
                className="relative"
              >
                {isLoading
                  ? t("please wait")
                  : t("confirm-password.reset-password")}
              </Button>
            </form>
            <p className="text-center mt-2">
              <Link to="/login" className="flex items-center">
                <ChevronLeftIcon className="mr-2" width={18} />
                <span className="align-middle">{t("back-to-login")}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNewPassword;
