import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, KeyIcon, PlusIcon, RefreshIcon, TrashIcon } from '@heroicons/react/solid';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader';
import Button from '../../components/Button';

import { deleteApiKey, fetchApiKeys, refreshApiKey, } from '../../../providers/api';

import downloadApiKey from './downloadApiKey';
import EmptyState from '../../components/EmptyState';
import { useTranslation } from 'react-i18next';

function RefreshTokenButton({ apiKey, onRefresh = () => {} }) {
  const {t} = useTranslation('api-keys');
  const [refreshStatus, setRefreshStatus] = useState(false);
  const { isLoading, data, error, mutate } = useMutation(() => refreshApiKey(apiKey), {
    onSuccess: res => {
      setRefreshStatus('SUCCEEDED');
      setTimeout(() => {
        setRefreshStatus(null);
      }, 1000);

      downloadApiKey(res.data);
    },
    onError: err => {
      toast.error(err?.message ? err.message : err);
    },
  });

  return (
    <Button
      type="button"
      size="sm"
      outline
      className="flex items-center"
      onClick={() => mutate(apiKey)}
    >
      {refreshStatus !== 'SUCCEEDED' ? (
        <span className="inline-flex items-center">
          <RefreshIcon
            className={`mr-2.5 ${isLoading && 'animate-spin'}`}
            width={14}
          />
          {t('refresh-token')}{' '}
        </span>
      ) : (
        <span className="inline-flex items-center text-green-500">
          <CheckCircleIcon
            className="mr-2.5"
            fill="currentColor"
            stroke="white"
            strokeWidth={2.5}
            width={14}
          />
          {t('token-refreshed')}{' '}
        </span>
      )}
    </Button>
  );
}

function DeleteAPIKeyButton({ apiKey, onDelete = () => {} }) {
  const {t} = useTranslation('api-keys')
  const { isLoading, data, error, mutate } = useMutation(deleteApiKey, {
    onSuccess: res => {
      onDelete();
    },
    onError: err => {
      console.log('Create API error: ', err);
      toast.error(err?.message ? err.message : err);
    },
  });

  return (
    <Button
      type="button"
      color="danger"
      size="sm"
      outline
      className="flex items-center"
      onClick={() => mutate(apiKey.id)}
    >
      <span className="inline-flex items-center">
        {isLoading ? (
          <Loader
            className="mr-2.5"
            fillParent={false}
            size={16}
            thickness={6}
            color="currentColor"
          />
        ) : (
          <TrashIcon className="mr-2.5" width={14} />
        )}
        {t('delete-key')}{' '}
      </span>
    </Button>
  );
}

const ApiKeys = () => {
  const {t} = useTranslation('api-keys');
  const { data, error, isLoading, isRefetching, refetch } = useQuery(
    'api-keys',
    fetchApiKeys,
    {
      refetchOnWindowFocus: false
    }
  );
  const [apikeys, setApikeys] = useState([]);

  useEffect(() => {
    if (data?.data) setApikeys(data.data);
  }, [data]);

  return (
    <div className="md:py-5 max-w-4xl mx-auto">
      <div className="flex items-center justify-between border-b py-3 px-4 md:px-0">
        <div className="flex items-center min-w-0">
          <h2 className="pt-1.5 text-xl font-semibold leading-none text-gray-900 truncate">
            {t('api-keys')}
          </h2>
        </div>

        <Link to="/api-keys/add" type="button">
          <Button
            outline
            color="primary"
            size="sm"
          >
            <span className='pb-0.5'>
              <PlusIcon width={14} strokeWidth={3} className="mr-2" />
            </span>

            {t('new-api-key')}
          </Button>
        </Link>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex flex-col items-center justify-center py-7">
          <Loader fillParent={false} size={50} thickness={4} color="#ddd" />
          <span className="mt-1 opacity-80"> {t('fetching-api-keys')}</span>
        </div>
      ) : !apikeys.length ? (
        <EmptyState
          icon={KeyIcon}
          title={t('no-api-keys.title')}
          message={t('no-api-keys.message')}
          actionText={t('new-api-key')}
          actionLink="/api-keys/add"
        />
      ) : (
        <div className="py-6 px-2">
          <div className="auth-inner">
            <div className="bg-white rounded shadow border overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th className='lg:min-w-[250px]'>{t('key')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody className="p-5">
                  {apikeys.map(key => (
                    <tr key={key.id}>
                      <td>{key.displayName}</td>
                      <td>
                        <span className="md:flex items-center space-y-1.5 md:space-y-0 md:space-x-3">
                          <RefreshTokenButton
                            apiKey={key}
                            onRefresh={refetch}
                          />
                          <DeleteAPIKeyButton
                            apiKey={key}
                            onDelete={refetch}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeys;
