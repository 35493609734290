import { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router-dom';
import useLocalStorageState, {
  saveValueToLocalStorage,
} from '../hooks/useLocalStorageState';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const history = useHistory();
  const [authUser, setAuthUser] = useLocalStorageState('authUser', null);
  const [authToken, setAuthToken] = useLocalStorageState('authToken', null);

  function signOut(callback = () => {}) {
    setAuthToken(null);
    setAuthUser(null);
    saveValueToLocalStorage("tenant", null);

    history.push('/login');
    window.location.reload();
  }

  const value = {
    authUser,
    authToken,
    setAuthUser: user => {
      setAuthToken(user.idToken);
      setAuthUser(user);
    },
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }) {
  const { authUser } = useAuth();
  const location = useLocation();

  if (!authUser) {
    saveValueToLocalStorage('quickr-router-from', location);

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Redirect to="/login" state={{ from: location }} replace />;
  }

  return children;
}
