import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KeyIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader';
import Button from '../../components/Button';

import { removeTenantUser, fetchTenantUsers } from '../../../providers/api';
import EmptyState from '../../components/EmptyState';
import { useTranslation } from 'react-i18next';

function RemoveTeamMember({ user, onDelete = () => {} }) {
  const { isLoading, data, error, mutate } = useMutation(removeTenantUser, {
    onSuccess: res => {
      onDelete();
    },
    onError: err => {
      console.log('Remove user error: ', err);
      toast.error(err?.message ? err.message : err);
    },
  });

  return (
    <Button
      type="button"
      color="danger"
      size="sm"
      outline
      className="flex items-center"
      onClick={() => mutate(user.id)}
    >
      <span className="inline-flex items-center text-red-500">
        {isLoading ? (
          <Loader
            className="mr-2.5"
            fillParent={false}
            size={16}
            thickness={6}
            color="currentColor"
          />
        ) : (
          <TrashIcon className="mr-2.5" width={14} />
        )}
        Remove Member{' '}
      </span>
    </Button>
  );
}

const TeamMembers = () => {
  const {t} = useTranslation('team-members');
  const { data, error, isLoading, isRefetching, refetch } = useQuery(
    'team-members',
    fetchTenantUsers,
    {
      refetchOnWindowFocus: false,
    }
  );
  const [tenantUsers, setTenantUsers] = useState([]);

  useEffect(() => {
    if (data?.data) setTenantUsers(data.data);
  }, [data]);

  return (
    <div className="md:py-5 max-w-4xl mx-auto">
      <div className="flex items-center justify-between border-b py-3 px-4 md:px-0">
        <div className="flex items-center min-w-0">
          <h2 className="pt-1.5 text-xl font-semibold leading-none text-gray-900 truncate">
            {t('team-members')}
          </h2>
        </div>

        <Link to="/team-members/add" type="button">
          <Button
            outline
            color="primary"
            size="sm"
          >
            <span className='pb-0.5'>
              <PlusIcon width={14} strokeWidth={3} className="mr-2" />
            </span>
            {t('invite-team-member')}
          </Button>
        </Link>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex flex-col items-center justify-center py-7">
          <Loader fillParent={false} size={50} thickness={4} color="#ddd" />
          <span className="mt-1 opacity-80">{t('fetching-team-members')}</span>
        </div>
      ) : !tenantUsers.length ? (
        <EmptyState
          icon={KeyIcon}
          title={t('no-team-members.title')}
          message={t('no-team-members.message')}
          actionText={t('invite-team-member')}
          actionLink="/team-members/add"
        />
      ) : (
        <div className="py-6 px-2">
          <div className="auth-inner">
            <div className="bg-white rounded shadow border overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: '250px' }}>{t('team-member')}</th>
                    <th>{t('status')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody className="p-5">
                  {tenantUsers.map((member, index) => (
                    <tr key={member.id}>
                      <td>{member.name || member.email}</td>
                      <td>{member.status}</td>
                      <td>
                        --
                        {/* <span className="flex items-center space-x-3">
                          {index > 0 ? (
                            <RemoveTeamMember user={member} onDelete={refetch} />
                          ) : (
                            '--'
                          )}
                        </span> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamMembers;
