import { PlusIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import Button from "./Button";

const EmptyState = ({
  title = "You don&apos;t have any cases yet?!",
  message = "We make that easy fo you, please go ahead and create your first Customer Due Diligence case:",
  icon: Icon,
  actionText = "New case",
  actionLink = "/"
}) => {
  return (
    <div className="py-10 flex flex-col items-center justify-center">
      <div className="mb-4 rounded-full w-10 h-10 md:w-[4.5rem] md:h-[4.5rem] relative flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-primary opacity-5"></div>
        <Icon className="text-blue-900 w-5 md:w-7" color="currentColor" />
      </div>

      <span
        className="text-xl font-semibold mb-2"
        dangerouslySetInnerHTML={{ __html: title }}
      ></span>

      <p
        className="mb-4 opacity-80 max-w-xl px-3 text-center leading-loose"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>

      <Link to={actionLink}>
        <Button outline color="primary" size="md">
          <PlusIcon width={14} strokeWidth={3} className="mr-2" />
          { actionText }
        </Button>
      </Link>
    </div>
  );
};

export default EmptyState;
