import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { fetchCaseDetails } from "../../../providers/api";
import Loader from "../../components/Loader";
import generatePDF, { css as pdfCSS } from "quickr-pdf";
import { getCurrentLanguage } from "../../../i18n";
import { HTTP404Error } from "../../../providers/api/request";
import useSearchQuery from "../../../hooks/useSearchQuery";

const ContractPreview = () => {
  const params = useParams();
  const [contract, setContract] = useState();
  const history = useHistory();
  const query = useSearchQuery();
  const formName = query.get("form_name");
  const { isLoading, isRefetching, refetch, error } = useQuery(
    ["case-preview", params.caseId],
    () => fetchCaseDetails(params.caseId),
    {
      onSuccess: (res) => {
        setContract(res.data.contracts[0].details);
      },
      onError: (err) => {
        if (err instanceof HTTP404Error) {
          const redirectTo = formName
            ? `/contract/not-found?form_name=${formName}`
            : "/contract/not-found";
          history.replace(redirectTo);
        }
      },
    }
  );

  if (!contract) return <Loader size={70} color="#ddd" />;

  return (
    <div id="contractPreview" className="min-h-screen bg-gray-100 py-8">
      <div
        className="max-w-5xl mx-auto bg-white shadow rounded-sm"
        dangerouslySetInnerHTML={{
          __html: `
          <style>
            ${pdfCSS}
          </style>
          ${generatePDF(contract, getCurrentLanguage())}
        `,
        }}
      ></div>
    </div>
  );
};

export default ContractPreview;
