import ValidationMessage from "./ValidationMessage";

export default function Radio({
  id,
  label,
  validator = {},
  error,
  valid = false,
  className,
  wrapperClassName,
  ...inputProps
}) {
  return (
    <>
      <label {...(id ? {htmlFor: id} : {})} className={`inline-flex items-center ${wrapperClassName}`}>
        <input
          id={id}
          type="radio"
          className={`placeholder-gray-400/70 border-gray-400/80 mr-1.5
            ${className}
            ${
              error
                ? "border-red-500 focus:border-red-500/50 focus:ring-red-500"
                : ""
            }
          `}
          {...validator}
          {...inputProps}
        />
        
        {label && <span className="text-sm text-gray-500">{label}</span>}
      </label>
      
      <ValidationMessage error={error} />
    </>
  );
}
