import { useState, useEffect } from "react";
import { MinusIcon, PencilIcon, PlusIcon, XIcon } from "@heroicons/react/solid";

import { numberWithCommas } from "../../../../utils";
import Textarea from "../../../components/formControl/Textarea";
import Input from "../../../components/formControl/Input";
import { useTranslation } from "react-i18next";

function ProductItem({ tempId, onChange = () => {}, onRemove = () => {} }) {
  const { t } = useTranslation("cases");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const canSave =
    name?.trim().length && description?.trim().length && price?.trim().length;

  useEffect(() => {
    onChange({ product: name, price, description, tempId });
  }, [name, price, description]);

  if (collapsed) {
    return (
      <div className="relative mt-2 border rounded-md pt-2.5 pb-3 pl-3 pr-2 bg-gray-50 border-gray-300">
        <div className="flex items-center mb-1">
          <h5 className="text-base text-gray-800 font-medium p-0 m-0">
            {name}
          </h5>

          <span className="leading-none text-xl font-bold mx-3">&middot;</span>

          <span className="text-sm">
            Pricing: &nbsp;
            <span className="font-medium text-gray-700">
              {numberWithCommas(price)}
            </span>
          </span>
        </div>

        <p className="block text-sm p-0 m-0">{description}</p>

        <div className="absolute right-1.5 top-1.5 flex items-center">
          <button
            type="button"
            className="inline-flex items-center mb-1 bg-white border py-1 px-2 rounded text-sm focus:outline-none"
            onClick={() => setCollapsed(false)}
          >
            <PencilIcon width={14} />
          </button>
          <button
            type="button"
            className="ml-1 inline-flex items-center mb-1 bg-white text-red-500 border py-1 px-2 rounded text-sm focus:outline-none"
            onClick={onRemove}
          >
            <XIcon width={14} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-y-4 gap-x-4 bg-white shadow-sm border rounded-md mt-4 px-4 pt-3">
      <Input
        label={t("product-name")}
        id={`pricing${tempId}product`}
        placeholder={t("product-name-placeholder")}
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Input
        id={`pricing${tempId}price`}
        label={t("pricing")}
        placeholder={t("pricing-placeholder")}
        required
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />

      <Textarea
        label={t("description")}
        id={`pricing${tempId}description`}
        required
        rows="3"
        wrapperClassName="col-span-2"
        className="resize-none"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <div className="mb-2 col-span-2 flex items-center justify-between">
        <button
          type="button"
          className="inline-flex items-center mb-1 border py-1 px-2 rounded text-red-500 focus:outline-none"
          onClick={onRemove}
        >
          <XIcon width={14} strokeWidth={3} />
        </button>

        <button
          type="button"
          className={`
              inline-flex items-center mb-1 py-1 px-4 rounded border border-primary text-primary focus:outline-none
              ${!canSave && "pointer-events-none opacity-40"}
            `}
          disabled={!canSave}
          onClick={() => setCollapsed(true)}
        >
          {t("done")}
        </button>
      </div>
    </div>
  );
}

export default function CaseProducts({
  data,
  setData,
  onAddProduct,
  onRemoveProduct,
}) {
  const { t } = useTranslation("cases");

  return (
    <div className="mt-6">
      <h2 className="text-black mb-2 font-semibold leading-none">
        {t("products-and-pricing.title")}
      </h2>
      {/* <p className="mb-">
              Add any products or services you'll be providing and specify prices for each.
            </p> */}

      <div className="mt-2">
        {!data.pricing?.length && (
          <div className="mb-6 bg-gray-100 border-2 border-dashed rounded p-6 flex flex-col items-center justify-center text-center">
            <p className="mb-3">{t("products-and-pricing.placeholder")}</p>
            <button
              type="button"
              className="text-sm inline-flex items-center p-0 text-primary focus:outline-none"
              onClick={onAddProduct}
            >
              <PlusIcon width={14} strokeWidth={3} className="mr-2" />
              {t("products-and-pricing.add-button")}
            </button>
          </div>
        )}

        {data.pricing?.length > 0 && (
          <div>
            {data.pricing.map((product, index) => (
              <ProductItem
                tempId={product.tempId}
                key={product.tempId}
                product={product}
                onRemove={() => {
                  setData({
                    ...data,
                    pricing: [...data.pricing].filter(({tempId}) => {
                      return product.tempId != tempId;
                    }),
                  });
                }}
                onChange={(updatedProduct) => setData({
                    ...data,
                    pricing: [...data.pricing].map((product) => {
                      if (product.tempId == updatedProduct.tempId) return updatedProduct;

                      return product;
                    }),
                  })
                }
              />
            ))}

            <button
              type="button"
              className="mt-4 mb-5 inline-flex items-center text-sm p-0 text-primary focus:outline-none"
              onClick={onAddProduct}
            >
              <PlusIcon width={14} strokeWidth={3} className="mr-2" />
              {t("products-and-pricing.add-button")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
