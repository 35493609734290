import { format as timeAgo, register as registerLocale } from 'timeago.js';
import nb_NO from 'timeago.js/lib/lang/nb_NO';
import sv from 'timeago.js/lib/lang/sv';
import i18n, { getCurrentLanguage } from '../i18n';

registerLocale('nb-NO', nb_NO);
registerLocale('sv-SE', sv);

const getCurrentLanguageLocale = ({forceEnglish} = {}) => {
  if(forceEnglish) return "en";
  return { 'en': 'en', 'se': 'sv-SE', 'no': 'nb-NO' }[getCurrentLanguage()];
} 

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
// eslint-disable-next-line no-confusing-arrow
export const kFormatter = (num) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat(getCurrentLanguageLocale({forceEnglish: true}), formatting).format(
    new Date(parseInt(value))
  )
}

export const formatTime = (
  value,
  formatting = { hour: 'numeric', minute: 'numeric', second: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat(getCurrentLanguageLocale({forceEnglish: true}), formatting).format(
    new Date(parseInt(value))
  )
}

export const formatDateTime = (
  value,
  formatting = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat(getCurrentLanguageLocale({forceEnglish: true}), formatting).format(
    new Date(parseInt(value))
  )
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat(getCurrentLanguageLocale({forceEnglish: true}), formatting).format(new Date(value))
}

export const reformatDate = (date) => {
  const d = new Date(date)
  let month = (d.getMonth() + 1).toString()
  let day = d.getDate().toString()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = `0${month}`
  }

  if (day.length < 2) {
    day = `0${day}`
  }

  return [year, month, day].join('-')
}

export const formatDateToTimeAgo = prevDate => {
  return timeAgo(prevDate, getCurrentLanguageLocale({forceEnglish: true}));
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
})

export const openLinkProgrammatically = (link, download) => {
  const linkNode = document.createElement("a");
  linkNode.href = link;
  linkNode.setAttribute("target", "_blank");

  if (download && download.length) linkNode.setAttribute("download", download);

  document.body.appendChild(linkNode);
  linkNode.click();
  document.body.removeChild(linkNode);
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const randomId = () => Math.random().toString(36).slice(2);

export const getCurrentCurrency = (countryCode) => {
  return { 'en': 'NOK', 'se': 'SEK', 'no': 'NOK' }[(countryCode || getCurrentLanguage()).toLowerCase()] || 'NOK';
};

export const linkifyUrls = (str, options = {}) => {
  const re =
    /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/gi;
  let m,
    buf = '',
    pos = 0;

  const encode = (val) =>
    (val === undefined ? '' : String(val)).replaceAll('"', '&quot;');
  const attrs = Object.entries(options.attributes || {})
    .map(([key, val]) => `${key}="${encode(val)}"`)
    .join(' ');

  while ((m = re.exec(str)) !== null) {
    buf +=
      str.substr(pos, m.index - pos) +
      `<a class="text-primary" href="${encodeURI(m[0])}"${attrs && ' ' + attrs}>${m[0]}</a>`;
    pos = m.index + m[0].length;
  }

  return buf + str.substr(pos);
}

export const trimSpecialCharaters = (str) => !str?.length ? "" : str.toString().replaceAll(/[^a-zA-Z0-9]/g, "");

export const isValidOrgNumber = (orgNumber) => {
	if (!orgNumber?.length) return "required";

	let num = trimSpecialCharaters(orgNumber);
  
  if(!num.split("").every(n => isFinite(n))) return i18n.t('invalid', {ns: "common"});

  if(num.length < 9) return i18n.t('too-short', {ns: "common"});

  if(num.length > 9) return i18n.t('too-long', {ns: "common"});

  return true;
};

export const camelToTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
