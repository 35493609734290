import Sidebar from "../../../../components/Sidebar";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

const CaseDetailsSidebar = ({ open, title = "", toggleSidebar, data }) => {
  return (
    <Sidebar
      size="lg"
      open={open}
      title={title}
      headerClassName="mb-1"
      contentClassName="p-0"
      bodyClassName="pb-sm-0 pb-3"
      onClose={toggleSidebar}
    >
      <div className="">
        <div className="p-3 text-left">
          <JSONPretty id="json-pretty" data={data} />
        </div>
      </div>
    </Sidebar>
  );
};

export default CaseDetailsSidebar;
