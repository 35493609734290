import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import {
  reevaluateCase,
  backOfficeReevaluateCase,
} from "../../../../../providers/api";
import { BASE_URL } from "../../../../../providers/api/request";
import { getValueFromLocalStorage } from "../../../../../hooks/useLocalStorageState";
import {
  formatDateTime,
  formatDateToTimeAgo,
  openLinkProgrammatically,
} from "../../../../../utils";

import Button from "../../../../components/Button";
import Select from "../../../../components/formControl/Select";

import { Badge, caseStatusObj } from "../../list/columns";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import Label from "../../../../components/formControl/Label";
import { useTranslation } from "react-i18next";
import { useAlerts } from "../../../../../providers/alerts";

const checkSteps = [
  "KYC",
  "AML",
  "Case signatories",
  "BANK_OWNERSHIP",
  "RISK",
  "INDUSTRY",
  "Notes and attachments",
];
const SummaryDetail = ({
  label = "Key",
  value = "Value",
  noBorder = false,
}) => {
  return (
    <div className={`py-4 flex flex-col ${!noBorder && "border-b"} relative`}>
      <span className="text-black opacity-70 uppercase p-0 m-0 text-xs font-medium tracking-wider">
        {label}
      </span>
      <span className="text-black text-sm font-medium">{value}</span>
    </div>
  );
};

const ReviewStepButton = ({ step }) => {
  const { t } = useTranslation("cases");
  const index = checkSteps.indexOf(step);
  const [complete, setComplete] = useState(false);

  const stepTarget = () => {
    const reviewSteps = document.querySelectorAll(".reviewStep");
    return index != -1 && reviewSteps && reviewSteps[index]
      ? reviewSteps[index]
      : null;
  };

  const scrollToReviewStep = () => {
    const target = stepTarget();
    if (target) target.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const deriveActiveState = () => {
    const target = stepTarget();

    if (!target || ["Case Signatories", "Notes and attachments"].includes(step))
      return;

    const checks = target.querySelectorAll("tbody tr");
    const passedChecks = target.querySelectorAll(".ActionButton.light-success");
    setComplete(checks?.length === passedChecks?.length);
  };

  useEffect(() => {
    deriveActiveState();
  }, [step]);

  return (
    <button
      className="group relative bg-black text-gray-700 border-2 rounded-md overflow-hidden w-full text-left px-3 py-2.5 flex items-center focus:outline-none"
      onClick={scrollToReviewStep}
    >
      <span className="absolute inset-0 bg-white group-hover:opacity-95"></span>

      <span
        className="relative mr-3 rounded-full flex items-center justify-center text-sm font-medium tracking-wide w-8 h-8 bg-gray-100 group-hover:bg-white group-hover:border text-black"
        style={{
          border: "1px solid #e5e5e5",
        }}
      >
        {(index + 1).toString().padStart(2, "0")}
      </span>
      <span className="border-l pl-3 relative truncate pr-2">
        {step == checkSteps.at(-1)
          ? t("notes-and-attachments.title")
          : step.toLowerCase() == "case signatories"
          ? t("case-signatories")
          : t(`checks.${step}.name`)}
      </span>

      <ChevronRightIcon
        className="relative ml-auto opacity-20 group-hover:opacity-40"
        strokeWidth={2}
        width={26}
      />
    </button>
  );
};

const RISK_LEVELS = {
  HIGH: "HIGH_RISK",
  NORMAL: "NORMAL_RISK",
  LOW: "LOW_RISK",
  MANUAL: "MANUAL_RATING_REQUIRED",
};

const CasePreviewSignatories = ({ data }) => {
  const { t } = useTranslation("cases");
  const [signatories, setSignatories] = useState([]);

  useEffect(() => {
    if (data.contracts?.[0]) {
      const contract = data.contracts[0];

      if (contract.details?.personRoles?.length) {
        const people = data.contracts[0].details.personRoles;
        setSignatories(people.filter(({ isSignatory }) => isSignatory));
      }
    }
  }, [data]);

  if (!signatories || !signatories.length) return null;

  return (
    <div className="relative border-t -mx-6">
      <div className="bg-black bg-opacity-5 px-6 py-2.5">
        <h5
          className="text-black font-medium uppercase text-sm tracking-wide -mb-3"
          style={{ marginBottom: "-0.15rem" }}
        >
          {t("case-signatories")}
        </h5>
      </div>

      <div className="px-6 grid grid-cols-2">
        {signatories.map((signatory, index) => (
          <SummaryDetail
            noBorder={signatories.length - index < 2}
            key={index}
            label={signatory.name}
            value={signatory.email}
          />
        ))}
      </div>
    </div>
  );
};

const PreviewActions = ({
  id,
  data,
  loadData,
  setBlock,
  saveLog,
  caseLogid,
  unsetPreviousSelectedLog
}) => {
  const { confirm } = useAlerts();
  const { t } = useTranslation("cases");
  const { isLoading: isReEvaluating, mutate: runReevaluateClick } = useMutation(
    backOfficeReevaluateCase,
    {
      onSuccess: () => {
        unsetPreviousSelectedLog();
        toast.success("Case reevaluated successfully");
        loadData();
      },
      onError: (err) => {
        toast.error(err.message);
      },
    }
  );
  const [rejectingApplicant, setRejectingApplicant] = React.useState(false);
  const [acceptingApplicant, setAcceptingApplicant] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState(null);
  const [selectedLogId, setSelectedLogId] = React.useState(null);

  const [note, setNote] = React.useState("");
  const [attachments, setAttachments] = React.useState([]);
  const [riskLevel, setRiskLevel] = React.useState(() => {
    const riskLevel = data.checksResults?.length
      ? data.checksResults[0]?.RISK?.financial?.riskLevel
      : null;
    const riskLevelLabelKey =
      Object.keys(RISK_LEVELS).find((key) => RISK_LEVELS[key] === riskLevel) ??
      "MANUAL";
    return {
      value: RISK_LEVELS[riskLevelLabelKey],
      label: RISK_LEVELS[riskLevelLabelKey].replaceAll("_", " ").toLowerCase(),
    };
  });
  const [isRiskLevelDirty, setIsRiskLevelDirty] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [downloadState, setDownloadState] = React.useState("idle");

  React.useEffect(() => {
    try {
      const logs = data.checksResults.map((log) => {
        return { value: log.id, label: formatDateTime(log.createdAt) };
      });

      if (logs?.length) {
        setSelectedLog(data.checksResults[logs.length - 1]);
        setSelectedLogId(data.checksResults[logs.length - 1].id);
      } else setSelectedLog(data);
    } catch (e) {
      console.log("Failed to process new data: ", e);
    }
  }, [data]);

  // const
  const runCDD = async () => {
    setBlock(true);
    await reevaluateCase(id);
    await loadData();
    setBlock(false);
  };

  const addAttachment = React.useCallback(
    async (attachment) => {
      setAttachments([...attachments, attachment]);
      await saveLog("saveAttachments", [...attachments, attachment]);
    },
    [attachments, setAttachments, saveLog]
  );

  const updateRiskLevel = React.useCallback(
    async (riskLevel) => {
      setIsUpdating(true);
      if (isRiskLevelDirty) {
        await saveLog("updateRiskLevel", { riskLevel });
      }
      setIsUpdating(false);
    },
    [isRiskLevelDirty, saveLog]
  );

  const selectedLogdetails = selectedLog?.details || {
    postalAddress: {},
  };

  async function downloadPDF() {
    const tenant = getValueFromLocalStorage("tenant", null);
    const token = getValueFromLocalStorage("authToken", null);

    // try {
    //   setDownloadState("pending")
    //   const res = await request('GET', `/cases/summary/${id}`, null,
    //     { Accept: "application/pdf" },
    //     { responseType: "blob", response: true }
    //   );
    //   const response = await res.blob();

    //   const blob = new Blob([response.data], { type: "application/pdf" })
    //   const filename = `${new Date().toISOString()}.pdf`
    //   const url = window.URL.createObjectURL(blob)

    //   openLinkProgrammatically(url, filename)
    //   setDownloadState("idle")
    // } catch (error) {
    //   setDownloadState("error")
    // }

    try {
      setDownloadState("pending");
      const res = await fetch(`${BASE_URL}/cases/summary/${id}`, {
        headers: {
          Accept: "application/pdf",
          Tenant: tenant?.id,
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        response: true,
      });
      const response = await res.blob();

      const blob = new Blob([response.data], { type: "application/pdf" });
      const filename = `${new Date().toISOString()}.pdf`;
      const url = window.URL.createObjectURL(blob);
      openLinkProgrammatically(url, filename);

      setDownloadState("idle");
    } catch (error) {
      setDownloadState("error");
    }
  }

  async function handleReevaluateClick() {
    const caseId = data.contracts[0]?.caseId;
    runReevaluateClick(caseId);
  }

  const handleRejectApplication = async () => {
    const res = await confirm({
			title: t("reject-applicant-prompt.title"),
			message: t("reject-applicant-prompt.message"),
			okayText: t("reject-applicant-prompt.action"),
		});
    if(res) {
      try {
        setRejectingApplicant(true);
        await saveLog("decline");
        toast.success(t("applicant-rejected"));
      } catch (error) {
        toast.error(t("applicant-not-rejected"));
      } finally {
        setRejectingApplicant(false);
      }
    }
  }

  const handleAcceptApplication = async () => {
    try {
      setAcceptingApplicant(true);
      await saveLog("activate");
      toast.success(t("applicant-accepted"));
    } catch (error) {
      toast.error(t("applicant-not-accepted"));
    } finally {
      setAcceptingApplicant(false);
    }
  }

  const caseDeclarationFilled = data?.caseStatus !== "WAITING_FOR_DECLARATION";
  const contractDetails = data?.contracts?.length
    ? data.contracts[0].details
    : {};

  return (
    <div className="min-h-full">
      <div className="pb-8 pt-1">
        <div className="pb-3 flex items-center justify-between">
          <h2 className="m-0 p-0 text-black opacity-70 text-lg leading-none font-semibold">
            {t("case-details")}
          </h2>
        </div>

        <div className="relative px-6 pt-1 pb-4">
          <div className="absolute inset-0 rounded-md bg-white border-2"></div>

          <SummaryDetail label={t("case-id")} value={data.id} />

          <div className="grid grid-cols-2">
            <SummaryDetail
              label={t("company-name")}
              value={contractDetails?.companyName}
            />
            <SummaryDetail
              label={t("org-nr")}
              value={contractDetails.organizationNumber}
            />
          </div>

          <SummaryDetail
            label={t("company-hq")}
            value={`${contractDetails?.postalAddress?.streetAddress}, ${
              contractDetails?.postalAddress?.city
            }, ${contractDetails?.postalAddress?.postalCode}, ${
              contractDetails?.postalAddress?.countryCode?.toUpperCase() ?? ""
            }`}
          />

          <SummaryDetail
            label={t("application-date")}
            value={formatDateTime(data.createdAt)}
          />

          <div className="grid grid-cols-2">
            <SummaryDetail
              noBorder={true}
              label={t("manual-update")}
              value={formatDateTime(selectedLog?.updatedAt)}
            />

            <SummaryDetail
              noBorder={true}
              label={t("last-monitored")}
              value={formatDateTime(selectedLog?.createdAt)}
            />
          </div>
        </div>
      </div>

      <div className="pb-5">
        <h2 className="mb-2 text-black opacity-70 text-lg leading-none font-semibold">
          {t("case-summary")}
        </h2>

        <div className="relative px-6 py-2">
          <div className="absolute inset-0 rounded-md bg-white border-2"></div>

          <div className="relative">
            <div className="flex items-center mt-1.5 mb-0.5">
              <span className="text-black uppercase p-0 m-0 text-xs font-medium tracking-wide">
                {t("company")}:
              </span>
              <span className="mx-3 my-3 font-bold leading-none uppercase tracking-wide text-xs">
                {selectedLogdetails?.companyName}
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <span className="text-black uppercase p-0 m-0 text-xs font-medium tracking-wide">
                {t("status")}:
              </span>

              <div className="py-3">
                <Badge
                  color={caseStatusObj[data.caseStatus]?.color ?? "light-dark"}
                >
                  {t("caseStatus." + data.caseStatus)}
                </Badge>
              </div>
            </div>

            <div className="flex items-center mb-0.5">
              <span className="text-black uppercase p-0 m-0 text-xs font-medium tracking-wide">
                {t("last activity")}:
              </span>
              <span className="mx-3 my-3 font-bold leading-none uppercase tracking-wide text-xs">
                {formatDateToTimeAgo(selectedLog?.updatedAt)}
              </span>
            </div>

            <p className="mb-3 leading-loose text-black opacity-60 text-sm">
              {data.caseStatus === "UNDER_MANUAL_REVIEW"
                ? t("case-summary-message.under-manual-review")
                : t("case-summary-message.default")}
            </p>
          </div>
        </div>
      </div>

      <div className="pt-2.5 sticky" style={{ top: "60px" }}>
        {selectedLogId && (
          <div className="hidden lg:block mb-7">
            <h2 className="mb-2 text-black opacity-70 text-lg leading-none font-semibold">
              {t("review-steps")}
            </h2>

            <div className="space-y-2">
              {checkSteps.map((step, index) => (
                <ReviewStepButton key={index} step={step} />
              ))}
            </div>
          </div>
        )}

        <div>
          <h2 className="mb-2 text-black opacity-70 text-lg leading-none font-semibold">
            {t("action-items")}
          </h2>

          <div className={!selectedLogId && "opacity-50 pointer-events-none"}>
            <div className="mb-4 p-4 bg-white border-2 rounded-md">
              <Label>{t("risk-verdict-by-agent")}</Label>

              <div className="flex items-center pt-1">
                <div className="flex-1 mr-2">
                  <Select
                    onChange={(value) => {
                      setRiskLevel(value);
                      setIsRiskLevelDirty(true);
                    }}
                    value={riskLevel}
                    choices={[
                      "HIGH_RISK",
                      "NORMAL_RISK",
                      "LOW_RISK",
                      "MANUAL_RATING_REQUIRED",
                    ].map((level) => ({
                      value: level,
                      label: t(`agent-verdict.${level}`),
                    }))}
                  />
                </div>
                <Button
                  color="primary"
                  onClick={() => {
                    updateRiskLevel(riskLevel.value);
                  }}
                  disabled={isUpdating || !isRiskLevelDirty}
                  loading={isUpdating}
                  className="py-3 px-6 border-primary"
                >
                  {isUpdating ? t("saving") : t("save")}
                </Button>
              </div>
            </div>

            <Button
              color="secondary"
              block
              outline
              className="mb-2"
              onClick={() => runCDD()}
            >
              {t("update-cdd")}
            </Button>
            {/* <Button
              color="secondary"
              block
              className="mb-2"
              disabled={downloadState === "pending"}
              onClick={downloadPDF}
            >
              {downloadState === "pending"
                ? t("downloading")
                : t("download-pdf")}
            </Button> */}
            {data.caseStatus !== "DECLINED" && (
              <Button
                color="danger"
                loading={rejectingApplicant}
                disabled={acceptingApplicant}
                onClick={handleRejectApplication}
                block
                className="mb-2"
              >
                {t("reject-applicant")}
              </Button>
            )}
            {data.caseStatus !== "ACTIVE" && (
              <Button
                color="success"
                block
                className="mb-2"
                loading={acceptingApplicant}
                disabled={rejectingApplicant}
                onClick={handleAcceptApplication}
              >
                {t("accept-applicant")}
              </Button>
            )}
          </div>

          {data.caseStatus !== "ACTIVE" && (
            <Button
              block
              outline
              disabled={isReEvaluating}
              onClick={handleReevaluateClick}
              className={
                !caseDeclarationFilled && "opacity-50 pointer-events-none"
              }
            >
              {isReEvaluating
                ? t("rerunning-case-evaluation")
                : caseLogid
                ? t("rerun-case-evaluation")
                : t("run-case-evaluation")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewActions;
