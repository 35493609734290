import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { AuthProvider } from "./providers/auth";

import Login from "./ui/pages/Login";
import Register from "./ui/pages/Register";

import ForgotPassword from "./ui/pages/ForgotPassword";
import ChangePassword from "./ui/pages/ChangePassword";
import ValidateAccount from "./ui/pages/ValidateAccount";
import ConfirmNewPassword from "./ui/pages/ConfirmNewPassword";

import Cases from "./ui/pages/cases/list";
import CreateCase from "./ui/pages/cases/CreateCase";
import CasePreview from "./ui/pages/cases/CasePreview";
import ApiKeys from "./ui/pages/APIKeys";
import CreateApiKey from "./ui/pages/APIKeys/CreateApiKey";
import TeamMembers from "./ui/pages/TeamMembers";
import AddTeamMember from "./ui/pages/TeamMembers/Add";

import Resellers from "./ui/pages/Resellers";
import AddReseller from "./ui/pages/Resellers/Add";

import PageNotFound from "./ui/pages/misc/404";

import AuthLayout from "./ui/layouts/AuthLayout";
import { useAuth } from "./providers/auth";

import ContractSignError from "./ui/pages/Contract/ContractSignError";
import ContractSigned from "./ui/pages/Contract/ContractSigned";
import ContractSubmitted from "./ui/pages/Contract/ContractSubmitted";
import ContractNotFound from "./ui/pages/Contract/ContractNotFound";
import DeclarationForm from "./ui/pages/Contract/DeclarationForm";
import CaseNotFound from "./ui/pages/cases/CaseNotFound";
import LanguageSwitcher from "./ui/components/LanguageSwitcher";
import ContractPreview from "./ui/pages/Contract/Preview";

const ProtectedRoute = (props) => {
  const { authUser } = useAuth();

  if (!authUser) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return (
    <AuthLayout>
      <Route {...props} />
    </AuthLayout>
  );
};

const PublicRoute = (props) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed top-3 right-3 z-50">
        {/* <LanguageSwitcher /> */}
      </div>
      <Route {...props} />
    </div>
  );
};

const AppRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <React.StrictMode>
        <AuthProvider>
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/register" component={Register} />
            <PublicRoute path="/forgot-password" component={ForgotPassword} />
            <PublicRoute path="/change-password" component={ChangePassword} />
            <PublicRoute path="/confirm" component={ValidateAccount} />
            <PublicRoute
              path="/confirm-password"
              component={ConfirmNewPassword}
            />
            <PublicRoute
              path="/contract/sign/error"
              component={ContractSignError}
            />
            <PublicRoute
              path="/contract/:id/sign/error"
              component={ContractSignError}
            />
            <PublicRoute
              path="/contract/sign/:contractId"
              component={DeclarationForm}
            />
            <PublicRoute
              path="/contract/signed/:contractId"
              component={ContractSigned}
            />
            <PublicRoute
              path="/contract/:contractId/sign/success"
              component={ContractSigned}
            />
            <PublicRoute
              path="/contract/submitted"
              component={ContractSubmitted}
            />
            <PublicRoute
              path="/contract/not-found"
              component={ContractNotFound}
            />
            <PublicRoute
              path="/contract/preview/:caseId"
              component={ContractPreview}
            />

            <ProtectedRoute path="/" isAuthProtected={true}>
              <Switch>
                <Redirect exact path="/" to="/cases" />
                <Route path="/cases" exact component={Cases} />
                <Route path="/cases/new" component={CreateCase} />
                <Route path="/cases/not-found" component={CaseNotFound} />
                <Route path="/cases/:caseId" component={CasePreview} />
                {/* <Route path='/contract/signatories/:id' component={ContractSignatories} /> */}

                <Route path="/api-keys" exact component={ApiKeys} />
                <Route path="/api-keys/add" component={CreateApiKey} />

                <Route path="/team-members" exact component={TeamMembers} />
                <Route path="/team-members/add" component={AddTeamMember} />

                <Route path="/resellers" exact component={Resellers} />
                <Route path="/resellers/add" exact component={AddReseller} />

                <PublicRoute path="*" component={PageNotFound} />
              </Switch>
            </ProtectedRoute>

            <PublicRoute path="*" component={PageNotFound} />
          </Switch>
        </AuthProvider>
      </React.StrictMode>
    </BrowserRouter>
  );
};

export default AppRouter;
