import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../../components/Loader";
import { fetchCaseDetails, updateCaseLog } from "../../../../providers/api";
import PreviewActions from "./PreviewActions";
import CaseDetails from "./CaseDetails";
import CaseSignatories from "./CaseSignatories";
import { useTranslation } from "react-i18next";
import { HTTP404Error } from "../../../../providers/api/request";

const CasePreview = () => {
  const { t } = useTranslation("cases");
  const history = useHistory();
  const { caseId } = useParams();
  const [data, setData] = useState(null);
  const [sendSidebarOpen, setSendSidebarOpen] = useState(false);

  const [addPaymentOpen, setAddPaymentOpen] = useState(false);
  const [block, setBlock] = useState(false);

  const [previousSelectedLogId, setPreviousSelectedLogId] = useState("");
  const [selectedLogId, setSelectedLogId] = useState("");

  const loadData = async () => {
    try {
      const caseDetails = await fetchCaseDetails(caseId);
      setData(caseDetails.data);
    } catch (error) {
      if (error instanceof HTTP404Error) {
        console.log("Case not found!", error);
        history.replace("/cases/not-found");
      }
    }
  };

  const updateSelectedLogid = (id) => {
    setSelectedLogId(id);
  };

  const saveLog = async (action, data = []) => {
    setBlock(true);
    setPreviousSelectedLogId(selectedLogId);
    await updateCaseLog(selectedLogId, {
      action,
      data,
    });
    await loadData();
    setBlock(false);

    return true;
  };

  // ** Get invoice on mount based on id
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadData();
  }, [caseId]);

  if (data == null) {
    return (
      <div className="h-screen bg-gray-100">
        <div className="relative py-6 flex flex-col items-center justify-center">
          <Loader fillParent={false} size={60} thickness={5} color="#ddd" />
          <span className="mt-2.5 opacity-80">
            {t("fetching-case-details")}
          </span>
        </div>
      </div>
    );
  }

  const formName = data?.contracts?.[0].type || "Declaration";

  return data !== null ? (
    <div className="lg:grid p-4 pb-10 grid-cols-12 gap-x-6">
      <div className="col-span-3">
        <PreviewActions
          id={data.id}
          data={data}
          updateSelectedLogid={updateSelectedLogid}
          setSendSidebarOpen={setSendSidebarOpen}
          setAddPaymentOpen={setAddPaymentOpen}
          loadData={loadData}
          unsetPreviousSelectedLog={() => setPreviousSelectedLogId(null)}
          saveLog={saveLog}
          setBlock={setBlock}
          selectedLogId={selectedLogId}
        />
      </div>

      <div className="mt-7 lg:mt-0 col-span-9 lg:px-8">
        <div className="w-full mx-auto">
          <CaseDetails
            data={data}
            formName={formName}
            previousSelectedLogId={previousSelectedLogId}
            updateSelectedLogid={updateSelectedLogid}
            saveLog={saveLog}
            loadData={loadData}
            block={block}
            setBlock={setBlock}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CasePreview;
