import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { isObjEmpty } from "../../../utils";
import { useHistory } from "react-router-dom";

import { inviteReseller } from "../../../providers/api";
import useLocalStorageState from "../../../hooks/useLocalStorageState";

import ModalMessageCentered from "../../components/ModalMessageCentered";
import Button from "../../components/Button";
import Input from "../../components/formControl/Input";
import { useTranslation } from "react-i18next";

const AddReseller = () => {
	const { t } = useTranslation("resellers");
	const [tenant] = useLocalStorageState("tenant", null);
	const [reseller, setReseller] = useState(null);
	const history = useHistory();
	const [userInvited, setUserInvited] = useState(false);
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();
	const { isLoading, data, error, mutate } = useMutation(inviteReseller, {
		onSuccess: (res) => {
			setUserInvited(true);
		},
		onError: (err) => {
			toast.error(err?.message ? err.message : err);
		},
	});

	const onSubmit = async (data) => {
		if (isObjEmpty(errors)) {
			setReseller({...data});
			mutate(data);
		}
	};

	const cancelAddReseller = () => {
		history.replace("/resellers");
	};

	return (
		<div className="pt-8 max-w-xl mx-auto">
			<div className="bg-white shadow-sm rounded-md overflow-hidden border">
				<div className="py-4 px-6 border-b">
					<h4 className="text-xl font-semibold text-black m-0 leading-none">
						{t("add-reseller")}
					</h4>
				</div>
				<form
					className="px-7 pt-5 pb-7 space-y-3"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Input
						label="Name"
						name="name"
						id="name"
						placeholder=""
						validator={register("name", {
							required: true,
						})}
						error={errors["name"]}
					/>
					<Input
						label={t("email")}
						name="email"
						id="email"
						placeholder=""
						validator={register("email", {
							required: true,
							validate: (value) => value !== "",
						})}
						error={errors["email"]}
					/>

					<div className="pt-3 flex justify-end space-x-2">
						<Button
							outline
							disabled={isLoading}
							className="py-3 px-7"
							onClick={cancelAddReseller}
						>
							{t("cancel")}
						</Button>
						<Button
							color="primary"
							loading={isLoading}
							type="submit"
							className="py-3 px-7"
						>
							{isLoading ? t("please-wait") : t("save-reseller")}
						</Button>
					</div>
				</form>
			</div>

			<ModalMessageCentered
				open={userInvited}
				success
				title={t("invite-success.title")}
				message={t("invite-success.message", {
					email: reseller?.email,
				})}
				onClose={() => history.push("/resellers")}
			/>
		</div>
	);
};
export default AddReseller;
