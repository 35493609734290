import errorImg from "../misc/error-illustration.svg";
import Logo from "../../components/Logo";
import i18n from "../../../i18n";

const ContractMessage = ({
  title = i18n.t("thank-you", { ns: "public-pages" }),
  subTitle = "",
  nextSteps = [],
  message = "Your application is currently being reviewed by our team. Should we have any questions, a team member will contact you shortly.",
  message2 = "",
  children,
}) => {
  return (
    <div className="h-screen md:flex items-center justify-center fixed inset-0 z-20">
      <div className="fixed inset-0 bg-gray-100 -z-1"></div>
      <Logo />
      <div className="pt-32 md:pt-0 px-10 max-w-6xl m-auto md:border md:bg-white md:shadow rounded-md">
        <div className="md:flex items-center">
          <div className="mr-8 text-gray-700 py-12">
            <h4 className="mb-3 text-gray-900 text-2xl font-semibold">
              {title}
            </h4>
            {subTitle && subTitle.length && (
              <h5 className="mb-3 text-gray-900 text-lg font-medium">
                {subTitle}
              </h5>
            )}

            {nextSteps?.length > 0 && (
              <p className="mt-3 mb-4">
                <h3 className="font-bold text-lg mb-1">Next steps</h3>
                <ul className="pl-6">
                  {nextSteps.map((step, index) => (
                    <li key={index} className="mb-1 list-disc leading-relaxed">
                      {step}
                    </li>
                  ))}
                </ul>
              </p>
            )}
            <p
              className="leading-relaxed"
              dangerouslySetInnerHTML={{ __html: message }}
            ></p>
            {message2 && message2.length && (
              <p
                className="mt-3 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: message2 }}
              ></p>
            )}
            {children}
          </div>

          <img className="w-96" src={errorImg} alt="Not authorized page" />
        </div>
      </div>
    </div>
  );
};
export default ContractMessage;
