import FormSection from '../FormSection';
import { numberWithCommas } from '../../../../../utils';
import Input from '../../../../components/formControl/Input';
import Checkbox from '../../../../components/formControl/Checkbox';
import { useTranslation } from 'react-i18next';

const PricingAndTerms = ({
  details,
  errors,
  register,
  addAttachment,
  removeItem,
  handleValueChange,
}) => {
  const {t} = useTranslation('declaration-form');

  return (
    <FormSection
      label={t('step-7')}
      title={t('pricing-section.title')}
      subtitle={t('pricing-section.description')}
    >
      {details?.pricing?.length > 0 && (
        <div>
          <h5 className="text-gray-500 font-semibold leading-none m-0 p-0">
            {t('product-service-prices.title')}
          </h5>
          <div className="mt-1 mb-3 text-black opacity-60 text-sm font-light leading-relaxed">
            {t('product-service-prices.description')}
          </div>

          <div className="-mx-6 bg-gray-50">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>{t('product')}</th>
                  <th>{t('description')}</th>
                  <th>{t('pricing')}</th>
                </tr>
              </thead>
              <tbody>
                {details.pricing.map((term, i) => (
                  <tr key={i}>
                    <td className='font-medium'>{term.product}</td>
                    <td>{term.description}</td>
                    <td>{numberWithCommas(term.price)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </FormSection>
  );
};

export default PricingAndTerms;
