import { useTranslation } from "react-i18next";
import Input from "../../../../components/formControl/Input";
import FormSection from "../FormSection";
import { useState } from "react";
import { getAccountNumberValidator } from "../../../../../utils/bankAccountNumber";

const BankingAndCurrencyDetails = ({
  details,
  errors,
  register,
  handleChange,
}) => {
  const { t } = useTranslation("declaration-form");

  const accountNumberValidator = getAccountNumberValidator(
    details.countryCode,
    {
      short: t("too-short"),
      long: t("too-long"),
      invalid: t("invalid"),
    }
  );

  return (
    <FormSection
      label={t("step-2")}
      title={t("banking-account-details.title")}
      subtitle={t("banking-account-details.description")}
    >
      <div className="mb-3 text-sm text-black opacity-60 font-light leading-relaxed">
        {t("banking-account-details.message")}
      </div>

      <div className="grid grid-cols-2 gap-6">
        <Input
          label={t("bank-account-number")}
          type="text"
          name="bankAccount_number"
          value={details.bankAccount.bankAccountNumber}
          isLoading={false}
          onChange={(e) => {
            e.target.value = e.target.value.replace(/[a-zA-Z]/g, "");
            handleChange("bankAccount", "bankAccountNumber", null, e);
          }}
          id="bankAccount_number"
          validator={register("bankAccount_number", {
            validate: (value) => {
              value = value.replace(/[^0-9]/g, "");
              if (!value) return "required";
              return accountNumberValidator(value);
            },
          })}
          error={errors.bankAccount_number}
        />
      </div>
    </FormSection>
  );
};

export default BankingAndCurrencyDetails;
