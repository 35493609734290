function Dots() {
  return (
    <svg
      width="192"
      height="146"
      viewBox="0 0 192 146"
      className="sprite-mobile-small sprite sprite-dots"
      style={{ color: '#C0D6EA' }}
    >
      <circle r="1.5" fill="currentColor" cx="2" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="2" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="25" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="48" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="71" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="94" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="117" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="140" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="163" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="186" cy="140"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="2"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="25"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="48"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="71"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="94"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="117"></circle>
      <circle r="1.5" fill="currentColor" cx="209" cy="140"></circle>
    </svg>
  );
}

export default Dots;
