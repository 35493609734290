import CreditScoreResults from "./CreditScoreResults";

export const sampleData = () => {
	const data = {
		countryCode: "no",
		sourceMetadata: {
			company_id: "afafafaf",
		},
		details: {
			companyStatus: "Active",
		},
	};

	const creditScore = {
		limit: 155000, // 5, // "No limit",
		score: {
			previous: {
				common: "D",
				value: 12,
				lastUpdatedAt: "2022-02-11T18:13:00Z",
			},
			current: {
				common: "C",
				value: 37,
				lastUpdatedAt: "2022-06-14T18:13:00Z",
			},
		},
	};

	return {
		data,
		selectedLog: { CREDIT: { creditScore } },
	};
}

const CreditScoreWrapper = (props) => {
	const { selectedLog, data } = props; //sampleData();
	return <CreditScoreResults selectedLog={selectedLog} data={data} />;
};

export default CreditScoreWrapper;
