import { XIcon } from "@heroicons/react/solid";
import Modal from "./Modal";
import Button from "./Button";
import i18n from "../../i18n";

const ModalMessageCentered = ({
	open = true,
	size,
	title = "Modal Title",
	message,
	action = i18n.t("okay", { ns: "common" }),
	actionStyle = "outline", //"primary"
	error = false,
	success = false,
	actions,
	children,
	hideCloseButton = false,
	onOkay,
	onClose,
}) => {
	const handleClose = () => {
		if (onOkay) {
			onOkay();
			return;
		}

		if (onClose) onClose();
	};

	return (
		<Modal
			open={open}
			alignTop={true}
			onClose={handleClose}
			noPadding
			contentClassName="overflow-hidden"
			modalClassName=""
			size={size}
		>
			<div
				className={`
        flex-grow-1 relative border-t-4 pb-2
        ${
			error
				? "border-red-500"
				: success
				? "border-green-400"
				: "border-transparent"
		}
      `}
			>
				{onClose && !hideCloseButton && (
					<button
						type="button"
						className="absolute right-2.5 top-1 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={handleClose}
					>
						<span className="sr-only">Close</span>
						<svg
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				)}

				<div className="mt-4">
					<div className="text-center md:mt-5">
						<h3
							className="text-lg leading-6 font-bold text-gray-900 px-4"
							id="modal-title"
						>
							{title}
						</h3>
						<div className="md:mt-2">
							{(children || message?.length) && (
								<p className="text-base text-gray-500 max-w-md mx-auto px-6">
									{children ? children : message}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="md:mt-4 mt-6 mb-4 px-6 flex justify-center items-center space-x-3">
					{actions ? (
						actions
					) : (
						<Button
							{...(actionStyle == "outline"
								? { outline: true }
								: { color: "primary" })}
							style={{
								minWidth: size == "md" ? "100px" : "130px",
							}}
							onClick={handleClose}
						>
							{action}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ModalMessageCentered;
