function downloadApiKey(apiKey) {
  const link = document.createElement('a');
  const apiKeyDetails = `apiId: ${apiKey.apiId},\npassword: ${apiKey.password}`;
  const apikeyDetailsFile = `data:text/plain;charset=utf-8,${apiKeyDetails}`;

  const filename = `${apiKey.name}-api-key-details.txt`;

  link.setAttribute('href', encodeURI(apikeyDetailsFile));
  link.setAttribute('download', filename);
  link.click();
}

export default downloadApiKey;