import mod11 from "./mod11";

export const validateNorwegianBankAccountNumber = (errorMessages) => {
  return (account_number) => {
    if (account_number) {
      const trimmed = account_number.replace(/\s/g, "");
      try {
        const numbers = trimmed.replace(/^\D+/g, "");
        if (numbers.length < 11) {
          return errorMessages.short;
        } else if (numbers.length > 11) {
          return errorMessages.long;
        } else if (mod11(numbers) === parseInt(numbers.substring(10))) {
          return;
        }
      } catch {}
    }
    return errorMessages.invalid;
  };
};

export const getAccountNumberValidator = (country, errorMessages) => {
  if ((country || "").toLocaleLowerCase() === "no") {
    return validateNorwegianBankAccountNumber(errorMessages);
  }
  return (value) => {
    return undefined;
  };
};
