import { randomId } from "../../../utils";
import Label from "./Label";
import ValidationMessage from "./ValidationMessage";

export default function Textarea({
  id = randomId(),
  label,
  rows = 3,
  validator = {},
  error,
  className,
  wrapperClassName,
  ...inputProps
}) {
  return (
    <div className={`flex flex-col ${wrapperClassName}`}>
      {label && label.length && <Label htmlFor={id}>{label}</Label>}

      <div className="relative">
        <textarea
          id={id}
          rows={rows}
          className={`placeholder-gray-400/70
            ${className}
            ${
              error
                ? "border-red-500 focus:border-red-500/50 focus:ring-red-500"
                : "border-gray-400/70"
            }
          `}
          {...validator}
          {...inputProps}
        />
      </div>

      <ValidationMessage error={error} />
    </div>
  );
}
