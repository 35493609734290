import { useState, useEffect } from "react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  ClipboardCopyIcon,
  ExclamationIcon,
  ExternalLinkIcon,
  LinkIcon,
  MailIcon,
  PencilIcon,
  RefreshIcon,
} from "@heroicons/react/solid";

import { Badge } from "../list/columns";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";
import {
  manuallySign,
  manuallyUnSign,
  sendEmailToSignatory,
} from "../../../../providers/api";
import Button from "../../../components/Button";
import ReviewStep from "../../../components/ReviewStep";
import { useTranslation } from "react-i18next";
import { formatDate, formatTime } from "../../../../utils";
import { toast } from "react-toastify";
import { useAlerts } from "../../../../providers/alerts";
import { getContractUrl } from "../../../../utils/contractUrl";
import { signatoryRoleFromKey } from "../../Contract/DeclarationForm/dataLoader";

function CopyLinkButton({ link }) {
  const { t } = useTranslation("cases");
  const { copy: copyLink, status: copyStatus } = useCopyToClipboard();

  useEffect(() => {
    if (copyStatus == "SUCCEEDED") toast.success(t("link-copied"));
  }, [copyStatus]);

  return (
    <Button
      type="button"
      size="sm"
      outline
      className="flex items-center"
      onClick={(e) => {
        e.preventDefault();
        copyLink(link, 700);
      }}
    >
      <span className="mb-0.5 mr-1.5 -ml-0.5" style={{ height: "12px" }}>
        <LinkIcon
          className="text-gray-400"
          stroke="currentColor"
          strokeWidth={0.1}
          width={13}
        />
      </span>
      {t("copy-link")}{" "}
    </Button>
  );
}

function ResendEmailButton({ contractId, signatoryId, signatoryEmail }) {
  const { t } = useTranslation("cases");
  const [email, setEmail] = useState(signatoryEmail);
  const [resendStatus, setResendStatus] = useState("IDLE");

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setResendStatus("LOADING");
    try {
      await sendEmailToSignatory(contractId, signatoryId, email);
      setResendStatus("IDLE");
      toast.success(t("email-sent"));
    } catch (error) {
      setResendStatus("IDLE");
      toast.error(t("email-not-sent"));
    }
  };

  return (
    <form className="flex items-center" onSubmit={handleSendEmail}>
      <input
        type="text"
        placeholder="john@example.com"
        style={{ maxWidth: "130px", borderRadius: 0 }}
        className="w-full py-1 px-0 rounded-none border-0 border-b text-sm placeholder-gray-400/70"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Button
        type="submit"
        size="sm"
        outline
        className="ml-2 mb-0.5"
        loading={resendStatus === "LOADING"}
        disabled={resendStatus === "LOADING"}
      >
        <span className="py-px">{t("resend")}</span>
      </Button>
    </form>
  );
}

function ManuallySignButton({ contractId, signatoryId, loadData }) {
  const { t } = useTranslation("cases");
  const [signStatus, setSignStatus] = useState("IDLE");
  const { confirm } = useAlerts();

  const handleSign = async (e) => {
    e.preventDefault();
    const res = await confirm({
      title: t("sign-prompt.title"),
      message: t("sign-prompt.message"),
      okayText: t("sign-prompt.action"),
    });

    if (res) {
      setSignStatus("LOADING");
      try {
        await manuallySign(contractId, signatoryId);
        await loadData();
        setSignStatus("IDLE");
        toast.success(t("manually-signed"));
      } catch (error) {
        setSignStatus("IDLE");
        toast.error(t("manual-sign-failed"));
      }
    }
  };

  return (
    <Button
      type="button"
      size="sm"
      outline
      className="flex items-center"
      loading={signStatus === "LOADING"}
      disabled={signStatus === "LOADING"}
      onClick={handleSign}
    >
      <span className="mb-0.5 mr-1.5 -ml-0.5" style={{ height: "12px" }}>
        <PencilIcon className="text-gray-400" width={13} />
      </span>
      {t("sign")}
    </Button>
  );
}

function ManuallyUnSignButton({ contractId, signatoryId, loadData }) {
  const { t } = useTranslation("cases");
  const [unSignStatus, setUnsignStatus] = useState("IDLE");
  const { confirm } = useAlerts();

  const handleUnsign = async (e) => {
    e.preventDefault();
    const res = await confirm({
      title: t("unsign-prompt.title"),
      message: t("unsign-prompt.message"),
      okayText: t("unsign-prompt.action"),
    });

    if (res) {
      setUnsignStatus("LOADING");
      try {
        await manuallyUnSign(contractId, signatoryId);
        await loadData();
        setUnsignStatus("IDLE");
        toast.success(t("manually-unsigned"));
      } catch (error) {
        setUnsignStatus("IDLE");
        toast.error(t("manual-unsign-failed"));
      }
    }
  };

  return (
    <Button
      type="button"
      size="sm"
      outline
      className="flex items-center"
      loading={unSignStatus === "LOADING"}
      disabled={unSignStatus === "LOADING"}
      onClick={handleUnsign}
    >
      <span className="mb-0.5 mr-1.5 -ml-0.5" style={{ height: "12px" }}>
        <PencilIcon className="text-gray-400" width={13} />
      </span>
      {t("unsign")}
    </Button>
  );
}

const CaseSignatories = ({ data, loadData = () => {}, formName, onAddNotes }) => {
  const { t } = useTranslation("cases");
  const [contract, setContract] = useState(null);
  const [signatories, setSignatories] = useState([]);

  useEffect(() => {
    if (data.contracts?.[0]) {
      const contract = data.contracts[0];
      setContract(contract);

      if (contract.details?.personRoles?.length) {
        const people = data.contracts[0].details.personRoles;
        if (Array.isArray(people))
          setSignatories(people.filter(({ isSignatory }) => isSignatory));
      }
    }
  }, [data]);

  if (!contract?.isSubmitted) {
    return (
      <div className="mt-4 mb-7">
        <div className="py-5 px-6 bg-white shadow-sm rounded overflow-hidden border z-10 relative flex justify-between">
          {t("pending-declaration.message", { formName })}
          <a
            href={contract?.contractUrl}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center underline text-sm text-gray-600"
          >
            {t("pending-declaration.button", { formName })}

            {/* <ChevronRightIcon
							className="pt-0.5 ml-0.5 text-gray-400"
							stroke="currentColor"
							strokeWidth={0.1}
							width={20}
						/> */}
          </a>
        </div>
      </div>
    );
  }

  if (!signatories) return null;

  const signedSignatories = signatories.filter(({ isSigned }) => isSigned);
  const signatoryRoles = signatoryRoleFromKey(data?.details?.signatoryRoles)?.englishLabel;

  const translatedRole = (role) =>
    !role
      ? ""
      : t(
          role
            .toString()
            .toLowerCase()
            .replaceAll(" ", "-")
            .replaceAll("_", "-")
        );

  // signatoryRuleOptions

  return (
    <div className="mt-2 pb-2.5">
      <div className="mb-3"></div>
      <ReviewStep
        heading={t("case-signatories")}
        subHeading={`${t("case-signatories-subtitle")} ${signatoryRoles?.length ? t("signatory-rules-text", {signatoryRoles}) : t("no-signatory-rules")}`}
        onAddNotes={onAddNotes}
      >
        <table className="table">
          <thead>
            <tr>
              <th>{t("signatory")}</th>
              <th>{t("timestamp")}</th>
              <th>{t("status")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {!signatories.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <span className="inline-block mt-4">
                    {t("no-signatories", { formName })}
                  </span>
                </td>
              </tr>
            )}
            {signatories.map((signatory, index) => (
              <tr key={index}>
                <td>
                  <span className="block text-black font-medium">
                    {signatory.name}
                  </span>
                  <span className="block text-black font-semibold">
                    {translatedRole(signatory.title)}
                  </span>
                  {signatory.email?.length > 0 && (
                    <small className="text-sm font-light">
                      {signatory.email}
                    </small>
                  )}
                </td>
                <td>
                  {signatory.isSigned ? (
                    <>
                      {formatDate(signatory.signedAt)},{" "}
                      {formatTime(signatory.signedAt)}
                    </>
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <Badge
                    color={signatory.isSigned ? "light-success" : "light-dark"}
                  >
                    {signatory.isSigned
                      ? t(
                          signatory.status == "manual_signed"
                            ? "manually-signed"
                            : "signed"
                        )
                      : t(
                          signatory.status == "manual_unsigned"
                            ? "manually-unsigned"
                            : "not-signed"
                        )}
                  </Badge>
                </td>
                <td>
                  {signatory.isSigned ? (
                    signedSignatories.length != signatories.length ? (
                      <ManuallyUnSignButton
                        signatoryId={signatory.id}
                        contractId={contract.id}
                        loadData={loadData}
                      />
                    ) : (
                      <span className="pl-4">--</span>
                    )
                  ) : (
                    <div className="flex items-center flex-wrap gap-2">
                      <ResendEmailButton
                        signatoryId={signatory.id}
                        contractId={contract.id}
                        signatoryEmail={signatory.email}
                      />
                      <CopyLinkButton link={signatory.signUrl} />
                      <ManuallySignButton
                        signatoryId={signatory.id}
                        contractId={contract.id}
                        loadData={loadData}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ReviewStep>
    </div>
  );
};

export default CaseSignatories;
