import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../components/Button/ActionButton";
import Input from "../../../components/formControl/Input";

export default function CaseTerms({ data, setData, onRemoveTerm, onAddTerm }) {
  const { t } = useTranslation("cases");

  return (
    <div className="mt-6">
      <h2 className="text-black mb-2 font-semibold leading-none">
        {t("terms-of-service.title")}
      </h2>
      {/* <p className="mb-">
              Add any terms(if you have any) that you would like your customers to accept before they can get access to your services.
            </p> */}

      {!data.terms?.length && (
        <div className="mb-6 bg-gray-100 border-2 border-dashed rounded p-6 flex flex-col items-center justify-center text-center">
          <p className="mb-3">{t("terms-of-service.placeholder")}</p>
          <button
            type="button"
            className="text-sm inline-flex items-center p-0 text-primary focus:outline-none"
            onClick={onAddTerm}
          >
            <PlusIcon width={14} strokeWidth={3} className="mr-2" />
            {t("terms-of-service.add-button")}
          </button>
        </div>
      )}

      {data.terms?.length > 0 && (
        <>
          {data.terms.map((_, index) => (
            <div className="mt-1 flex items-end">  
              <div key={index} className="mr-3 flex-1 grid grid-cols-2 gap-4">
                <Input
                  label={t("agreement")}
                  required
                  value={data.terms[index].name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      terms: [...data.terms].map((term, idx) => {
                        if (index == idx) {
                          return {
                            ...data.terms[idx],
                            name: e.target.value,
                          };
                        }

                        return term;
                      }),
                    })
                  }
                  id={`terms${index}Text`}
                  placeholder={t("tos-label-placeholder")}
                />
                <Input
                  label={t("url")}
                  required
                  value={data.terms[index].url}
                  onChange={(e) =>
                    setData({
                      ...data,
                      terms: [...data.terms].map((term, idx) => {
                        if (index == idx) {
                          return {
                            ...data.terms[idx],
                            url: e.target.value,
                          };
                        }

                        return term;
                      }),
                    })
                  }
                  name="terms[0].url"
                  id={`terms${index}Url`}
                  placeholder={t("tos-url-placeholder")}
                />
              </div>

              <div className="pb-2">
                <ActionButton
                    color="flat-danger"
                    onClick={() => onRemoveTerm(index)}
                >
                    <XIcon width={14} />
                </ActionButton>
              </div>
            </div>
          ))}

          <button
            type="button"
            className="mt-3 mb-5 inline-flex items-center text-sm p-0 text-primary focus:outline-none"
            onClick={onAddTerm}
          >
            <PlusIcon width={14} strokeWidth={3} className="mr-2" />
            {t("terms-of-service.add-button")}
          </button>
        </>
      )}
    </div>
  );
}
