const mod11OfNumberWithControlDigit = (input) => {
  let controlNumber = 2;
  let sumForMod = 0;

  for (let i = input.length - 2; i >= 0; --i) {
    sumForMod += parseInt(input.charAt(i)) * controlNumber;
    if (++controlNumber > 7) {
      controlNumber = 2;
    }
  }
  const result = 11 - (sumForMod % 11);

  return result === 11 ? 0 : result;
};

export default mod11OfNumberWithControlDigit;
