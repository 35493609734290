export default function Switch({
  id = "randomId",
  label,
  validator = {},
  error,
  className,
  ...inputProps
}) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <div className={`relative w-10 h-6 rounded-full
        ${inputProps.checked ? "bg-primary " : "bg-gray-300"}
      `}>
        <input id={id} type="checkbox" className="sr-only" {...inputProps} />
        <div className={`
          dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition
          ${inputProps.checked && "translate-x-full"}
        `}></div>
      </div>

      {label?.length && (
        <span className="ml-3 text-gray-700 font-medium">
          Default switch checkbox input
        </span>
      )}
    </label>
  );
}
