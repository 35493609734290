import { useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { Link, Redirect, useHistory } from "react-router-dom";

import { isObjEmpty } from "../../../utils";
import { confirmTenant } from "../../../providers/api";
import Button from "../../components/Button";
import Input from "../../components/formControl/Input";
import logo from "../Login/logo.png";
import forgotPasswordIllustration from "../ForgotPassword/forgot-password-illustration.svg";
import { useTranslation } from "react-i18next";

const ValidateAccount = () => {
  const {t} = useTranslation('auth');
  const [code, setCode] = useState("");
  const history = useHistory();
  const [valErrors, setValErrors] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { isLoading, data, error, mutate } = useMutation(confirmTenant, {
    onSuccess: res => {
      setValErrors({});
      history.push("/");
    },
    onError: err => {
      console.log("Confirm email error: ", err);
      toast.error(err?.message ? err.message : err);
    },
  });

  const onSubmit = async () => {
    if (isObjEmpty(errors)) {
      const email = sessionStorage.getItem("email");
      const tenant = sessionStorage.getItem("tenant");

      mutate({ tenant, email, code });
    }
  };

  const handleCodeChange = e => {
    const errs = valErrors;
    if (errs.code) delete errs.code;
    setCode(e.target.value);
    setValErrors(errs);
  };

  if (!sessionStorage.getItem('email') || !sessionStorage.getItem('tenant'))
    return <Redirect to="/" />;

  return (
    <div className="bg-gray-100">
      <div className="flex h-screen items-center">
        <Link
          className="brand-logo inline-flex items-center absolute top-6 left-6"
          to="/"
          onClick={e => e.preventDefault()}
        >
          <img className="object-cover h-10" src={logo} alt="logo" />
        </Link>
        <div className="hidden md:flex items-center p-5 flex-1">
          <div className="w-100 lg:flex items-center justify-content-center px-5">
            <img
              className="img-fluid"
              src={forgotPasswordIllustration}
              alt="Login V2"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex items-center h-full bg-white px-2 lg:p-10">
          <div className="px-xl-2 max-w-lg mx-auto p-8">
            <div className="mb-5">
              <h2 className="text-3xl font-semibold mb-2">
                {t('validate-account.title')}
              </h2>
              <p className="mb-2 max-w-xl">
                {t('validate-account.message', {email: sessionStorage.getItem("email")})}
              </p>
            </div>

            <form className="mt-4 space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Input
                  label={t('validate-account.your-activation-code')}
                  type="text"
                  value={code}
                  placeholder="123456"
                  id="validate-code"
                  name="validate-code"
                  onChange={handleCodeChange}
                  error={errors["validate-code"]}
                  validator={register("validate-code", {
                    required: true,
                    validate: value => value !== "",
                  })}
                />
                {Object.keys(valErrors).length && valErrors.code ? (
                  <small className="text-danger">{valErrors.code}</small>
                ) : null}
              </div>
              <Button
                type="submit"
                color="primary"
                block
                loading={isLoading}
                className="relative"
              >
                {isLoading ? t('please-wait') : t("validate-account.activate")}
              </Button>
            </form>
            <p className="text-center mt-2">
              <Link to="/login" className="flex items-center">
                <ChevronLeftIcon className="mr-2" width={18} />
                <span className="align-middle">{t('back-to-login')}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateAccount;
