import { formatDate, formatDateToTimeAgo } from "../../../../utils";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import i18n from "../../../../i18n";

export function Badge({ color = "", className, children }) {
  return (
    <span
      className={`inline-block text-xs font-medium leading-none rounded-full bg-gray-50 border truncate
      ${className}
      ${
        color == "light-success" &&
        "bg-green-50 text-green-900 border-green-900 border-opacity-10"
      }
      ${
        color == "light-danger" &&
        "bg-red-50 text-red-900 border-red-900 border-opacity-10"
      }
      ${
        color == "light-warning" &&
        "bg-yellow-100 text-yellow-900 border-yellow-900 border-opacity-10"
      }
      ${
        color == "light-info" &&
        "bg-blue-50 text-blue-900 border-blue-900 border-opacity-10"
      }
      ${
        color == "light-primary" &&
        "bg-primary text-white border-white border-opacity-10"
      }
      ${
        color == "light-secondary" &&
        "bg-gray-100 text-black border-black border-opacity-10"
      }
      ${!color?.length && "text-black border-black border-opacity-5"}
    `}
      style={{
        padding: "7px 10px 6px 10px",
        background: color,
      }}
    >
      {children}
    </span>
  );
}

export const caseStatusObj = {
  ACTIVE: { color: "light-success" },
  AUTOMATIC_REVIEW: { color: "light-success" },
  DECLINED: { color: "light-danger" },
  MANUAL_APPROVE: { color: "light-success" },
  UNDER_MANUAL_REVIEW: { color: "light-warning" },
  WAITING_FOR_DECLARATION: { color: "light-dark" },
  WAITING_FOR_SIGNATURE: { color: "light-dark" },
  DECLARATION_SUBMITTED: { color: "light-dark" },
};

export const columns = () => [
  {
    name: i18n.t("org-nr", { ns: "cases" }),
    minWidth: "157px",
    selector: (row) => row.id,
    sortable: true,
    cell: function Cell(row) {
      return <Link to={`/cases/${row.id}`}>{`${row.organizationNumber}`}</Link>;
    },
  },
  {
    name: i18n.t("reference-no", { ns: "cases" }),
    minWidth: "157px",
    selector: (row) => row.id,
    sortable: true,
    cell: function Cell(row) {
      return row.referenceId || <span className="text-xl opacity-30">--</span>;
    },
  },
  {
    name: i18n.t("company-name", { ns: "cases" }),
    minWidth: "200px",
    selector: (row) => row.companyName,
    sortable: true,
    cell: function Cell(row) {
      let name = row.details?.companyName;
      let contractDetails =
        row.contracts?.length && row.contracts[0]?.details
          ? row.contracts[0].details
          : null;
      if (!name?.length && row.contracts?.length)
        name = contractDetails?.companyName;

      if (!name?.length) return <span className="text-xl opacity-30">--</span>;

      return <h6 className="user-name truncate mb-0">{name}</h6>;
    },
  },
  {
    name: i18n.t("application-status", { ns: "cases" }),
    minWidth: "250px",
    selector: (row) => row.caseStatus,
    sortable: true,
    align: "center",
    cell: function Cell(row) {
      return row.caseStatus ? (
        <Badge color={caseStatusObj[row.caseStatus]?.color ?? "light-dark"}>
          {i18n.t("caseStatus." + row.caseStatus, { ns: "cases" })}
        </Badge>
      ) : (
        ""
      );
    },
  },
  {
    id: "statusUpdated",
    name: i18n.t("status-updated", { ns: "cases" }),
    selector: (row) => row.updatedAt,
    sortable: true,
    minWidth: "150px",
    cell: (row) => {
      return row.updatedAt ? formatDateToTimeAgo(row.updatedAt) : "";
    },
  },
  {
    name: i18n.t("applied-on", { ns: "cases" }),
    selector: (row) => row.createdAt,
    sortable: true,
    minWidth: "140px",
    cell: (row) => formatDate(row.createdAt),
  },
  {
    name: i18n.t("last-updated", { ns: "cases" }),
    selector: (row) => row.updatedAt,
    sortable: true,
    minWidth: "140px",
    cell: (row) => formatDate(row.updatedAt),
  },
  {
    name: i18n.t("last-edited-by", { ns: "cases" }),
    minWidth: "150px",
    selector: (row) => row.createdBy?.email || row.createdBy?.name,
    sortable: false,
    cell: function Cell(row) {
      let creator;

      if(row.createdBy?.email) creator = row.createdBy.email;
      else if(row.createdBy?.isApiUser || row.createdBy?.name) creator = `API - ${row.createdBy.name || ""}`;
      
      return creator ? <span className="truncate" title={creator}>{creator}</span> : <span className="text-xl opacity-30">--</span>;
    },
  },
  {
    name: "",
    maxWidth: "60px",
    align: "center",
    cell: function Cell(row) {
      return (
        <div className="column-action flex items-center" title="Preview Case">
          <Link to={`/cases/${row.id}`} id={`pw-tooltip-${row.id}`}>
            <ChevronRightIcon fill="#aaa" width={22} className="mx-1" />
          </Link>
          {/* <UncontrolledTooltip placement="top" target={`pw-tooltip-${row.id}`}>
            Preview Case
          </UncontrolledTooltip> */}
        </div>
      );
    },
  },
];
