import { useState } from "react";
import FileUploaderMulti from "./FileUploaderMulti";
import Attachment from "./Attachment";
import Loader from "./Loader";

const LogoUploader = ({
	onAddAttachment = () => {},
	onRemoveAttachment = () => {},
}) => {
	const [attachment, setAttachment] = useState(null);
	const [fetchingUploadDetails, setFetchingUploadDetails] = useState(null);

	const handleFileSelected = () => {
		setFetchingUploadDetails(true);
		setAttachment(null);
	};

	const handleAddAttachment = ({ attachment, uploadDetails }) => {
		setFetchingUploadDetails(false);
		setAttachment(null);

		setTimeout(() => {
			setAttachment({ attachment, uploadDetails, pending: true });
		});
	};

	const handleRemoveAttachment = ({ pending }) => {
		if (!pending) onRemoveAttachment(null);
		setAttachment(null);
	};

	const handleAttachmentUploaded = (att) => {
		setAttachment({ ...attachment, pending: false, status: "success" });
		onAddAttachment(att);
	};

	return (
		<div>
			<div className="relative overflow-hidden rounded-t">
				<FileUploaderMulti
					maxFileSize={2000000}
					maxFileSizeText="2Mb"
					flat
					autoUpload={false}
					onFileSelected={handleFileSelected}
					onAttachmentSelected={handleAddAttachment}
					imagesOnly
				/>

				{ (attachment?.pending || fetchingUploadDetails) &&
					<div className="opacity-80 backdrop-blur-md absolute inset-0 bg-gray-300 z-10 text-gray-900">
						{ fetchingUploadDetails && <Loader color="currentColor" />}
					</div>
				}
			</div>

			{attachment && (
				<div>
					<Attachment
						{...attachment}
						showPreview
						onUploaded={() =>
							handleAttachmentUploaded(attachment.attachment)
						}
						onRemove={() => handleRemoveAttachment(attachment)}
					/>
				</div>
			)}
		</div>
	);
};

export default LogoUploader;
