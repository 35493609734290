import FormSection from "../FormSection";
import Input from "../../../../components/formControl/Input";
import Select from "../../../../components/formControl/Select";
import RadioButtonGroup from "../../../../components/formControl/RadioButtonGroup";
import { yesNoChoices } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import { getCurrentCurrency } from "../../../../../utils";

const PurposeForPaymentService = ({
  details,
  errors,
  register,
  handleChange,
  handleSelectChange,
}) => {
  const { t } = useTranslation("declaration-form");
  const lessThan = t("less-than", { ns: "declaration-form" });
  const moreThan = t("more-than", { ns: "declaration-form" });

  const averageTransactionValueOptions = [
    { value: "l-100", label: lessThan + " 100" },
    { value: "101-300", label: "101 - 300" },
    { value: "301-600", label: "301 - 600" },
    { value: "601-1000", label: "601 - 1000" },
    { value: "1001-2000", label: "1001 - 2000" },
    { value: "g-2000", label: moreThan + " 2000" },
  ];

  const annualturnoverOptions = [
    { value: "0-50.000", label: "0 - 50.000" },
    { value: "50.001-500.000", label: "50.001 - 500.000" },
    { value: "500.001-1.000.000", label: "500.001 - 1.000.000" },
    { value: "1.000.001-5.000.000", label: "1.000.001 - 5.000.000" },
    { value: "g-5.000.000", label: moreThan + " 5.000.000" },
  ];

  return (
    <FormSection
      label={t("step-3")}
      title={t("purpose-of-payment-services.title")}
      subtitle={t("purpose-of-payment-services.description")}
    >
      <div className="mb-1 text-black opacity-60 font-light leading-relaxed text-sm">
        {t("purpose-of-payment-services.message")}
      </div>

      <div className="grid grid-2 gap-x-6">
        {(details.commercialInformation.webshop ||
          details.commercialInformation.paymentOrSubscriptionsApp) && (
          <>
            <Input
              label={t("webshop-url")}
              type="text"
              name="commercialInformation_shopUrl"
              value={details.commercialInformation.shopUrl}
              onChange={(e) =>
                handleChange("commercialInformation", "shopUrl", null, e)
              }
              placeholder="E.g. https://myshop.com"
              id="commercialInformation_shopUrl"
              validator={register("commercialInformation_shopUrl", {
                required: true,
                pattern:
                  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              })}
              error={errors.commercialInformation_shopUrl}
            />

            <Input
              label={t("terms-and-conditions-url")}
              type="text"
              name="commercialInformation_shopTermsUrl"
              value={details.commercialInformation.shopTermsUrl}
              onChange={(e) =>
                handleChange("commercialInformation", "shopTermsUrl", null, e)
              }
              placeholder="https://myshop.com/toc"
              id="commercialInformation_shopTermsUrl"
              validator={register("commercialInformation_shopTermsUrl", {
                required: true,
                pattern:
                  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              })}
              error={errors.commercialInformation_shopTermsUrl}
            />

            <Select
              label={t("do-you-offer-fullfilment")}
              error={
                details.commercialInformation.postFullfilmentPayment === null
              }
              value={{
                value: details.commercialInformation.postFullfilmentPayment,
                label: yesNoOptions.find((i) => {
                  return (
                    i.value ===
                    details.commercialInformation.postFullfilmentPayment
                  );
                }).label,
              }}
              options={yesNoOptions}
              onChange={(e) =>
                handleSelectChange(
                  "commercialInformation",
                  "postFullfilmentPayment",
                  e
                )
              }
              isClearable={false}
            />
            <div>
              {/* <InputGroupAddon addonType="prepend">
                <InputGroupText>Days</InputGroupText>
              </InputGroupAddon> */}
              <Input
                label={t("average-fullfillment-days")}
                type="number"
                name="commercialInformation_fullfillmentDays"
                value={details.commercialInformation.fullfillmentDays}
                onChange={(e) =>
                  handleChange(
                    "commercialInformation",
                    "fullfillmentDays",
                    null,
                    e
                  )
                }
                placeholder="example: 3"
                id="commercialInformation_fullfillmentDays"
                validator={register("commercialInformation_fullfillmentDays", {
                  required: true,
                })}
                error={errors.commercialInformation_fullfillmentDays}
              />
            </div>
          </>
        )}

        <div className="mb-3 grid grid-cols-2 gap-x-6">
          <Select
            label={t("average-transaction-value", {
              currency: getCurrentCurrency(),
            })}
            isClearable={false}
            id="commercialInformation_averageTransactionValue"
            name="commercialInformation_averageTransactionValue"
            choices={averageTransactionValueOptions}
            error={errors.commercialInformation_averageTransactionValue}
            validator={register(
              "commercialInformation_averageTransactionValue",
              {
                required: true,
              }
            )}
            defaultValue={details.commercialInformation.averageTransactionValue}
            onChange={(e) =>
              handleSelectChange(
                "commercialInformation",
                "averageTransactionValue",
                e
              )
            }
          />

          <Select
            label={t("projected-sales", { currency: getCurrentCurrency() })}
            id="commercialInformation_annualTurnover"
            name="commercialInformation_annualTurnover"
            error={errors.commercialInformation_annualTurnover}
            choices={annualturnoverOptions}
            validator={register("commercialInformation_annualTurnover", {
              required: true,
            })}
            defaultValue={details.commercialInformation.annualturnover}
            onChange={(e) =>
              handleSelectChange("commercialInformation", "annualturnover", e)
            }
            isClearable={false}
          />
        </div>

        <Input
          wrapperClassName="mb-3"
          label={t("most-expensive-product", {
            currency: getCurrentCurrency(),
          })}
          name="commercialInformation_mostExpensiveProduct"
          value={details.commercialInformation.mostExpensiveProduct}
          onChange={(e) =>
            handleChange(
              "commercialInformation",
              "mostExpensiveProduct",
              null,
              e
            )
          }
          placeholder=""
          id="commercialInformation_mostExpensiveProduct"
          validator={register("commercialInformation_mostExpensiveProduct", {
            required: true,
          })}
          error={errors.commercialInformation_mostExpensiveProduct}
        />

        <div className="mb-3">
          <div className="flex flex-wrap items-center border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-2.5 px-3.5">
            <RadioButtonGroup
              name="commercialInformation_giftcards"
              label={t("do-you-offer-giftcards")}
              choices={yesNoChoices()}
              validator={register("commercialInformation_giftcards", {
                required: true,
              })}
              error={errors.commercialInformation_giftcards}
              value={details.commercialInformation.giftcards}
              onChange={(e) =>
                handleChange("commercialInformation", "giftcards", null, e)
              }
            />

            {details.commercialInformation.giftcards && (
              <Input
                wrapperClassName="mt-2.5 pt-2.5 mb-1 w-full border-t"
                label={t("giftcards-revenue-percent.label")}
                type="number"
                name="commercialInformation_giftcardsPercentage"
                defaultValue={details.commercialInformation.giftcardsPercentage}
                onChange={(e) =>
                  handleChange(
                    "commercialInformation",
                    "giftcardsPercentage",
                    null,
                    e
                  )
                }
                placeholder={t("giftcards-revenue-percent.placeholder")}
                id="commercialInformation_giftcardsPercentage"
                validator={register(
                  "commercialInformation_giftcardsPercentage",
                  {
                    required: details.commercialInformation.giftcards,
                    min: {
                      value: 0,
                      message: t("total-revenue-percentage-too-low"),
                    },
                    max: {
                      value: 100,
                      message: t("total-revenue-percentage-too-high"),
                    },
                  }
                )}
                error={errors.commercialInformation_giftcardsPercentage}
              />
            )}
          </div>
        </div>

        <div className="mb-3 flex items-center flex-wrap border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-2.5 px-3.5">
          <RadioButtonGroup
            name="commercialInformation_subscriptions"
            label={t("do-you-offer-subscriptions")}
            choices={yesNoChoices()}
            validator={register("commercialInformation_subscriptions", {
              required: true,
            })}
            error={errors.commercialInformation_subscriptions}
            value={details.commercialInformation.subscriptions}
            onChange={(e) =>
              handleChange("commercialInformation", "subscriptions", null, e)
            }
          />

          {details.commercialInformation.subscriptions && (
            <Input
              wrapperClassName="mt-2.5 pt-2.5 mb-1 w-full border-t"
              label={t("subscriptions-revenue-percent.label")}
              type="number"
              name="commercialInformation_subscriptionsPercentage"
              defaultValue={
                details.commercialInformation.subscriptionsPercentage
              }
              onChange={(e) =>
                handleChange(
                  "commercialInformation",
                  "subscriptionsPercentage",
                  null,
                  e
                )
              }
              placeholder={t("subscriptions-revenue-percent.placeholder")}
              id="commercialInformation_subscriptionsPercentage"
              validator={register(
                "commercialInformation_subscriptionsPercentage",
                {
                  required: details.commercialInformation.subscriptions,
                  min: {
                    value: 0,
                    message: t("total-revenue-percentage-too-low"),
                  },
                  max: {
                    value: 100,
                    message: t("total-revenue-percentage-too-high"),
                  },
                }
              )}
              error={errors.commercialInformation_subscriptionsPercentage}
            />
          )}
        </div>

        <div className="flex items-center border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-2.5 px-3.5">
          <RadioButtonGroup
            type="radio"
            name="commercialInformation_internationalSales"
            label={t("will-you-sell-internationally")}
            choices={yesNoChoices()}
            validator={register("commercialInformation_internationalSales", {
              required: true,
            })}
            error={errors.commercialInformation_internationalSales}
            value={details.commercialInformation.internationalSales}
            onChange={(e) =>
              handleChange(
                "commercialInformation",
                "internationalSales",
                null,
                e
              )
            }
          />
        </div>

        <div
          className={`mt-3 flex items-center border border-gray-400/40 rounded bg-gray-50 pt-2.5 pb-2.5 px-3.5
          ${!details?.commercialInformation?.internationalSales && "hidden"}
        `}
        >
          <RadioButtonGroup
            name="commercialInformation_internationalTax"
            label={t("do-you-pay-taxes-internationally")}
            choices={yesNoChoices()}
            validator={register("commercialInformation_internationalTax", {
              required: details?.commercialInformation?.internationalSales,
            })}
            error={errors.commercialInformation_internationalTax}
            value={details.commercialInformation.internationalTax}
            onChange={(e) =>
              handleChange("commercialInformation", "internationalTax", null, e)
            }
          />
        </div>
      </div>
    </FormSection>
  );
};

export default PurposeForPaymentService;
