import classnames from "classnames";
import { toast } from "react-toastify";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { isObjEmpty } from "../../../utils";
import Button from "../../components/Button";
import logo from "../Login/logo.png";
import forgotPasswordIllustration from "./forgot-password-illustration.svg";

import { requestTemporaryPassword } from "../../../providers/api";
import Input from "../../components/formControl/Input";

const ForgotPassword = () => {
  const { t } = useTranslation("auth");
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { isLoading, mutate } = useMutation(requestTemporaryPassword, {
    onSuccess: (res) => {
      toast.success(res.data.message);
      history.push("/confirm-password");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const onSubmit = async (data) => {
    try {
      if (isObjEmpty(errors)) {
        const { email } = data;
        sessionStorage.setItem("email", email);
        mutate({ username: email });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitted(true);
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="flex h-screen items-center">
        <Link
          className="brand-logo inline-flex items-center absolute top-6 left-6"
          to="/"
          onClick={(e) => e.preventDefault()}
        >
          <img className="object-cover h-10" src={logo} alt="logo" />
        </Link>
        <div className="hidden md:flex items-center p-5 flex-1">
          <div className="w-100 lg:flex items-center justify-content-center px-5">
            <img
              className="img-fluid"
              src={forgotPasswordIllustration}
              alt="Login V2"
            />
          </div>
        </div>
        <div className="w-full md:w-auto flex items-center h-full bg-white px-2 lg:p-10">
          <div className="px-xl-2 mx-auto p-8">
            <div className="mb-5">
              <h2 className="text-2xl font-semibold mb-2">
                {t("forgot-password.title")}
              </h2>
              <p className="mb-2">{t("forgot-password.message")}</p>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Input
                  label={t("email")}
                  autoFocus
                  type="email"
                  id="email"
                  name="email"
                  placeholder="john@example.com"
                  error={errors["email"]}
                  validator={register("email", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                />
              </div>
              <Button
                type="submit"
                color="primary"
                block
                loading={isLoading}
                className="relative"
              >
                {isLoading ? t("please wait") : t("submit")}
              </Button>
            </form>

            <p className="text-center mt-4">
              <Link to="/login" className="flex items-center">
                <ChevronLeftIcon className="mr-25" width={16} />
                <span className="align-middle">{t("back-to-login")}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
