import Sidebar from "../../../../components/Sidebar";
import { useCallback, useState } from "react";
import FileUploaderMulti from "../../../../components/FileUploaderMulti";
import Textarea from "../../../../components/formControl/Textarea";
import Loader from "../../../../components/Loader";
import Button from "../../../../components/Button";
import Label from "../../../../components/formControl/Label";
import AttachmentUploader from "../../../../components/AttachmentUploader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddReviewNotes = ({ open, toggleSidebar, block, setBlock, saveLog }) => {
  const { t } = useTranslation("cases");
  const [selectedLog, setSelectedLog] = useState(null);
  const [savingNotes, setSavingNotes] = useState(false);
  const [note, setNote] = useState("");
  const [attachments, setAttachments] = useState([]);

  const addAttachment = async (attachment) => {
    setAttachments([
      ...attachments,
      { name: attachment.name, url: attachment.url },
    ]);

    return await saveLog("saveAttachments", [...attachments, attachment]);
  }

  const removeAttachment = async (index) => {
    const newAttachments = attachments.filter((a, i) => {
      return i !== index;
    });
    setAttachments(newAttachments);
    return await saveLog("saveAttachments", newAttachments);
  }

  const handleSaveNote = async () => {
    try {
      setSavingNotes(true);
      await saveLog("save", [{ note, createdAt: new Date().getTime() }]);
      toast.success(t('notes-saved'));
      setNote("");
      setSavingNotes(false);
    } catch (error) {
      setSavingNotes(false);
      toast.error(t('notes-not-saved'));
      console.log("Saving notes error");
    }
  }

  return (
    <Sidebar
      size="md"
      open={open}
      title={t("add-notes-to-case")}
      onClose={toggleSidebar}
    >
      <div className="fixed inset-0 bg-gray-100 -z-1"></div>
      <div className="mt-1 py-2 px-6 space-y-3 text-left">
        <div className="space-y-1 mb-6">
          <Label>{t("documentation-acquired-by-agent")}</Label>
          <AttachmentUploader
            onAddAttachment={addAttachment}
            onRemoveAttachment={removeAttachment}
          />
        </div>
        <Textarea
          label={t("notes-by-agent.label")}
          className="shadow-sm block w-full text-sm  rounded-md"
          type="textarea"
          name="text"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows="5"
          placeholder={t("notes-by-agent.placeholder")}
        />
        <Button
          color="primary"
          block
          loading={savingNotes}
          onClick={handleSaveNote}
        >
          {t("save-updates")}
        </Button>
      </div>
    </Sidebar>
  );
};

export default AddReviewNotes;
