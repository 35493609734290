import { DocumentAddIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import Button from "./Button";

export default function ReviewStep({
  heading = "Know your customer",
  subHeading = "This case is currently being automatically monitored as part of our Ongoing Due Diligence system.",
  children,
  onAddNotes,
}) {
  const { t } = useTranslation("cases");
  return (
    <div className="-mx-4 lg:mx-0 mb-6 reviewStep">
      <div className="mb-3.5 px-4 lg:px-0">
        <h2 className="mb-1 p-0 text-black opacity-70 text-lg leading-none font-semibold">
          {heading}
        </h2>
        <p className="leading-relaxed" dangerouslySetInnerHTML={{__html: subHeading}}></p>
      </div>

      <div className="relative">
        <div className="bg-white shadow rounded overflow-hidden border z-10 relative">
          {children}
        </div>

        {typeof onAddNotes == "function" && (
          <div className="relative h-14 flex items-center justify-end">
            <div className="h-8 border-l border-b absolute top-0 left-10 right-0"></div>
            <div
              className="bg-gray-100 h-full py-4 pl-1 pr-2"
              style={{ zIndex: 1 }}
            >
              <Button
                size="sm"
                outline
                className="flex items-center"
                onClick={onAddNotes}
              >
                <DocumentAddIcon width={14} className="-ml-0.5" />
                <span className="ml-2">{t("add-notes")}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
