import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import Input from "./Input";
import Label from "./Label";
import { randomId } from "../../../utils";

const PasswordInput = ({id = randomId(), label, error, className, ...props}) => {
  const [inputVisibility, setInputVisibility] = useState(false);

  return (
    <div className="flex flex-col">
      {label && label.length && <Label htmlFor={id}>{label}</Label>}

      <div className="relative">
        <Input
          id={id}
          {...props}
          error={error}
          type={inputVisibility === false ? "password" : "text"}
        />

        <div className={`
          absolute top-0 bottom-0 flex
          ${error ? 'right-3' : 'right-0'}
        `}>
          <button
            aria-label="Toggle Password"
            className="px-4 flex items-center justify-center text-gray-400 hover:text-gray-600"
            type="button"
            onClick={() => setInputVisibility(!inputVisibility)}
          >
            {inputVisibility ? <EyeOffIcon width={16} /> : <EyeIcon width={16} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
