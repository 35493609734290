import { Link } from "react-router-dom";
import logo from "./logo.png";
import dinteroLogo from "./dintero-logo.png";

const Logo = ({ className = "object-cover h-9", dintero }) => {
  return (
    <Link
      className={`inline-flex items-center absolute left-6 top-6
        ${className}
      `}
      to="/"
    >
      <img
        className={className}
        src={dintero ? dinteroLogo : logo}
        alt="logo"
      />
    </Link>
  );
};

export default Logo;
